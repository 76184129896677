
// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';

// Modely
import { CustomerGroup, Domain, Shipment, StockAvailability, Unit, Tag, Category } from '../../models/Models';
import { ProductCreateComponentProps } from './ProductCreate';

// Komponenty
import { TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, FormControlLabel, Switch, Card, CardMedia } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import CategorySelect from '../category/CategorySelect';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import TagIcon from '@mui/icons-material/Tag';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateMainProps extends ProductCreateComponentProps {
    domains: Domain[];
    stockAvailabilities: StockAvailability[];
    customerGroups: CustomerGroup[];
    categories: Category[];
    shipments: Shipment[];
    tags: Tag[],
    units: Unit[],
    vatRates: number[];
    onCategoriesChanged: () => void;    // Udalosť pre oznámenie zmeny v kategóriach (vnútorne ich môžem upraviť)
    imageClick?: () => void;
    stockClick?: () => void;
};

const ProductCreateMain = (props: ProductCreateMainProps) => {

    return (
        <>
            <Grid container columnSpacing={1}>

                <Grid item xs={12} mb={1}>
                    <FormControlLabel control={<Switch checked={props.source.actived} name="actived" onChange={(e) => { props.onChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                </Grid>

                <Grid item xs={12} md={10} container columnSpacing={1}>

                    <Grid item xs={12} sm={4}>
                        <TextField required margin="dense" name="code" label="Kód" fullWidth variant="outlined" autoComplete="off" value={props.source.code ?? ''} onChange={(e) => { props.onChange(e.target.name, e.target.value) }}
                            inputRef={input => (props.autoFocus === 'code' || props.autoFocus === undefined) && props.setFocus(input)}
                            inputProps={{ maxLength: 64 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Jedinečný kód produktu v rámci celého katalógu. Kód produktov sa nemôžu opakovať.">
                                            <TagIcon className="formIconHelp" />
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextField fullWidth required margin="dense" name="price" type="text" sx={{ fontWeight: 'bold' }} label={'Cena ' + (props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={props.source.price ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                            inputRef={input => props.autoFocus === 'price' && props.setFocus(input, true)}
                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EuroIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextField fullWidth margin="dense" name="priceDiscount" type="text" label={'Akciová cena ' + (props.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={props.source.priceDiscount ?? ''} onChange={(e) => { props.onChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                            inputRef={input => props.autoFocus === 'priceDiscount' && props.setFocus(input, true)}
                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Akciová cena produktu je platná iba v čase, keď je aktívny štítok typu 'akcia'.">
                                            <EuroIcon className="formIconHelp" />
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <InputLabel id="product-create-vatrate">DPH</InputLabel>
                            <Select required labelId="product-create-vatrate" label="DPH" value={props.source.priceVatRate ?? 0} name="priceVatRate" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                inputRef={input => props.autoFocus === 'priceVatRate' && props.setFocus(input)}>
                                {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <InputLabel id="product-create-stocktype">Typ skladovej karty</InputLabel>
                            <Select required labelId="product-create-stocktype" label="Typ skladovej karty" value={props.source.stockType ?? 0} name="stockType" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                inputRef={input => props.autoFocus === 'stockType' && props.setFocus(input)}>
                                <MenuItem value={0}>Tovar</MenuItem>
                                <MenuItem value={1}>Služba</MenuItem>
                                <MenuItem value={2}>Obal</MenuItem>
                                <MenuItem value={3}>Výrobok</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <InputLabel id="product-create-availability">Dostupnosť</InputLabel>
                            <Select labelId="product-create-availability" label="Dostupnosť" value={props.source.stockAvailabilityId ?? 0} name="stockAvailabilityId" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}
                                inputRef={input => props.autoFocus === 'stockAvailabilityId' && props.setFocus(input)}>
                                <MenuItem value={0}>-</MenuItem>
                                {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextField fullWidth margin="dense" type="number" label="Množstvo na sklade (sumár)" variant="outlined" autoComplete="off" onClick={props.stockClick}
                            value={props.source.stocks?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0) ?? ''}
                            inputProps={{ readOnly: true }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <ApartmentIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl margin="dense" variant="outlined" fullWidth>
                            <CategorySelect label='Kategória' activeCategoryId={props.source.categoryId} categories={props.categories} onSelect={(id: number) => props.onChange('categoryId', id)} onCategoriesChanged={props.onCategoriesChanged} />
                        </FormControl>
                    </Grid>

                </Grid>

                <Grid item xs={12} md={2}>
                    <Card sx={{ mt: 1, cursor: 'pointer' }} onClick={props.imageClick}>
                        {props.source?.files?.find(file => file.coverInDetail === true) !== undefined && (
                            <CardMedia component="img" height="123" image={props.source?.files?.find(file => file.coverInDetail === true)?.other?.fileSrcMedium ?? ''} />
                        )}
                        {props.source?.files?.find(file => file.coverInDetail === true) === undefined && (
                            <CardMedia component="div" sx={{ display: 'flex', height: "123px", justifyContent: 'center', alignItems: 'center' }}>
                                <ImageOutlinedIcon fontSize="large" />
                            </CardMedia>
                        )}
                    </Card>
                </Grid>

                {props.languages.map((language, index) => {
                    return (
                        <Grid item xs={12} key={language.id}>
                            <TextField required margin="dense" name='name' label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                inputRef={input => props.autoFocus === 'name' && index === 0 && props.setFocus(input)}
                                value={props.source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                onChange={(e) => { props.onChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                inputProps={{ maxLength: 255 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EditIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                    )
                })}

            </Grid>
        </>
    )
};

export default ProductCreateMain;
