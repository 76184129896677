import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimalOnKeyDown, ConvertToInt } from '../../../utility/Number';
import { ConvertToDecimal } from '../../../utility/Number';

// Modely
import { StockAvailability, StockAvailabilityTranslation, StockAvailabilityTypes, BasicColors, Language } from '../../../models/Models';
import { File } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, Card, CardMedia, CardActions, FormControl, InputLabel, Select, MenuItem, RadioGroup, Radio, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';

// Ikony
import DateRangeIcon from '@mui/icons-material/DateRange';
import SortIcon from '@mui/icons-material/Sort';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): StockAvailability => ({
    id: 0,
    type: 0,
    ordinalNumber: 0,
    // dispatchTime: 0,
    // deliveryTime: 0,
    color: '',
    fileId: 0,
    translations: [],
    other: {
        fileSrcMedium: ''
    }
});

// Vstupné parametre
export interface StockAvailabilityCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const StockAvailabilityCreate = (props: StockAvailabilityCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<StockAvailability>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            if (files.length > 0 && files[0].fileIsImage) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'stockavailability/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as StockAvailability);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: StockAvailabilityTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'stockavailability', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <FilesDialog {...filesDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Dostupnosť' : 'Nová dostupnosť')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={8}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="stockavalability-create-type">Typ *</InputLabel>
                                    <Select required labelId="stockavalability-create-type" label="Typ *" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        {StockAvailabilityTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <Grid key={language.id} item xs={12}>
                                        <TextField required margin="dense" name='name' label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                            inputRef={input => index === 0 && (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                            value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 70 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12} mt={2}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => handleToggleAccordion('note')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {languages.map((language, index) => {
                                                return (
                                                    <Grid key={language.id} item xs={12}>
                                                        <TextField fullWidth multiline rows={5} margin="dense" name="note" label={'Poznámka (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                            value={source.translations?.find(item => item.languageId === language.id)?.note ?? ''}
                                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                            inputRef={input => index === 0 && props.autoFocus === 'note' && setFocus(input)}
                                                            inputProps={{ maxLength: 1000 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <EditIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => handleToggleAccordion('settings')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nastavenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField fullWidth margin="dense" name="dispatchTime" type="text" label="Doba expedície (dni)" variant="outlined" autoComplete="off" value={source.dispatchTime ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'dispatchTime' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Maximálna doba do expedície od prijatia objednávky, alebo platby.">
                                                                    <DateRangeIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField fullWidth margin="dense" name="deliveryTime" type="text" label="Doba doručenia (dni)" variant="outlined" autoComplete="off" value={source.deliveryTime ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputRef={input => props.autoFocus === 'deliveryTime' && setFocus(input)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Celková doba od prijatia objednávky po doručenie produktu k zákazníkovi.">
                                                                    <DateRangeIcon className="formIconHelp" />
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <FormControl margin="dense" variant="outlined" fullWidth component="fieldset">
                                    <RadioGroup row aria-label="Color" name="radio-color">
                                        {BasicColors.map((item: string, index) => <Radio key={index} sx={{ color: '#' + item, '&.Mui-checked': { color: '#' + item } }} checked={source.color === item} value={item} name="color" onChange={(e) => { handleChange(e.target.name, e.target.value) }} />)}
                                        <Button onClick={() => handleChange('color', '')} color="secondary">Bez farby</Button>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid item xs={12} md={4}>
                                    {(source.fileId ?? 0) > 0 &&
                                        <Card sx={{ mt: 1 }}>
                                            <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                            <CardActions>
                                                <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                            </CardActions>
                                        </Card>
                                    }
                                    <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                                </Grid>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default StockAvailabilityCreate;