import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import { AppRouteUrl } from '../../AppRoutes';

// Modely
import { ProductsFilter, ProductLite, Category, StockAvailability, Parameter } from '../../models/Models';

// Utility
import Debounce from '../../utility/Debounce';
import { ResponsivePoint as HidePoint } from '../../utility/ResponsivePoint';
import { FormatDecimal } from '../../utility/Format';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../utility/DataGrid';
import { useQuery } from '../../utility/URL';
import format from 'date-fns/format';
import { LoadLocalSettings, SaveLocalSettings } from '../../utility/LocalSettings';

// Komponenty
import { Avatar, Backdrop, Box, Button, Chip, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, ListItemIcon, Menu, Paper, Select } from '@mui/material';
import { DataGrid, GridColDef, GridDensity, GridFilterModel, GridRenderCellParams, GridRowId, GridSortItem, GridSortModel, GridValueFormatterParams, GridValueGetterParams, skSK } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import Search from '../../components/Search';
import ProductCreate, { ProductCreateProps, ProductCreateSaveAction } from './ProductCreate';
import ProductsFiltering, { ProductsFilteringProps } from './ProductsFiltering';
import ProductsAvailability, { ProductsAvailabilityProps } from './ProductsAvailability';
import ProductsMove, { ProductsMoveProps } from './ProductsMove';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import Categories from '../category/Categories';
import { Content, ContentTop, ContentBottom } from '../../layout/Content';
import DataGridDensity from '../../components/DataGridDensity';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import FlagIcon from '@mui/icons-material/Flag';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SettingsIcon from '@mui/icons-material/Settings';

// Rozšírenie pre vlastný index zoradenia
interface GridSortModelIndex extends GridSortItem {
    index: number;
}

// Vstupné parametre
export interface ProductsProps {
    categoryId?: number;       // Predvolená kategória
    onSelectionChanged?: (rows: ProductLite[], confirm: boolean) => void;
}

// Komponent pre zoznam užívateľov
const Products = (props?: ProductsProps) => {

    // Lokálny stav
    const [loading, setLoading] = useState<boolean>(true);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [productCreate, setProductCreate] = useState<ProductCreateProps>({
        open: false,
        keepMounted: true,
        categories: [],
        onSave: (id?: number, action?: ProductCreateSaveAction) => {
            // Obnovím zoznam
            loadDataSafe();

            // Rozšírené funkcie po uložení
            if (id !== undefined && action !== undefined) {
                if (action === ProductCreateSaveAction.SaveAndNew) {
                    handleCreate(0);
                    return;
                }
                if (action === ProductCreateSaveAction.SaveAndContinue) {
                    handleCreate(id ?? 0);
                    return;
                }
                if (action === ProductCreateSaveAction.SaveAndNewFromCopy) {
                    handleCreate(id ?? 0, true);
                    return;
                }
            }
        },
        onClose: () => setProductCreate(prev => ({ ...prev, open: false })),
        onCategoriesChanged: () => loadDataCategories()
    });
    const [productsFiltering, setRowsFiltering] = useState<ProductsFilteringProps>({
        open: false,
        keepMounted: true,
        onSave: (filter) => setRowsFilter({ ...filter, page: 0 }),
        onClose: () => setRowsFiltering(prev => ({ ...prev, open: false }))
    });
    const [productsAvailability, setProductsAvailability] = useState<ProductsAvailabilityProps>({
        open: false,
        keepMounted: true,
        onSelect: (id) => { },
        onClose: () => setProductsAvailability(prev => ({ ...prev, open: false }))
    });
    const [productsMove, setProductsMove] = useState<ProductsMoveProps>({
        open: false,
        keepMounted: false,
        categories: [],
        onSelect: (id) => { },
        onClose: () => setProductsMove(prev => ({ ...prev, open: false }))
    });

    // Nastavenia gridu
    const gridSettings = new DataGridColumnSettings({ uid: 'products' });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        { field: 'actived', headerName: 'Aktívny', hide: HidePoint().sm, width: 70, type: 'boolean', sortable: false },
        {
            field: 'file', headerName: 'Náhľad', hide: HidePoint().sm, width: 80, sortable: false, filterable: false, align: 'center', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {params.row.fileIsImage ? <Avatar alt='-' src={params.row.fileSrcSmall} /> : null}
                </>
            )
        },
        { field: 'name', headerName: 'Názov', hide: false, minWidth: 60, flex: 0.8 },
        { field: 'parameters', headerName: 'Parametre', hide: true, minWidth: 60, flex: 0.5, sortable: false },
        { field: 'code', headerName: 'Kód', hide: true, minWidth: 60, flex: 0.2 },
        { field: 'model', headerName: 'Model', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        {
            field: 'price', headerName: 'Cena', hide: false, minWidth: 60, flex: 0.2, align: 'right', headerAlign: 'center', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'priceDiscount', headerName: 'Akciová cena', hide: HidePoint().lg, minWidth: 60, flex: 0.2, align: 'right', headerAlign: 'center', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        { field: 'discount', headerName: 'Akcia', hide: false, width: 70, type: 'boolean', sortable: false },
        {
            field: 'pricePurchase', headerName: 'Nákupná cena', hide: true, minWidth: 60, flex: 0.2, align: 'right', headerAlign: 'center', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'discountFrom', headerName: 'Akcia od', hide: true, minWidth: 50, flex: 0.5, type: 'date', sortable: false,
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['discountFrom']),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'discountTo', headerName: 'Akcia do', hide: true, minWidth: 50, flex: 0.5, type: 'date', sortable: false,
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['discountTo'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        { field: 'stockAvailabilityQuantity', headerName: 'Zásoby', hide: HidePoint().md, minWidth: 60, flex: 0.2, align: 'right', headerAlign: 'center', type: 'number' },
        {
            field: 'stockAvailability', headerName: 'Dostupnosť', hide: HidePoint().md, minWidth: 60, flex: 0.4, sortable: false,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Chip label={(params.row.stockAvailability ?? '').length > 0 ? params.row.stockAvailability : '...'} size="small" variant="filled" sx={{ bgcolor: '#' + ((params.row.stockAvailabilityColor ?? '').length > 0 ? params.row.stockAvailabilityColor : 'f0f0f0'), color: ((params.row.stockAvailabilityColor ?? '').length > 0 ? '#ffffff' : '#222222') }}
                    component="a" onClick={() => {
                        handleAvailabilityList([params.row.id]);
                    }} />
            )
        },
        { field: 'categoryName', headerName: 'Kategória', hide: true, minWidth: 60, flex: 0.4, sortable: false },
        { field: 'ean', headerName: 'EAN', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'mpn', headerName: 'MPN', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'codeSupplier', headerName: 'Kód dodávateľa', hide: true, minWidth: 60, flex: 0.2, sortable: false },
        { field: 'weight', headerName: 'Hmotnosť (g)', hide: true, minWidth: 60, flex: 0.2, align: 'center', headerAlign: 'center', type: 'number' },
        {
            field: 'dimensions', headerName: 'Rozmer (š, d, v)', hide: true, minWidth: 50, flex: 0.2, sortable: false,
            valueGetter: (params: GridValueGetterParams) => (params.row.dimensionWidth ?? 0) + ' / ' + (params.row.dimensionLength ?? 0) + ' / ' + (params.row.dimensionHeight ?? 0)
        },
        { field: 'ordinalNumber', headerName: 'P.č.', hide: true, minWidth: 50, flex: 0.2, align: 'center', headerAlign: 'center', type: 'number' },
        {
            field: 'hideCart', headerName: 'Košík', hide: true, width: 70, type: 'boolean', sortable: false,
            valueGetter: (params: GridValueGetterParams) => !params.row.hideCart
        },
        {
            field: 'hideReview', headerName: 'Hodnotenie', hide: true, width: 70, type: 'boolean', sortable: false,
            valueGetter: (params: GridValueGetterParams) => !params.row.hideReview
        },
        {
            field: 'createdDate', headerName: 'Vytvorený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => format((params?.value as Date), 'dd.MM.yyyy HH:mm') ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravený', hide: true, minWidth: 50, flex: 0.5, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? format((params?.value as Date), 'dd.MM.yyyy HH:mm') : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 120, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => handleCreate(params.value ?? 0, false)}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Kopírovať" title="Kopírovať (ctrl + enter)" size="small" onClick={() => handleCreate(params.value ?? 0, true)}>
                        <ContentCopyIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => handleDelete(params.value ?? 0, params.row.name)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Možnosti zoradenia (index podľa DB, názov stĺpca v gride a jeho smer)
    const columnsSortDefault: number = -1; // automaticky, ak nie je zvolené (posledné nahor, alebo rank)
    const columnsSort: GridSortModelIndex[] = [
        { index: 0, field: 'id', sort: 'asc' },
        { index: 1, field: 'id', sort: 'desc' },
        { index: 0, field: 'createdDate', sort: 'asc' },
        { index: 1, field: 'createdDate', sort: 'desc' },
        { index: 2, field: 'name', sort: 'asc' },
        { index: 3, field: 'name', sort: 'desc' },
        { index: 4, field: 'price', sort: 'asc' },
        { index: 5, field: 'price', sort: 'desc' },
        { index: 6, field: 'priceDiscount', sort: 'asc' },
        { index: 7, field: 'priceDiscount', sort: 'desc' },
        { index: 8, field: 'pricePurchase', sort: 'asc' },
        { index: 9, field: 'pricePurchase', sort: 'desc' },
        { index: 10, field: 'code', sort: 'asc' },
        { index: 11, field: 'code', sort: 'desc' },
        { index: 12, field: 'updatedDate', sort: 'asc' },
        { index: 13, field: 'updatedDate', sort: 'desc' },
        { index: 14, field: 'stockAvailabilityQuantity', sort: 'asc' },
        { index: 15, field: 'stockAvailabilityQuantity', sort: 'desc' },
        { index: 16, field: 'weight', sort: 'asc' },
        { index: 17, field: 'weight', sort: 'desc' },
        { index: 18, field: 'ordinalNumber', sort: 'asc' },
        { index: 19, field: 'ordinalNumber', sort: 'desc' }
    ];

    // Zobrazené parametre
    let filterParametersColumnSaved: number[] = [];
    let filterParametersColumnSavedArray = LoadLocalSettings('products-parameters-column', '');
    if (filterParametersColumnSavedArray.length > 0) {
        filterParametersColumnSaved = filterParametersColumnSavedArray.split(',').map(v => parseInt(v));
    }
    const filterParametersColumnChanged = (values: number[]) => {
        SaveLocalSettings('products-parameters-column', values.join(','));
        setRowsFilter(prev => ({ ...prev, parametersColumn: values }));
    };

    // Tabuľka
    const [rows, setRows] = useState<ProductLite[]>([]);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([]);
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsOptionsMenuEl, setRowsOptionsMenuEl] = useState<null | HTMLElement>(null);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [rowsFilter, setRowsFilter] = useState<ProductsFilter>({
        page: 0,
        pageSize: gridSettings.pageSizeApply(25),
        sort: columnsSortDefault,
        categoryId: props?.categoryId ?? parseInt(LoadLocalSettings('products-category', '0')),
        parametersColumn: filterParametersColumnSaved
    });
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>();

    // Číselníky
    const [stockAvailabilities, setStockAvailabilities] = useState<StockAvailability[]>([]);
    const [parameters, setParameters] = useState<Parameter[]>([]);

    // Aplikujem uložené nastavenia gridu
    useEffect(() => setColumns(gridSettings.columnApply(columns)), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre získanie počtu aktívnych filtrov
    const filterCount = (): number => {
        let count = 0;
        if ((rowsFilter.domainId ?? 0) !== 0) { count++; }
        if ((rowsFilter.tagId ?? 0) !== 0) { count++; }
        if ((rowsFilter.actived ?? 0) !== 0) { count++; }
        if ((rowsFilter.hideCart ?? 0) !== 0) { count++; }
        if ((rowsFilter.name ?? '') !== '') { count++; }
        if ((rowsFilter.model ?? '') !== '') { count++; }
        if ((rowsFilter.ean ?? '') !== '') { count++; }
        if ((rowsFilter.mpn ?? '') !== '') { count++; }
        if ((rowsFilter.code ?? '') !== '') { count++; }
        if ((rowsFilter.codeSupplier ?? '') !== '') { count++; }
        if ((rowsFilter.externalCatalogId ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockAvailabilityId ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockAvailabilityQuantityFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.stockAvailabilityQuantityTo ?? 0) !== 0) { count++; }
        if ((rowsFilter.priceFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.priceTo ?? 0) !== 0) { count++; }
        if ((rowsFilter.weightFrom ?? 0) !== 0) { count++; }
        if ((rowsFilter.weightTo ?? 0) !== 0) { count++; }
        return count;
    };

    // Nastavenie zobrazenia    
    const [density, setDensity] = useState<GridDensity>('standard' as GridDensity);
    const [sidebarExtend, setSidebarExtend] = useState<boolean>(LoadLocalSettings('products-sidebar-extend', 'true') === 'true');

    // Zmena zobrazenia
    const sidebarExtendSwitch = () => {
        setSidebarExtend(!sidebarExtend);
        SaveLocalSettings('products-sidebar-extend', (!sidebarExtend).toString());
    };

    // Automatické odovzdanie označených záznamov, ak je nastavená udalosť
    useEffect(() => {
        if (props?.onSelectionChanged) {
            const ids = rowsSelected.map(r => r as number);
            props.onSelectionChanged(rows.filter(item => ids.includes((item.id ?? 0))) ?? [], false);
        }
    }, [rowsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak sa v zozname zmení poradie, tak automaticky upravím filter
    useEffect(() => {
        let sortIndex: number = columnsSortDefault;
        if (sortModel !== undefined && sortModel.length > 0) {
            sortIndex = columnsSort.find(s => s.field === sortModel[0].field && s.sort === sortModel[0].sort)?.index ?? 0;
        }
        if (rowsFilter.sort !== sortIndex) {
            setRowsFilter(prev => ({ ...prev, sort: sortIndex }));
        }
    }, [sortModel]); // eslint-disable-line react-hooks/exhaustive-deps

    // Dvoj-klik v zozname
    const handleDoubleClick = (id: number, field: string) => {
        // Ak požadujem výber záznamu, tak po dvoj-kliku prenesiem konkrétny záznam
        if (props?.onSelectionChanged) {
            const item = rows.find(item => item.id === id);
            if (item !== undefined) {
                props.onSelectionChanged([item], true);
                return;
            }
        }
        // Predvolená akcia pre úpravu záznamu
        handleCreate(id, false, field);
    };

    // Pridať upraviť záznam
    const handleCreate = (id: number | number[], copy?: boolean, field?: string) => {
        setProductCreate(prev => ({
            ...prev,
            id: (typeof id === 'number' ? id : id[0]),
            ids: (typeof id === 'number' ? undefined : id),
            copy: copy ?? false,
            open: true,
            autoFocus: field
        }));
    };

    // Úprava záznamu podľa "id" v URL
    const history = useHistory();
    const requestId: number = parseInt(useQuery().get('id') ?? '0');
    useEffect(() => {
        if (requestId > 0) {
            history.push(AppRouteUrl.PRODUCTS);
            handleCreate(requestId);
        }
    }, [requestId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vymazať záznam
    const handleDelete = (id: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'product/' + id)
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleDeleteList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + ids.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .delete(AppConfig.ApiUri + 'product/list', {
                        params: {
                            'ids': ids
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadData();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleActivateList = (ids: number[], actived: boolean) => {
        if (ids.length === 0) {
            return;
        }
        setConfirm(prev => ({
            ...prev, open: true, title: (actived === true ? 'Aktivovať záznamy: ' : 'Deaktivovať záznamy: ') + ids.length, children: 'Skutočne chcete vykonať úpravu?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'product/actived', null, {
                        params: {
                            'ids': ids,
                            'actived': actived
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Funkcie pre označené záznamy
    const handleEditList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        handleCreate(ids);
    };
    const handleAvailabilityList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setProductsAvailability(prev => ({
            ...prev, open: true, onSelect: (id: number) => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'product/availability', null, {
                        params: {
                            'ids': ids,
                            'stockAvailabilityId': id
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };
    const handleMoveList = (ids: number[]) => {
        if (ids.length === 0) {
            return;
        }
        setProductsMove(prev => ({
            ...prev, open: true, onSelect: (id: number) => {
                setConfirm(prev => ({ ...prev, open: false }));
                setLoading(true);
                axios
                    .post(AppConfig.ApiUri + 'product/category', null, {
                        params: {
                            'ids': ids,
                            'categoryId': id
                        }
                    })
                    .then(response => {
                        if (response.data === true) {
                            loadDataSafe();
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }));
    };

    // Načítam zoznam dostupností
    const loadStockAvailabilities = () => {
        axios
            .get(AppConfig.ApiUri + 'stockavailability')
            .then(response => {
                setStockAvailabilities(response.data as StockAvailability[]);
            });
    };
    useEffect(() => loadStockAvailabilities(), []);

    // Načítam zoznam parametrov
    const loadParameters = () => {
        axios
            .get(AppConfig.ApiUri + 'parameter')
            .then(response => {
                setParameters(response.data as Parameter[]);
            });
    };
    useEffect(() => loadParameters(), []);

    // Uloženie kategórii
    let categoriesSaved: Category[] = [];
    let categoriesSavedData = LoadLocalSettings('products-categories', '');
    if (categoriesSavedData.length > 0) {
        try {
            const categoriesSavedJson = JSON.parse(categoriesSavedData);
            if (categoriesSavedJson != null && categoriesSavedJson.list !== undefined) {
                categoriesSaved = categoriesSavedJson.list as Category[];
            }
        } catch (e) { console.log(e); }
    }
    const categoriesChanged = (categoryList: Category[]) => {
        SaveLocalSettings('products-categories', JSON.stringify({ list: categoryList }));
    };

    // Kategórie
    const [categories, setCategories] = useState<Category[]>(categoriesSaved);

    // Funkcia pre načítanie kategórii
    const loadDataCategories = () => {
        axios
            .get(AppConfig.ApiUri + 'category')
            .then(response => {
                setCategories(response.data as Category[]);
                categoriesChanged(response.data as Category[]);
            });
    };
    useEffect(() => loadDataCategories(), []); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia po kliknutí na kategóriu
    const handleCategoriesClick = (id: number) => {
        if (rowsFilter.categoryId !== id) {
            setRowsFilter(prev => ({ ...prev, page: 0, categoryId: id }));
            SaveLocalSettings('products-category', id.toString());
        }
    };

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        DataGridExport({
            type: type,
            columns: gridSettings.columnApply(columns),
            columnsSkip: ['options', 'file'],
            rows: rows,
            ids: rowsSelected.map(r => r as number),
            specific: [{
                field: 'dimensions',
                getValue: (row: any) => {
                    return (row?.dimensionWidth ?? 0) + ' / ' + (row?.dimensionLength ?? 0) + ' / ' + (row?.dimensionHeight ?? 0);
                }
            }]
        });
    };

    // Funkcia pre načítanie produktov
    const loadData = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'product', {
                params: rowsFilter
            })
            .then(response => {
                setRows(response.data?.list ?? []);
                setRowsCount(response.data?.itemsCount ?? 0);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Funkcia pre bezpečné obnovenie zoznamu pomocou zmeny stavu (cudzie vlákno môže mať problém so stavom filtra)
    const loadDataSafe = () => setRowsFilter(prev => ({ ...prev }));

    // Automatická obnova zoznamu po otvorení stránky alebo zmene filtru
    const loadDataDebounce = Debounce(() => loadData(), 100);
    useEffect(() => loadDataDebounce(), [rowsFilter]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/* Preloader */}
            <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Obsah */}
            <Content>
                <ContentTop>
                    {/* Horný panel */}
                    <Grid container alignItems="center">

                        {/* Možnosti */}
                        <Grid item xs={9} md>

                            {/* Nový záznam */}
                            <Button variant="contained" sx={{ mr: 1 }} size="large" startIcon={<AddIcon />} onClick={() => handleCreate(0)}>Nový produkt</Button>

                            {/* Označené záznamy (možnosti) */}
                            <Button variant="text" size="large" disabled={rowsSelected?.length === 0} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                            <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                                <MenuItem onClick={() => { handleAvailabilityList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><FlagIcon fontSize="small" /></ListItemIcon> Zmeniť dostupnosť
                                </MenuItem>
                                <MenuItem key={0} onClick={() => { handleMoveList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><OpenWithIcon fontSize="small" /></ListItemIcon> Zmeniť kategóriu
                                </MenuItem>
                                <MenuItem onClick={() => { handleEditList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon> Upraviť hromadne
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleActivateList(rowsSelected.map(r => r as number), true); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><CheckBoxIcon fontSize="small" /></ListItemIcon> Označiť ako aktívne
                                </MenuItem>
                                <MenuItem onClick={() => { handleActivateList(rowsSelected.map(r => r as number), false); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><CheckBoxOutlineBlankIcon fontSize="small" /></ListItemIcon> Označiť ako neaktívne
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleExport('csv'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                                </MenuItem>
                                <MenuItem onClick={() => { handleExport('xml'); }}>
                                    <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { handleDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                                    <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber
                                </MenuItem>
                            </Menu>
                        </Grid>

                        {/* Možnosti */}
                        <Menu id="menu-selected" anchorEl={rowsOptionsMenuEl} open={Boolean(rowsOptionsMenuEl)} onClose={() => setRowsOptionsMenuEl(null)}>
                            {/* <MenuItem onClick={() => { alert('Pripravujeme!'); setRowsOptionsMenuEl(null); }}>
                                <ListItemIcon><EuroIcon fontSize="small" /></ListItemIcon> Preceniť produkty
                            </MenuItem>
                            <Divider /> */}
                            <Box sx={{ px: 1, m: 0 }}>
                                <FormControl size="small" margin="dense" variant="outlined" fullWidth sx={{ width: '250px' }}>
                                    <InputLabel id="products-options-parameters">Parametre v zozname</InputLabel>
                                    <Select multiple labelId="products-options-parameters" label="Parametre v zozname" value={rowsFilter.parametersColumn ?? []} onChange={(e) => filterParametersColumnChanged(e.target.value as number[])}>
                                        {parameters.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Box>
                            {(rowsFilter.parametersColumn ?? []).length > 0 && (
                                <MenuItem dense onClick={() => { filterParametersColumnChanged([]); }}>Zrušiť vybrané</MenuItem>
                            )}
                        </Menu>

                        {/* Zobrazenie a možnosti */}
                        <Grid item xs md={3} textAlign="right">
                            <Button variant="text" size="small" sx={{ mr: 1 }} color="secondary" aria-label="Možnosti" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsOptionsMenuEl(e.currentTarget)} endIcon={<SettingsIcon />}>Možnosti</Button>
                            <DataGridDensity onDensityChanged={setDensity} />
                        </Grid>

                        {/* Vyhľadávanie */}
                        <Grid item xs={12} md={4} sx={{ mt: { xs: 1, md: 0 } }}>
                            <Search
                                onSearch={s => setRowsFilter(prev => ({ ...prev, page: 0, fulltext: s }))}
                                onClear={() => {
                                    setRowsFilter(prev => ({
                                        page: 0,
                                        pageSize: prev.pageSize,
                                        sort: prev.sort,
                                        categoryId: prev.categoryId,
                                        parametersColumn: prev.parametersColumn
                                    }));
                                }}
                                autoFocus={true}
                                filter={true}
                                filterCount={filterCount()}
                                onFilter={() => setRowsFiltering(prev => ({ ...prev, filter: rowsFilter, open: true }))} />
                        </Grid>
                    </Grid>
                </ContentTop>
                <ContentBottom>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <Grid container columnSpacing={1}>
                            {/* Kategórie */}
                            <Grid item xs={12} md={(sidebarExtend ? 5 : 4)} lg={(sidebarExtend ? 4 : 3)} xl={(sidebarExtend ? 3 : 2)}>
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Paper variant="outlined" sx={{ position: 'absolute', width: '100%', height: '100%' }}>
                                        <Box sx={{ position: 'relative', width: '100%', height: '100%', overflowY: 'auto', p: 1 }}>
                                            <Categories hideUncategorized={false} categories={categories} activeCategoryId={rowsFilter.categoryId ?? 0} onClick={handleCategoriesClick} onCategoriesChanged={loadDataCategories} />
                                            <IconButton size="small" sx={{ position: 'absolute', top: 70, right: 15, opacity: 0.5, '&:hover': { opacity: 1 } }} onClick={() => sidebarExtendSwitch()}>
                                                {sidebarExtend ? <CloseFullscreenIcon fontSize='small' /> : <OpenInFullIcon fontSize='small' />}
                                            </IconButton>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Grid>
                            {/* Produkty */}
                            <Grid item xs={12} sm lg xl>
                                <Box sx={{ height: '100%', width: '100%' }}>
                                    <DataGrid
                                        getRowId={row => row.id}
                                        density={density}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        columns={columns}
                                        rows={rows}
                                        rowCount={rowsCount}

                                        pagination
                                        paginationMode="server"
                                        page={rowsFilter.page}
                                        pageSize={rowsFilter.pageSize}

                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        onPageChange={(page) => setRowsFilter(prev => ({ ...prev, page: page }))}
                                        onPageSizeChange={(pageSize) => {
                                            setRowsFilter(prev => ({ ...prev, page: 0, pageSize: pageSize }));
                                            gridSettings.pageSizeChanged(pageSize);
                                        }}

                                        sortingMode="server"
                                        sortModel={sortModel}
                                        onSortModelChange={(model) => setSortModel(model)}

                                        localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                                        loading={loading}

                                        // Dvoj-klik (úprava)
                                        onCellDoubleClick={(e) => {
                                            handleDoubleClick(e.row.id, e.field);
                                        }}

                                        // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                                        onCellKeyDown={(e, c) => {
                                            if (c.code === 'Enter' && c.ctrlKey) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, true, e.field);
                                                return;
                                            }
                                            if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleCreate(e.row.id, false, e.field);
                                                return;
                                            }
                                            if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                                c.preventDefault();
                                                c.stopPropagation();
                                                handleDelete(e.row.id, e.row.name);
                                                return;
                                            }
                                        }}

                                        // Filtrácia
                                        filterModel={filterModel}
                                        onFilterModelChange={e => setFilterModel(e)}

                                        // Vybrané záznamy
                                        selectionModel={rowsSelected}
                                        onSelectionModelChange={e => setRowsSelected(e)}

                                        // Stĺpce (automatické ukladanie nastavení)
                                        onColumnVisibilityChange={e => gridSettings.columnVisibilityChanged(e, columnsDefault)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </ContentBottom>
            </Content>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Formulár pre nový záznam */}
            <ProductCreate {...productCreate} categories={categories} categoryId={(rowsFilter.categoryId ?? 0) > 0 ? rowsFilter.categoryId : 0} />

            {/* Zmena dostupnosti */}
            <ProductsAvailability {...productsAvailability} stockAvailabilities={stockAvailabilities} />

            {/* Zmena kategórie */}
            <ProductsMove {...productsMove} categories={categories} onCategoriesChanged={loadDataCategories} />

            {/* Formulár pre filtráciu */}
            <ProductsFiltering {...productsFiltering} stockAvailabilities={stockAvailabilities} />

        </>
    )
}

export default Products;