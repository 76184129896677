import React, { FormEvent, useEffect, useState } from 'react'

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, MathRound2 } from '../../utility/Number';

// Modely
import { DocumentItem, DocumentItemCompute, DocumentItemType, Shipment } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select, Menu } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): DocumentItem => ({
    id: 0,
    parentId: 0,
    productId: 0,
    type: DocumentItemType.Shipment,
    ordinalNumber: 0,
    ean: '',
    code: '',
    codeSupplier: '',
    name: '',
    variant: '',
    note: '',
    weight: 0,
    quantity: 1,
    unit: 'ks',
    // pricePurchase: 0,
    // pricePurchaseVatRate: 0,
    // price: 0,
    // priceCalc: 0,
    priceIncludeVat: true,
    // priceVatRate: 0,
    // priceUnitNoVat: 0,
    // priceUnitVatAmount: 0,
    // priceTotalNoVat: 0,
    // priceTotalVatAmount: 0,
    configurator: []
});

// Vstupné parametre
export interface DocumentCreateItemShipmentProps {
    item?: DocumentItem;
    itemIndex?: number;
    vatRates: number[];
    vatRateDefault: number;
    priceIncludeVat: boolean;
    priceTotal: number;
    shipments: Shipment[];
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (item: DocumentItem, itemIndex?: number) => void;
    onClose: () => void;
}

const DocumentCreateItemShipment = (props: DocumentCreateItemShipmentProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<DocumentItem>(EmptySource());
    const [optionsMenuEl, setOptionsMenuEl] = useState<null | HTMLElement>(null);

    // Automaticky vyplním okno
    useEffect(() => {
        if (!props.open) {
            return;
        }
        // Použijem props.item alebo emptySource
        setSource((props.item ?? {
            ...EmptySource(),
            priceIncludeVat: props.priceIncludeVat,
            priceVatRate: props.vatRateDefault
        }));
    }, [props.item, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            ...prev,
            ...EmptySource(),
            id: prev.id,
            ordinalNumber: prev.ordinalNumber,
            priceIncludeVat: props.priceIncludeVat,
            priceVatRate: props.vatRateDefault
        }))
    };

    // Vloženie možnosti z číselníku (v administrácii sú obmedzenia číselníka ignorované)
    const handleShipmentSelect = (shipment: Shipment) => {

        // Poplatok za zvolenú možnosť
        let shipmentFee = shipment.feeValue ?? 0; // fixná cena, alebo percentá
        let shipmentFeeFeeFrom = (shipment.feeFreeFrom ?? 0); // zdarma od sumy
        let shipmentFeePrice = 0;

        // Pokračujem, len ak je nastavená nejaká hodnota poplatku a súčasne som neprekročil "zdarma od sumy"
        if (shipmentFee > 0 && !(shipmentFeeFeeFrom > 0 && props.priceTotal >= shipmentFeeFeeFrom)) {
            if ((shipment.feeType ?? 0) === 0) {
                // Fixná suma
                shipmentFeePrice = shipmentFee;
            } else {
                // Percentá
                shipmentFeePrice = MathRound2((props.priceTotal / 100) * shipmentFee);
            }
        }

        setSource(prev => DocumentItemCompute({
            ...prev,
            name: shipment.other?.translationName ?? '',
            price: shipmentFeePrice,
            priceVatRate: shipment.feeVatRate ?? 0
        }));
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source, props.itemIndex);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
            // Automaticky označím text vo vybraných poliach
            if ((props.autoFocus === 'quantity' || props.autoFocus === 'price') &&
                (input?.select !== undefined && typeof input.select === 'function')) {
                setTimeout(() => { input?.select(); }, 250);
            }
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        // Prepočet spustím len ak som zmenil niektorú z hodnôt, ktoré ho ovplyvňujú
        let compute = [
            'quantity',
            'price',
            'priceCalc',
            'priceIncludeVat',
            'priceVatRate'
        ].includes(property);

        // Upraví stav položky
        setSource(prev => {
            if (compute) {
                return DocumentItemCompute(({ ...prev, [property]: value }), property);
            }
            return ({ ...prev, [property]: value });
        });
    }

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.itemIndex === undefined ? 'Nový spôsob dopravy' : 'Spôsob dopravy'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth required margin="dense" name="price" type="text" label={'Poplatok ' + (source.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'price' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="priceCalc" type="text" label={'Poplatok ' + (!source.priceIncludeVat ? '(s DPH)' : '(bez DPH)')} variant="outlined" autoComplete="off" value={source.priceCalc ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'priceCalc' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="product-create-vatrate">DPH</InputLabel>
                                    <Select required labelId="product-create-vatrate" label="DPH" value={source.priceVatRate ?? 0} name="priceVatRate" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'priceVatRate' && setFocus(input)}>
                                        {(props.vatRates ?? []).length === 0 && <MenuItem value={0}>0 %</MenuItem>}
                                        {props.vatRates.map(item => <MenuItem key={item} value={item}>{item} %</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {props.shipments.length > 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <Button variant="contained" size="small" startIcon={<ArrowDropDownIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} aria-label="Vložiť" aria-controls="menu-addresses" aria-haspopup="true"
                                            onClick={(e) => setOptionsMenuEl(e.currentTarget)}>Vybrať ({props.shipments?.length ?? 0})</Button>
                                    </Grid>
                                    <Menu id="menu-addresses" anchorEl={optionsMenuEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} sx={{ ml: '5px' }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} open={Boolean(optionsMenuEl)} onClose={() => setOptionsMenuEl(null)} >
                                        {props.shipments?.map((shipment, index) => (
                                            <MenuItem key={index} onClick={() => { handleShipmentSelect(shipment); setOptionsMenuEl(null); }}>{shipment.other?.translationName}</MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DocumentCreateItemShipment;