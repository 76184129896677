import React, { useEffect, useState } from 'react';

// Modely
import { FileDirectory } from '../../models/Models';

// Komponenty
import { TextField, InputAdornment } from '@mui/material';
import FileDirectorySelectDialog, { FileDirectorySelectDialogProps } from '../file/FileDirectorySelectDialog';

// Ikony
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export interface FileDirectorySelectProps {
    label?: string;
    activeDirectoryId?: number;          // Aktuálne označená kategória
    hiddenDirectoryId?: number;          // Skrytá vetva kategórie, napr. ak vyberám nadradenú kategóriu tak nemôžem dovoliť vybrať seba samú, inak hrozí zacyklenie a to, že sa nevykreslí.
    directories: FileDirectory[];             // Zoznam kategórii
    onDirectoriesChanged?: () => void;   // Oznámenie zmeny v kategóriach (CRUD)
    onSelect: (id: number) => void;
}

const FileDirectorySelect = (props: FileDirectorySelectProps) => {

    // Stav
    const [selectedFileDirectoryName, setSelectedFileDirectoryName] = useState<string>('...');

    // Zobrazenie výberu kategórie
    const [fileDirectorySelectDialog, setFileDirectorySelectDialog] = useState<FileDirectorySelectDialogProps>({
        open: false,
        directories: [],
        onSelect: props.onSelect,
        onClose: () => handleFileDirectorySelectDialogClose()
    });
    const handleFileDirectorySelectDialogOpen = () => {
        setFileDirectorySelectDialog(prev => ({ ...prev, open: true }));
    };
    const handleFileDirectorySelectDialogClose = () => {
        setFileDirectorySelectDialog(prev => ({ ...prev, open: false }));
    };

    // Získam názov aktívnej kategórie s jednou nadradenou kategóriou
    useEffect(() => {
        // Ak nie možné získať názov, tak ďalej nepokračujem
        if (((props.activeDirectoryId ?? 0) === 0 || props.directories.length === 0) && selectedFileDirectoryName !== '') {
            setSelectedFileDirectoryName('');
            return;
        }

        // Názov označenej kategórie
        let name = '';
        let active = props.directories.find(c => c.id === props.activeDirectoryId);
        if (active !== undefined) {
            name = active?.name ?? '';

            // Pridám názov nadradenej kategórie
            if ((active.parentId ?? 0) > 0) {
                let parent = props.directories.find(c => c.id === active?.parentId);
                if (parent !== undefined) {
                    name = (parent?.name ?? '') + ' / ' + name;

                    // Ak má aj naradená nadradenú, tak pridám tri bodky na začiatok
                    if ((parent.parentId ?? 0) > 0) {
                        name = '... / ' + name;
                    }
                }
            }
        }

        setSelectedFileDirectoryName(name);
    }, [props.activeDirectoryId, props.directories]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <TextField fullWidth margin="none" label={props.label ?? "Adresár"} variant="outlined" autoComplete="off" onClick={handleFileDirectorySelectDialogOpen}
                value={selectedFileDirectoryName}
                inputProps={{ style: { cursor: 'pointer' } }}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                            <FolderOpenIcon />
                        </InputAdornment>
                    ),
                }} />
            <FileDirectorySelectDialog
                {...fileDirectorySelectDialog}
                activeDirectoryId={props.activeDirectoryId}
                hiddenDirectoryId={props.hiddenDirectoryId}
                directories={props.directories}
                onDirectoriesChanged={props.onDirectoriesChanged}
            />
        </>
    )
}

export default FileDirectorySelect;