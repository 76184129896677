import { useEffect, useState } from 'react';

// Modely
import { FileDirectory } from '../../models/Models';

// Komponenty
import { Dialog, DialogContent, DialogActions, Button, Grid } from '@mui/material';
import FilesDirectories from '../file/FilesDirectories';

export interface FileDirectorySelectDialogProps {
    open: boolean;
    keepMounted?: boolean;
    activeDirectoryId?: number;          // Aktuálne označená kategória
    hiddenDirectoryId?: number;          // Skrytá vetva kategórie, napr. ak vyberám nadradenú kategóriu tak nemôžem dovoliť vybrať seba samú, inak hrozí zacyklenie a to, že sa nevykreslí.
    directories: FileDirectory[];       // Zoznam adresárov
    onDirectoriesChanged?: () => void;  // Oznámenie zmeny v kategóriach (CRUD)
    onSelect: (id: number) => void;
    onClose: () => void;
}

const FileDirectorySelectDialog = (props: FileDirectorySelectDialogProps) => {

    // Stav
    const [activeFileDirectoryId, setActiveFileDirectoryId] = useState<number | undefined>(props.activeDirectoryId ?? 0);

    // Aktívnu kategóriu posúvam do zoznamu cez stav, aby som vedel kategórie zbaliť nastavením hodnoty "undefined"
    useEffect(() => {
        setActiveFileDirectoryId(props.activeDirectoryId);
    }, [props.activeDirectoryId]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => props.onClose()}>
                <DialogContent sx={{ maxHeight: '75vh', padding: 1 }}>
                    <Grid container columnSpacing={1}>
                        <Grid item xs={12} mt={0.5}>
                            <FilesDirectories
                                directories={props.directories}
                                activeDirectoryId={activeFileDirectoryId}
                                hiddenDirectoryId={props.hiddenDirectoryId}
                                onChanged={props.onDirectoriesChanged}
                                hideTrash={true}
                                onClick={(id: number) => {
                                    props.onSelect(id);
                                    props.onClose();
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ paddingTop: 1 }}>
                    <Button onClick={() => setActiveFileDirectoryId(undefined)} color="secondary" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Zbaliť priečinky</Button>
                    <Button onClick={props.onClose}>Späť</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FileDirectorySelectDialog;