import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, ConvertToInt } from '../../../utility/Number';
import { EscapeSlug } from '../../../utility/Text';
import { File, ParameterItemPriceList, PriceList } from '../../../models/Models';

// Modely
import { ParameterItem, ParameterItemTranslation, Language } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, Card, CardMedia, CardActions, Typography, Table, TableHead, TableRow, TableCell, TableBody, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FilesDialog, { FilesDialogProps } from '../../file/FilesDialog';

// Ikony
import SortIcon from '@mui/icons-material/Sort';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LinkIcon from '@mui/icons-material/Link';
import EuroIcon from '@mui/icons-material/Euro';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): ParameterItem => ({
    id: 0,
    actived: true,
    fileId: 0,
    ordinalNumber: 0,
    color: '',
    default: false,
    translations: []
});

// Vstupné parametre
export interface ParameterItemCreateProps {
    open: boolean;
    parameterId: number;
    parameterName?: string;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const ParameterItemCreate = (props: ParameterItemCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<ParameterItem>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [priceLists, setPriceLists] = useState<PriceList[]>([]);
    const [filesDialog, setFilesDialog] = useState<FilesDialogProps>({
        open: false,
        onSelect: (files: File[]) => {
            if (files.length > 0 && files[0].fileIsImage) {
                setSource(prev => ({
                    ...prev,
                    fileId: files[0].id,
                    other: {
                        ...prev.other,
                        fileSrcMedium: files[0].fileSrcMedium
                    }
                }));
            }
            handleFilesClose();
        },
        onClose: () => handleFilesClose()
    });

    // Harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Zobrazenie súborov
    const handleFilesOpen = () => {
        setFilesDialog(prev => ({ ...prev, open: true }));
    };
    const handleFilesClose = () => {
        setFilesDialog(prev => ({ ...prev, open: false }));
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        if ((props.id ?? 0) === 0) {
            setSource(EmptySource());
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'parameteritem/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as ParameterItem);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Načítam zoznam cenníkov
    const loadPriceLists = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'pricelist')
            .then(response => {
                setPriceLists(response.data.filter((pricelist: PriceList) => pricelist.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadPriceLists(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangePriceList = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: ParameterItemPriceList = { ...prev?.priceLists?.find(item => item.priceListId === id), priceListId: id, [property]: value };
            const next = { ...prev, priceLists: [...prev?.priceLists?.filter(item => item.priceListId !== id) ?? [], item] };
            return next;
        });
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: ParameterItemTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            if (property === 'name') {
                item['slug'] = EscapeSlug(value);
            }
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        source.parameterId = props.parameterId;

        axios
            .post(AppConfig.ApiUri + 'parameteritem', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <FilesDialog {...filesDialog} />

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Hodnota' : 'Nová hodnota')}
                        {((props.parameterName ?? '').length > 0 && ' (' + props.parameterName + ')')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} md={4} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívna" />
                            </Grid>
                            <Grid item xs={12} md={8} mb={1}>
                                <FormControlLabel control={<Switch checked={source.default} name="default" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Predvolená" />
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <TextField fullWidth margin="dense" name="color" type="color" label="Farba" variant="outlined" value={source.color?.length === 6 ? '#' + source.color : ''} onChange={(e) => { handleChange(e.target.name, e.target.value.replace('#', '')) }}
                                    inputRef={input => props.autoFocus === 'color' && setFocus(input)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormatColorFillIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <TextField fullWidth margin="dense" name="color" label="Farba (HEX)" variant="outlined" value={source.color !== '' ? source.color : ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => props.autoFocus === 'color' && setFocus(input)}
                                    inputProps={{ maxLength: 6 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FormatColorFillIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <React.Fragment key={language.id}>
                                        <Grid item xs={12} md={6}>
                                            <TextField required margin="dense" name="name" label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LocalOfferIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField required margin="dense" name="slug" label={'Slug (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => props.autoFocus === 'slug' && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.slug ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, EscapeSlug(e.target.value)) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Slug - unikátna hodnota pre parameter URL, ktorá bude zastupovať túto hodnotu. Napr. slug 'cerevna' pre hodnotu s názvom 'Červená'.">
                                                                <LinkIcon className="formIconHelp" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}

                            {(priceLists?.length ?? 0) > 0 &&
                                <Grid item xs={12} mt={2}>
                                    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('configurator')} onChange={() => handleToggleAccordion('configurator')}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Cenník pre konfigurátor</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container columnSpacing={1}>
                                                <Grid item xs={12} mt={3}>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Cenník</TableCell>
                                                                <TableCell>Poplatok</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell>
                                                                    <strong>Základná cena</strong>
                                                                </TableCell>
                                                                <TableCell sx={{ width: '50%', p: 0.5, pl: 0 }}>
                                                                    <TextField fullWidth name="price" size="small" type="text" variant="outlined" autoComplete="off"
                                                                        value={source.price ?? ''}
                                                                        onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                        inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <EuroIcon />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }} />
                                                                </TableCell>
                                                            </TableRow>
                                                            {priceLists?.map((pricelist, index) => (
                                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell>
                                                                        <strong>{pricelist.name}</strong><br />
                                                                        zľava {pricelist.discount}% alebo:
                                                                    </TableCell>
                                                                    <TableCell sx={{ width: '50%', p: 0.5, pl: 0 }}>
                                                                        <TextField fullWidth size="small" type="text" variant="outlined" autoComplete="off"
                                                                            value={source.priceLists?.find(item => item.priceListId === pricelist.id)?.price ?? ''}
                                                                            onChange={(e) => { handleChangePriceList(pricelist.id ?? 0, 'price', ConvertToDecimal(e.target.value)) }}
                                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <EuroIcon />
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Grid>
                                                <Grid item xs={12} mt={1}>
                                                    <Alert severity="info">
                                                        Hodnoty parametrov môžu byť v rámci konfigurátora spoplatnené.
                                                        Spoplatnené parametre budú automaticky pripočítané k jednotkovej cene produktu.
                                                        Takto je možné spoplatniť napríklad voliteľné príslušenstvo, výbavu, materiál a podobne.
                                                    </Alert>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }

                            <Grid item xs={12} mt={1}>
                                <Grid item xs={12} md={4}>
                                    {(source.fileId ?? 0) > 0 &&
                                        <Card sx={{ mt: 1 }}>
                                            <CardMedia component="img" height="120" image={source.other?.fileSrcMedium} alt="Náhľad" />
                                            <CardActions>
                                                <Button variant="text" color="secondary" size="small" startIcon={<DeleteIcon />} onClick={() => setSource(prev => ({ ...prev, fileId: 0 }))}>Vymazať</Button>
                                            </CardActions>
                                        </Card>
                                    }
                                    <Button color="secondary" variant="contained" sx={{ my: 1 }} onClick={handleFilesOpen}>Vybrať obrázok</Button>
                                </Grid>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ParameterItemCreate;