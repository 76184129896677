import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../../utility/Number';

// Modely
import { EmailAccount } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, FormControlLabel, Tooltip, Box, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import StorageIcon from '@mui/icons-material/Storage';
import NumbersIcon from '@mui/icons-material/Numbers';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): EmailAccount => ({
    id: 0,
    default: false,
    name: '',
    email: '',
	imapUsername: '',
	imapPassword: '',
	imapServer: '',
	imapPort: 143,
	imapSecurity: 0,
	smtpUsername: '',
	smtpPassword: '',
	smtpServer: '',
	smtpPort: 25,
	smtpSecurity: 0
});

// Vstupné parametre
export interface EmailAccountCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const EmailAccountCreate = (props: EmailAccountCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<EmailAccount>(EmptySource());
    
    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if(!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'emailaccount/' + props.id)
            .then(response => {
                if(response.data !== null) {
                    if(props.copy) {
                        response.data.id = 0;
                        response.data.default = false;
                    }
                    setSource(response.data as EmailAccount);
                }
            })
            .finally(() => {
                setLoading(false);
            }); 
    }, [props.id, props.copy, props.open]); 

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        setSource(prev => ({ ...prev, [property] : value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => { 
        if(warning.text.length > 0) {
            setWarning(EmptyWarning);
        }        
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'emailaccount', source)
            .then(response => {
                if(response.data > 0) {
                    if(props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            }); 
    };

    const handleVerifyAccount = (inbox: boolean) => {
        setLoading(true);
        if(inbox) {
            axios
                .post(AppConfig.ApiUri + 'emailaccount/verifyimap', source)
                .then(response => {
                    if(response.data === 'success') { 
                        setWarning({ ...EmptyWarning, text: 'Overenie úspešné!', color: "success" });
                    } else {
                        setWarning({ ...EmptyWarning, text: 'Nesprávne údaje!' });
                    }
                })
                .finally(() => {
                    setLoading(false);
                }); 
        }
        else {
            axios
                .post(AppConfig.ApiUri + 'emailaccount/verifysmtp', source)
                .then(response => {
                    if(response.data === 'success') { 
                        setWarning({ ...EmptyWarning, text: 'Overenie úspešné!', color: "success" });
                    } else {
                        setWarning({ ...EmptyWarning, text: 'Nesprávne údaje!' });
                    }
                })
                .finally(() => {
                    setLoading(false);
                }); 
        }
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if(focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);
    
    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="md" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick') { props.onClose(); }}}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        { ((props?.id ?? 0) > 0 ? 'Emailový účet' : 'Nový emailový účet') }
                        { (props.copy && ' (kópia)') }
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} sm={6} mb={1}>
                                <FormControlLabel control={<Switch checked={source.default} name="default" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Predvolený účet" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Meno odosielateľa" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Meno ktoré bude zobrazené ako odosielateľ emailu.">
                                                    <PersonIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required fullWidth margin="dense" name="email" type="email" label="Emailová adresa" variant="outlined" autoComplete="email" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => props.autoFocus === 'email' && setFocus(input)}
                                    inputProps={{ maxLength: 120 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Emailová adresa odosielateľa, ktorá je zobrazená spolu s menom odosielateľa.">
                                                    <EmailIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item container xs={12} md={6} columnSpacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom component="div" mt={2} align="center">
                                        IMAP server
                                    </Typography>  
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required margin="dense" name="imapUsername" label="Prihlasovacie meno" fullWidth variant="outlined" autoComplete="off" value={source.imapUsername ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                        inputRef={input => props.autoFocus === 'imapUsername' && setFocus(input)}
                                        inputProps={{ maxLength: 60 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required margin="dense" name="imapPassword" label="Prihlasovacie heslo" fullWidth variant="outlined" type="password" autoComplete="off" value={source.imapPassword ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                        inputRef={input => props.autoFocus === 'imapPassword' && setFocus(input)}
                                        inputProps={{ maxLength: 60 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <KeyIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField required margin="dense" name="imapServer" label="IMAP server" fullWidth variant="outlined" autoComplete="off" value={source.imapServer ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                        inputRef={input => props.autoFocus === 'imapServer' && setFocus(input)}
                                        inputProps={{ maxLength: 60 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <StorageIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField required fullWidth margin="dense" name="imapPort" type="number" label="Port" variant="outlined" value={source.imapPort ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }} 
                                        inputRef={input => props.autoFocus === 'imapPort' && setFocus(input)}
                                        inputProps={{ min: 0, max: 65535, step: 1 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <NumbersIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="email-account-create-imap-security">Zabezpečenie</InputLabel>
                                        <Select required labelId="email-account-create-imap-security" label="Zabezpečenie" value={source.imapSecurity ?? 0} name="imapSecurity" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                inputRef={input => props.autoFocus === 'imapSecurity' && setFocus(input)}>
                                            <MenuItem value={0}>Nepoužívať zabezpečené pripojenie</MenuItem>
                                            <MenuItem value={1}>Automaticky</MenuItem>
                                            <MenuItem value={2}>Použiť SSL/TSL</MenuItem>
                                            <MenuItem value={3}>Použiť TSL po pripojení</MenuItem>
                                            <MenuItem value={4}>Použiť TSL po pripojení, ak je dostupné</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="secondary" fullWidth onClick={() => handleVerifyAccount(true)}>Skontrolovať / IMAP</Button>
                                </Grid>
                            </Grid>  
                            
                            <Grid item container xs={12} md={6} columnSpacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom component="div" mt={2} align="center">
                                        SMTP server
                                    </Typography>  
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required margin="dense" name="smtpUsername" label="Prihlasovacie meno" fullWidth variant="outlined" autoComplete="off" value={source.smtpUsername ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                        inputRef={input => props.autoFocus === 'smtpUsername' && setFocus(input)} 
                                        inputProps={{ maxLength: 60 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required margin="dense" name="smtpPassword" label="Prihlasovacie heslo" fullWidth variant="outlined" type="password" autoComplete="off" value={source.smtpPassword ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                        inputRef={input => props.autoFocus === 'smtpPassword' && setFocus(input)}
                                        inputProps={{ maxLength: 60 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <KeyIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField required margin="dense" name="smtpServer" label="SMTP server" fullWidth variant="outlined" autoComplete="off" value={source.smtpServer ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                        inputRef={input => props.autoFocus === 'smtpServer' && setFocus(input)}
                                        inputProps={{ maxLength: 60 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <StorageIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField required fullWidth margin="dense" name="smtpPort" type="number" label="Port" variant="outlined" value={source.smtpPort ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }} 
                                        inputRef={input => props.autoFocus === 'smtpPort' && setFocus(input)}
                                        inputProps={{ min: 0, max: 65535, step: 1 }} 
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <NumbersIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                        <InputLabel id="email-account-create-smtp-security">Zabezpečenie</InputLabel>
                                        <Select required labelId="email-account-create-smtp-security" label="Zabezpečenie" value={source.smtpSecurity ?? 0} name="smtpSecurity" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                                inputRef={input => props.autoFocus === 'smtpSecurity' && setFocus(input)} >
                                            <MenuItem value={0}>Nepoužívať zabezpečené pripojenie</MenuItem>
                                            <MenuItem value={1}>Automaticky</MenuItem>
                                            <MenuItem value={2}>Použiť SSL/TSL</MenuItem>
                                            <MenuItem value={3}>Použiť TSL po pripojení</MenuItem>
                                            <MenuItem value={4}>Použiť TSL po pripojení, ak je dostupné</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="secondary" fullWidth onClick={() => handleVerifyAccount(false)}>Skontrolovať / SMTP</Button>
                                </Grid>
                            </Grid>                            

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={ warning.color as AlertColor }>{warning.text}</Alert>)}  

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default EmailAccountCreate;