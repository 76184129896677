import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../../utility/Number';
import { EscapeSlug } from '../../../utility/Text';

// Modely
import { Parameter, ParameterTranslation, Language, ParameterTypes } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import SortIcon from '@mui/icons-material/Sort';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Parameter => ({
    id: 0,
    actived: true,
    required: false,
    ordinalNumber: 0,
    type: 0,
    showInFilter: false,
    showInList: false,
    showInDetail: true,
    displayMethod: 0,
    translations: []
});

// Vstupné parametre
export interface ParameterCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const ParameterCreate = (props: ParameterCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<Parameter>(EmptySource());
    const [languages, setLanguages] = useState<Language[]>([]);
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleToggleAccordion = (name: string) => {
        if (isAccordionExpanded(name)) {
            setAccordionExpanded(prev => prev.filter(item => item !== name));
        } else {
            setAccordionExpanded(prev => [...prev, name]);
        }
    };

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded([]);
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'parameter/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as Parameter);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam kódov jazykov
    const loadLanguages = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadLanguages(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: ParameterTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            if (property === 'name') {
                item['slug'] = EscapeSlug(value);
            }
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'parameter', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Parameter' : 'Nový parameter')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} md={4} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12}></Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="parameter-create-type">Typ *</InputLabel>
                                    <Select required labelId="parameter-create-type" label="Typ *" value={source.type ?? 0} name="type" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        {ParameterTypes.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="parameter-create-sorting">Zobrazenie *</InputLabel>
                                    <Select required labelId="parameter-create-sorting" label="Zobrazenie *" value={source.displayMethod ?? 0} name="displayMethod" onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString())) }}
                                        inputRef={input => props.autoFocus === 'displayMethod' && setFocus(input)}>
                                        <MenuItem value={0}>Text</MenuItem>
                                        <MenuItem value={1}>Farba</MenuItem>
                                        <MenuItem value={2}>Obrázok</MenuItem>
                                        <MenuItem value={3}>Text a obrázok</MenuItem>
                                        <MenuItem value={4}>Text a farba</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Slúži na zoradenie v zozname spolu s názvom.">
                                                    <SortIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {languages.map((language, index) => {
                                return (
                                    <React.Fragment key={language.id}>
                                        <Grid item xs={12} md={6}>
                                            <TextField required margin="dense" name="name" label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <ColorLensIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField required margin="dense" name="slug" label={'Slug (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                                inputRef={input => index === 0 && props.autoFocus === 'slug' && setFocus(input)}
                                                value={source.translations?.find(item => item.languageId === language.id)?.slug ?? ''}
                                                onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, EscapeSlug(e.target.value)) }}
                                                inputProps={{ maxLength: 255 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Slug - unikátna hodnota pre parameter URL, ktorá bude zastupovať tento parameter. Napr. slug 'velkost' pre parameter s názvom 'Veľkosť'.">
                                                                <LinkIcon className="formIconHelp" />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('settings')} onChange={() => { handleToggleAccordion('settings') }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Nastavenia</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.required} name="required" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Povinný" />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.showInFilter} name="showInFilter" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Zobraziť vo filtri" />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.showInList} name="showInList" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Zobraziť v zozname" />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Switch checked={source.showInDetail} name="showInDetail" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Zobraziť v detaile" />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('newitems')} onChange={() => { handleToggleAccordion('newitems') }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Vložiť nové hodnoty</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={12}>
                                                <Alert severity="info">
                                                    Pre hromadné pridanie nových hodnôt je potrebné zapísať každú hodnotu na nový riadok, pričom jej ekvivalent v ďalšom jazyku musí byť v poradí na rovnakom riadku.
                                                </Alert>
                                            </Grid>
                                            {languages.map((language, index) => {
                                                return (
                                                    <Grid key={language.id} item xs={12}>
                                                        <TextField fullWidth multiline rows={5} margin="dense" name="newItems" label={'Nové hodnoty (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                            value={source.translations?.find(item => item.languageId === language.id)?.newItems ?? ''}
                                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'newItems' && setFocus(input)}
                                                            inputProps={{ maxLength: 1000 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FormatListNumberedIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('note')} onChange={() => { handleToggleAccordion('note') }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Poznámka</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>
                                            {languages.map((language, index) => {
                                                return (
                                                    <Grid key={language.id} item xs={12}>
                                                        <TextField fullWidth multiline rows={5} margin="dense" name="note" label={'Poznámka (' + language.code + ')'} variant="outlined" autoComplete="off"
                                                            value={source.translations?.find(item => item.languageId === language.id)?.note ?? ''}
                                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'note' && setFocus(input)}
                                                            inputProps={{ maxLength: 1000 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <EditIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ParameterCreate;