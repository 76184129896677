import React, { FormEvent, useEffect, useState } from 'react'
import { AppConfig } from '../../AppConfig';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import skLocale from 'date-fns/locale/sk';

// Modely
import { Country, CustomerGroup, DocumentsFilter, Domain, Payment, Shipment, DocumentState } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTimePicker } from '@mui/lab';

// Ikony
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import NumbersIcon from '@mui/icons-material/Numbers';
import EuroIcon from '@mui/icons-material/Euro';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Vstupné parametre
export interface DocumentsFilteringProps {
    open: boolean;
    filter?: DocumentsFilter;
    documentStates?: DocumentState[];
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (filter: DocumentsFilter) => void;
    onClose: () => void;
}

// Prázdny filter
const EmptyFilter: DocumentsFilter = {
    page: 0,
    pageSize: 25,
    sort: 0
}

const DocumentsFiltering = (props: DocumentsFilteringProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [domains, setDomains] = useState<Domain[]>([]);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [shipments, setShipments] = useState<Shipment[]>([]);
    const [documentStates, setDocumentStates] = useState<DocumentState[]>([]);
    const [source, setSource] = useState<DocumentsFilter>({ ...EmptyFilter });

    // Automaticky naplním zdroj
    useEffect(() => {
        setSource(props.filter ?? EmptyFilter);
    }, [props.filter, props.open]);
    
    // Načítam zoznam domén
    const loadDomains = () => {
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zoznam zákazníckych skupín
    const loadCustomerGroups = () => {
        axios
            .get(AppConfig.ApiUri + 'customergroup')
            .then(response => {
                setCustomerGroups(response.data);
            });
    };
    useEffect(() => loadCustomerGroups(), []);

    // Načítam zoznam krajín
    const loadCountries = () => {
        axios
            .get(AppConfig.ApiUri + 'country')
            .then(response => {
                setCountries(response.data.filter((country: Country) => country.actived));
            });
    };
    useEffect(() => loadCountries(), []);

    // Načítam zoznam platieb
    const loadPayments = () => {
        axios
            .get(AppConfig.ApiUri + 'payment')
            .then(response => {
                setPayments(response.data.filter((payment: Payment) => payment.actived));
            });
    };
    useEffect(() => loadPayments(), []);

    // Načítam zoznam dopráv
    const loadShipments = () => {
        axios
            .get(AppConfig.ApiUri + 'shipment')
            .then(response => {
                setShipments(response.data.filter((shipment: Shipment) => shipment.actived));
            });
    };
    useEffect(() => loadShipments(), []);

    // Načítam zoznam stavov objednávky
    const loadDocumentStates = () => {
        axios
            .get(AppConfig.ApiUri + 'documentstate')
            .then(response => {
                setDocumentStates(response.data.filter((documentstate: DocumentState) => documentstate.actived));
            });
    };

    // Načítam zoznam stavov, ak nefigurujú na vstupe
    useEffect(() => {
        if (props.documentStates !== undefined) {
            setDocumentStates(props.documentStates);
        } else {
            loadDocumentStates();
        }
    }, [props.documentStates]);
    
    // Zmeny vo formulári
    const handleChange = (property:string, value:any) => {   
        setSource(prev => ({ ...prev, [property] : value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => { 
        if(warning.text.length > 0) {
            setWarning(EmptyWarning);
        }        
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            page: 0,
            pageSize: prev.pageSize,
            sort: prev.sort,
            documentTypeId: prev.documentTypeId
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if(focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);
    
    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick') { props.onClose(); }}}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        Filter
                    </DialogTitle>
                    <DialogContent sx={{ maxHeight: '75vh' }}>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                            
                            <Grid item xs={12} sm={6}>                                
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Vystavené od" value={source.dateOfIssueFrom ?? null} onChange={(d) => { handleChange('dateOfIssueFrom', d) }}                                         
                                        inputRef={input => (props.autoFocus === 'dateOfIssueFrom') && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>                                
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Vystavené do" value={source.dateOfIssueTo ?? null} onChange={(d) => { handleChange('dateOfIssueTo', d) }}                                         
                                        inputRef={input => (props.autoFocus === 'dateOfIssueTo') && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>                                
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Vytvorené od" value={source.createdDateFrom ?? null} onChange={(d) => { handleChange('createdDateFrom', d) }}                                         
                                        inputRef={input => (props.autoFocus === 'createdDateFrom') && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>                                
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DateTimePicker inputFormat='dd.MM.yyyy HH:mm' ampm={false} disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Vytvorené do" value={source.createdDateTo ?? null} onChange={(d) => { handleChange('createdDateTo', d) }}                                         
                                        inputRef={input => (props.autoFocus === 'createdDateTo') && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-domain">Doména</InputLabel>
                                    <Select labelId="filter-domain" label="Doména" value={source.domainId ?? 0} name="domainId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'domainId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { domains?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-state">Stav</InputLabel>
                                    <Select labelId="filter-state" label="Stav" value={source.documentStateId ?? 0} name="documentStateId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'documentStateId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { documentStates?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-payment">Platba</InputLabel>
                                    <Select labelId="filter-payment" label="Platba" value={source.paymentId ?? 0} name="paymentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'paymentId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { payments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-shipment">Doprava</InputLabel>
                                    <Select labelId="filter-shipment" label="Doprava" value={source.shipmentId ?? 0} name="shipmentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'shipmentId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { shipments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-customergroup">Zákaznícka skupina</InputLabel>
                                    <Select labelId="filter-customergroup" label="Zákaznícka skupina" value={source.customerGroupId ?? 0} name="customerGroupId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'customerGroupId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { customerGroups?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-country">Krajina</InputLabel>
                                    <Select labelId="filter-country" label="Krajina" value={source.countryId ?? 0} name="countryId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'countryId' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Všetko</MenuItem>
                                        { countries?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>) }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>
                                                        
                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-done">Vybavené</InputLabel>
                                    <Select labelId="filter-done" label="Vybavené" value={source.done ?? 0} name="done" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'done' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        <MenuItem value={1}>Áno</MenuItem>
                                        <MenuItem value={2}>Nie</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="filter-paid">Uhradené</InputLabel>
                                    <Select labelId="filter-paid" label="Uhradené" value={source.paid ?? 0} name="paid" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                            inputRef={input => props.autoFocus === 'paid' && setFocus(input)}>
                                        <MenuItem value={0}>Všetko</MenuItem>
                                        <MenuItem value={1}>Áno</MenuItem>
                                        <MenuItem value={2}>Nie</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="priceTotalWithVatFrom" type="text" label="Cena od" variant="outlined" autoComplete="off" value={source.priceTotalWithVatFrom ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }} 
                                    inputRef={input => props.autoFocus === 'priceTotalWithVatFrom' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="priceTotalWithVatTo" type="text" label="Cena do" variant="outlined" autoComplete="off" value={source.priceTotalWithVatTo ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }} 
                                    inputRef={input => props.autoFocus === 'priceTotalWithVatTo' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EuroIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="trackingNumber" label="Sledovacie číslo" fullWidth variant="outlined" autoComplete="off" value={source.trackingNumber ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'trackingNumber') && setFocus(input)}
                                    inputProps={{ maxLength: 255 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="email" label="Email" fullWidth variant="outlined" autoComplete="off" value={source.email ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'email') && setFocus(input)}
                                    inputProps={{ maxLength: 120 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="firstname" label="Meno" fullWidth variant="outlined" autoComplete="off" value={source.firstname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'firstname') && setFocus(input)}
                                    inputProps={{ maxLength: 35 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="lastname" label="Priezvisko" fullWidth variant="outlined" autoComplete="off" value={source.lastname ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'lastname') && setFocus(input)}
                                    inputProps={{ maxLength: 35 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="phone" label="Telefón" fullWidth variant="outlined" autoComplete="off" value={source.phone ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'phone') && setFocus(input)}
                                    inputProps={{ maxLength: 25 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sx={{ marginTop: 3 }}></Grid>
                            
                            <Grid item xs={12}>
                                <TextField margin="dense" name="company" label="Názov firmy (spoločnosť)" fullWidth variant="outlined" autoComplete="off" value={source.company ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'company') && setFocus(input)}
                                    inputProps={{ maxLength: 50 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CorporateFareIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="regId" label="IČO" fullWidth variant="outlined" autoComplete="off" value={source.regId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'regId') && setFocus(input)}
                                    inputProps={{ maxLength: 15 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="taxId" label="DIČ" fullWidth variant="outlined" autoComplete="off" value={source.taxId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'taxId') && setFocus(input)}
                                    inputProps={{ maxLength: 18 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField margin="dense" name="vatId" label="IČDPH" fullWidth variant="outlined" autoComplete="off" value={source.vatId ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }} 
                                    inputRef={input => (props.autoFocus === 'vatId') && setFocus(input)}
                                    inputProps={{ maxLength: 20 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <NumbersIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={ warning.color as AlertColor }>{warning.text}</Alert>)}  

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Použiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DocumentsFiltering;