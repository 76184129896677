import React, { FormEvent, useEffect, useState } from 'react'

// Modely
import { Language, ProductFile, ProductFileTranslation } from '../../models/Models';

// Utility
import { ConvertToInt } from '../../utility/Number';

// Komponenty
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import SortIcon from '@mui/icons-material/Sort';

// Vstupné parametre
export interface ProductCreateFilesEditProps {
    open: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    file: ProductFile;
    languages: Language[];
    onSave: (file: ProductFile) => void;
    onClose: () => void;
}

const ProductCreateFilesEdit = (props: ProductCreateFilesEditProps) => {

    // Stav
    const [source, setSource] = useState<ProductFile>({});

    // Pri otvorení vyplním formulár
    useEffect(() => {
        if (props.open) {
            setSource(props.file);
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        let file = { ...source };

        // Doplním názov z hlavného jazyka pre zoznam
        const languageId = props.languages.find(language => language.system)?.id;
        if (languageId !== undefined && source !== undefined) {
            file = { ...file, other: { ...file.other, translationName: source.translations?.find(item => item.languageId === languageId)?.name ?? '' } };
        }

        props.onSave(file);
        props.onClose();
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeTranslation = (id: number, property: string, value: any) => {
        setSource(prev => {
            const item: ProductFileTranslation = { ...prev?.translations?.find(item => item.languageId === id), languageId: id, [property]: value };
            const next = { ...prev, translations: [...prev?.translations?.filter(item => item.languageId !== id) ?? [], item] };
            return next;
        });
    }

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);   
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if(r !== 'backdropClick') { props.onClose(); }}}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {source.type === 0 ? "Obrázok" : "Súbor"}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            {props.languages.map((language, index) => {
                                return (
                                    <Grid key={language.id} item xs={12}>
                                        <TextField margin="dense" name='name' label={'Názov (' + language.code + ')'} fullWidth variant="outlined" autoComplete="off"
                                            inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && index === 0 && setFocus(input)}
                                            value={source.translations?.find(item => item.languageId === language.id)?.name ?? ''}
                                            onChange={(e) => { handleChangeTranslation((language?.id ?? 0), e.target.name, e.target.value) }}
                                            inputProps={{ maxLength: 255 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <EditIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12} mt={2}>
                                <TextField fullWidth margin="dense" name="ordinalNumber" type="number" label="Poradové číslo" variant="outlined" value={source.ordinalNumber ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToInt(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'ordinalNumber' && setFocus(input)}
                                    inputProps={{ min: 0, max: 32767, step: 1 }} 
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SortIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSource(props.file)} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ProductCreateFilesEdit;