import React, { useState } from 'react';

// Modely
import { Currency, DocumentPayment, Payment } from '../../models/Models';
import { DocumentCreateComponentProps } from './DocumentCreate';
import DocumentCreatePayment, { DocumentCreatePaymentProps } from './DocumentCreatePayment';

// Utility
import { format as dateFormat } from 'date-fns';
import { FormatCurrency } from '../../utility/Format';
import { MathRound2 } from '../../utility/Number';

// Komponenty
import { Alert, AlertTitle, Button, Grid, IconButton, Typography } from '@mui/material';
import Confirm, { ConfirmProps } from '../../components/Confirm';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';

// Vstupné parametre
export interface DocumentCreatePaymentsProps extends DocumentCreateComponentProps {
    currency: Currency;
    payments: Payment[];
    onChangeHistory: (property: string, value: any) => void;
}

const DocumentCreatePayments = (props: DocumentCreatePaymentsProps) => {

    // Uhradené a zostáva uhradiť
    const paymentsTotal = MathRound2((props.source.payments?.reduce((sum, current) => sum + (current?.price ?? 0), 0) ?? 0));
    const paymentsTotalRest = MathRound2((props.source.priceTotalNoVat ?? 0) + (props.source.priceTotalVatAmount ?? 0) - paymentsTotal) + 0; // FIX: "+ 0" je tam preto, že JS má zápornú nulu, takto sa to neguje, vznikne to vtedy a výpočet: -0.000001 sa zaokrúhli na 2 desatiny, výsledkom je: -0.00.

    // Lokálny stav
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položky)
    const [documentCreatePayment, setDocumentCreatePayment] = useState<DocumentCreatePaymentProps>({
        open: false,
        payments: props.payments,
        onSave: (payment, paymentIndex) => {
            props.setSource(prev => {
                let payments: DocumentPayment[] = [];

                // Aktualizujem alebo vytvorím záznam
                if (paymentIndex !== undefined && paymentIndex >= 0) {
                    payments = [...prev.payments ?? []];
                    payments[paymentIndex] = { ...payment };
                }
                else {
                    payments = [...prev.payments ?? [], payment];
                }

                // Označím ako uhradené, ak je celková uhradená suma rovná celkovej sume
                if (prev.paid !== true) {
                    const payed = MathRound2((payments?.reduce((sum, current) => sum + (current?.price ?? 0), 0) ?? 0));
                    const rest = MathRound2((prev.priceTotalNoVat ?? 0) + (prev.priceTotalVatAmount ?? 0) - payed);
                    if (rest <= 0) {
                        prev.paid = true;
                        setTimeout(() => { props.onChangeHistory('paid', true); }, 500);
                    }
                }

                // Vrátim aktualizované záznamy
                return ({ ...prev, payments: payments });
            });
        },
        onClose: () => setDocumentCreatePayment(prev => ({ ...prev, open: false }))
    });

    // Pridať upraviť záznam
    const handleCreate = (index?: number) => {
        let payment: DocumentPayment | undefined = undefined;

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            payment = (props.source?.payments ?? [])[index ?? 0];
        }

        setDocumentCreatePayment(prev => ({
            ...prev,
            payment: payment,
            paymentIndex: index,
            paymentRest: paymentsTotalRest,
            paymentId: props.source.paymentId,
            open: true
        }));
    };

    // Vymazať záznam
    const handleDelete = (index: number, name: string) => {
        setConfirm(prev => ({
            ...prev, open: true, title: name, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                props.setSource(prev => {
                    const payments = prev.payments ?? [];
                    return ({ ...prev, payments: [...payments.filter(payment => payment !== payments[index]) ?? []] });
                });
            }
        }));
    };

    return (
        <>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Nový záznam */}
            <DocumentCreatePayment {...documentCreatePayment} />

            <Grid container columnSpacing={1}>

                <Grid item xs={12} mb={1}>
                    <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mt: 1, mr: 1 }} aria-label="Vložiť" aria-controls="menu-add" aria-haspopup="true" onClick={() => handleCreate()}>Nová úhrada</Button>
                </Grid>

                {/* Zoznam */}
                {
                    props.source.payments?.map((payment, index) => {
                        return (
                            <Grid item xs={12} mt={1} key={index}>
                                <Alert
                                    variant="standard"
                                    severity={(payment.price ?? 0) >= 0 ? 'success' : 'error'}
                                    icon={(payment.price ?? 0) >= 0 ? <AddCardIcon /> : <CreditCardOffIcon />}
                                    onDoubleClick={() => handleCreate(index)}
                                    action={
                                        <>
                                            <IconButton aria-label="Upraviť" color="inherit" size="small" onClick={() => handleCreate(index)}>
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="Vymazať" color="inherit" size="small" onClick={() => handleDelete(index, FormatCurrency(payment.price ?? 0, props.currency, 2) ?? '')}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </>
                                    }>
                                    <AlertTitle>
                                        {dateFormat(new Date(payment.date ?? new Date()), 'dd.MM.yyyy HH:mm')}
                                    </AlertTitle>
                                    {(payment.price ?? 0) >= 0 ? 'Príjem' : 'Výdaj'}: <strong>{FormatCurrency(payment.price ?? 0, props.currency, 2)}</strong>
                                    {(payment.note?.length ?? 0) > 0 ? <><br />Poznámka: {payment.note}</> : null}
                                </Alert>
                            </Grid>
                        )
                    })
                }

                {/* Sumár */}
                {(props.source.payments?.length ?? 0) > 0 && (
                    <Grid item xs={12} mt={3} textAlign="right">
                        <Typography variant="h5" component="div">
                            Uhradené: <strong>{FormatCurrency(paymentsTotal, props.currency, 2)}</strong>
                        </Typography>
                        <Typography variant="body2" component="div">
                            Zostáva uhradiť{paymentsTotalRest < 0 && ' (preplatok)'}: <strong>{FormatCurrency(paymentsTotalRest, props.currency, 2)}</strong>
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default DocumentCreatePayments