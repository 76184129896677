import React, { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';

// Modely
import { DocumentItem, DocumentItemType, Payment, Parameter, Unit, Shipment, ProductLite, DocumentItemConfigurator } from '../../models/Models';
import { DocumentCreateComponentProps } from './DocumentCreate';

// Utility
import { FormatCurrency, FormatDecimal } from '../../utility/Format';
import { FindText } from '../../utility/Search';
import { Export as DataGridExport, Settings as DataGridColumnSettings } from '../../utility/DataGrid';

// Komponenty
import { DataGrid, GridColDef, skSK, GridValueFormatterParams, GridValueGetterParams, GridFilterModel, GridRowId, GridRenderCellParams } from "@mui/x-data-grid";
import { Avatar, Box, Button, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import DocumentCreateItem, { DocumentCreateItemProps } from './DocumentCreateItem';
import DocumentCreateItemDiscount, { DocumentCreateItemDiscountProps } from './DocumentCreateItemDiscount';
import DocumentCreateItemPayment, { DocumentCreateItemPaymentProps } from './DocumentCreateItemPayment';
import DocumentCreateItemShipment, { DocumentCreateItemShipmentProps } from './DocumentCreateItemShipment';
import ProductsDialog, { ProductsDialogProps } from '../product/ProductsDialog';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import Search from '../../components/Search';

// Ikony
import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PercentIcon from '@mui/icons-material/Percent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalculateIcon from '@mui/icons-material/Calculate';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

// Rozšírenie triedy pre potreby gridviewu
interface DocumentItemRow extends DocumentItem {
    uid: number; // Slúži ako unikátny identifikátor pre riadok (id môže byť nulové a index môže byť rovnaký, keďže niektoré položky sa v zobrazení duplikujú, napr. zľavy pre každú sadzbu)
    index: number; // Index v zdrojovom poli, pre editáciu
    autoFocus?: string; // Pre autofocus
}

// Vstupné parametre
export interface DocumentCreateItemsProps extends DocumentCreateComponentProps {
    units: Unit[];
    parameters: Parameter[];
    tagDiscount: number;
    payments: Payment[];
    shipments: Shipment[];
    onSetZeroVatRate: () => void;
    onItemAdd: (item: DocumentItem, itemIndex?: number) => void;
    onItemDelete: (itemIndex: number) => void;
    onItemListDelete: (itemIndexList: number[]) => void;
    onItemListDiscount: (indexList: number[], discountName: string, discountValue: number, discountRelative: boolean) => void;
}

const DocumentCreateItems = (props: DocumentCreateItemsProps) => {

    // Stav
    const [addMenuEl, setAddMenuEl] = useState<null | HTMLElement>(null);
    const [addOptionsEl, setAddOptionsEl] = useState<null | HTMLElement>(null);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });

    // Stav (editácia položiek)
    const [documentCreateItem, setDocumentCreateItem] = useState<DocumentCreateItemProps>({
        open: false,
        units: [],
        parameters: [],
        vatRates: [],
        vatRateDefault: 0,
        languageDefaultId: 0,
        customerDiscount: 0,
        priceIncludeVat: false,
        priceList: {},
        tagDiscount: 0,
        currency: {},
        onSave: (item, itemIndex) => props.onItemAdd(item, itemIndex),
        onClose: () => setDocumentCreateItem(prev => ({ ...prev, itemLoadProducts: undefined, open: false }))
    });
    const [documentCreateItemPayment, setDocumentCreateItemPayment] = useState<DocumentCreateItemPaymentProps>({
        open: false,
        vatRates: [],
        vatRateDefault: 0,
        priceIncludeVat: false,
        priceTotal: 0,
        payments: [],
        onSave: (item, itemIndex) => props.onItemAdd(item, itemIndex),
        onClose: () => setDocumentCreateItemPayment(prev => ({ ...prev, open: false }))
    });
    const [documentCreateItemShipment, setDocumentCreateItemShipment] = useState<DocumentCreateItemShipmentProps>({
        open: false,
        vatRates: [],
        vatRateDefault: 0,
        priceIncludeVat: false,
        priceTotal: 0,
        shipments: [],
        onSave: (item, itemIndex) => props.onItemAdd(item, itemIndex),
        onClose: () => setDocumentCreateItemShipment(prev => ({ ...prev, open: false }))
    });
    const [documentCreateItemDiscount, setDocumentCreateItemDiscount] = useState<DocumentCreateItemDiscountProps>({
        open: false,
        onSave: (item, itemIndex) => props.onItemAdd(item, itemIndex),
        onClose: () => setDocumentCreateItemDiscount(prev => ({ ...prev, open: false }))
    });
    const [documentCreateSelectedItemsDiscount, setDocumentCreateSelectedItemsDiscount] = useState<DocumentCreateItemDiscountProps>({
        open: false,
        alert: 'Zľava na položku v percentách bude jednorázovo prepočítaná na fixnú sumu.',
        onSave: (item, itemIndex) => { },
        onClose: () => setDocumentCreateSelectedItemsDiscount(prev => ({ ...prev, open: false }))
    });

    // Pridať alebo upraviť položku
    const handleItemCreate = (type: DocumentItemType, index?: number, autoFocus?: string) => {
        let item: DocumentItem | undefined = undefined;

        // Úprava položky podľa indexu
        if (index !== undefined && index >= 0) {
            item = (props.source?.items ?? [])[index ?? 0];
        }

        // Štandardná položka (produkt)
        if (type === DocumentItemType.Product) {
            if (autoFocus === 'priceUnit' || autoFocus === 'priceTotal') {
                autoFocus = ((item?.priceIncludeVat ?? true) === true ? 'price' : 'priceCalc');
            }
            if (autoFocus === 'priceUnitNoVat' || autoFocus === 'priceTotalNoVat') {
                autoFocus = ((item?.priceIncludeVat ?? true) === true ? 'priceCalc' : 'price');
            }
            setDocumentCreateItem(prev => ({
                ...prev,
                item: item,
                itemIndex: index,
                itemLoadProducts: undefined,
                autoFocus: autoFocus,
                open: true
            }));
        }

        // Zľava (% / €)
        if (type === DocumentItemType.Discount || type === DocumentItemType.DiscountRelative) {
            setDocumentCreateItemDiscount(prev => ({
                ...prev,
                item: item,
                itemIndex: index,
                autoFocus: 'price',
                open: true
            }));
        }

        // Platba
        if (type === DocumentItemType.Payment) {
            setDocumentCreateItemPayment(prev => ({
                ...prev,
                item: item,
                itemIndex: index,
                autoFocus: 'price',
                open: true
            }));
        }

        // Doprava
        if (type === DocumentItemType.Shipment) {
            setDocumentCreateItemShipment(prev => ({
                ...prev,
                item: item,
                itemIndex: index,
                autoFocus: 'price',
                open: true
            }));
        }
    };

    // Výber položiek rovno cez zoznam produktov (zoznam zostáva po celý čas otvorený a nad ním sa priebežne po výbere zobrazí okno položky)
    const [productsDialog, setProductsDialog] = useState<ProductsDialogProps>({
        open: false,
        keepMounted: false,
        onSelect: (products: ProductLite[]) => {
            if (products.length > 0) {
                // Zobrazím okno položky, ktorému podhodím jeden alebo viacero produktov pre automatické načítanie
                // Ak je podhodený iba jeden produkt, tak sa nad zoznamom zobrazí predvyplnené okno položky
                setDocumentCreateItem(prev => ({
                    ...prev,
                    item: undefined,
                    itemIndex: undefined,
                    itemLoadProducts: products,
                    open: true
                }));
                // Ak je podhodených viacero položiek, tak sa všetky automaticky pridajú a okno sa zavrie, v tom prípade zavriem aj zoznam produktov
                if (products.length > 1) {
                    handleProductsClose();
                }
            }
            else {
                handleProductsClose();
            }
        },
        onClose: () => handleProductsClose()
    });

    // Zobrazenie produktov (pri prvom ponecham komponent pripnutý aby som nemusel znovu naťahovať všetky číselníky)
    const handleProductsOpen = () => {
        setProductsDialog(prev => ({ ...prev, open: true /*, keepMounted: true - citelne to spomaluje form */ }));
    };
    const handleProductsClose = () => {
        setProductsDialog(prev => ({ ...prev, open: false }));
    };

    // Nastavenia stĺpcov
    const columnsSettings = new DataGridColumnSettings({ uid: 'document-create-items-' + (props.source.documentTypeId ?? 0).toString() });
    const columnsDefault: GridColDef[] = [
        { field: 'id', headerName: 'Id', hide: true, minWidth: 20, width: 90, type: 'number', align: 'center', headerAlign: 'center' },
        {
            field: 'file', headerName: 'Náhľad', hide: false, width: 80, sortable: false, filterable: false, align: 'center', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    {params.row.type === DocumentItemType.Product && (params.row.other?.fileIsImage ? <Avatar alt='-' src={params.row.other?.fileSrcSmall} /> : <Avatar><WidgetsIcon /></Avatar>)}
                    {params.row.type === DocumentItemType.Discount || params.row.type === DocumentItemType.DiscountRelative ? <Avatar><PercentIcon /></Avatar> : null}
                    {params.row.type === DocumentItemType.Payment ? <Avatar><CreditCardIcon /></Avatar> : null}
                    {params.row.type === DocumentItemType.Shipment ? <Avatar><LocalShippingIcon /></Avatar> : null}
                </>
            )
        },
        {
            field: 'name', headerName: 'Názov', hide: false, minWidth: 50, flex: 0.8,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Box>
                    <Typography variant="body1"><strong>{params.row.name ?? '...'}</strong></Typography>
                    {(params.row.code?.length ?? 0) > 0 && <>Kód: {params.row.code}<br /></>}
                    {(params.row.codeSupplier?.length ?? 0) > 0 && <>Kód dodávateľa: {params.row.codeSupplier}<br /></>}
                    {(params.row.ean?.length ?? 0) > 0 && <>EAN: {params.row.ean}<br /></>}
                    {(params.row.mpn?.length ?? 0) > 0 && <>MPN: {params.row.mpn}<br /></>}
                    {(params.row.variant?.length ?? 0) > 0 && <>Variant: {params.row.variant}<br /></>}
                    {(params.row.weight ?? 0) > 0 && <>Hmotnosť: {params.row.weight > 1000 ? (params.row.weight / 1000) + ' kg' : params.row.weight + ' g'}<br /></>}
                    {params.row.configurator?.map((conf: DocumentItemConfigurator, confIndex: number) =>
                        <React.Fragment key={confIndex}>{conf.name}: {conf.value} {(conf.price ?? 0) !== 0 && ((conf.price ?? 0) > 0 ? '+' : '') + FormatDecimal(conf.price ?? 0, 2)}<br /></React.Fragment>
                    )}
                    {(params.row.note?.length ?? 0) > 0 && <>Poznámka: {params.row.note}</>}
                </Box>
            )
        },
        { field: 'ean', headerName: 'EAN', hide: true, minWidth: 30, flex: 0.15 },
        { field: 'code', headerName: 'Kód', hide: true, minWidth: 30, flex: 0.15 },
        { field: 'codeSupplier', headerName: 'Kód dodávateľa', hide: true, minWidth: 30, flex: 0.15 },
        { field: 'note', headerName: 'Poznámka', hide: true, minWidth: 30, flex: 0.15 },
        { field: 'variant', headerName: 'Variant', hide: true, minWidth: 30, flex: 0.15 },
        { field: 'unit', headerName: 'Merná jednotka', hide: true, minWidth: 30, flex: 0.15 },
        {
            field: 'quantity', headerName: 'Množstvo', hide: false, minWidth: 30, flex: 0.15, align: 'center', headerAlign: 'center', type: 'number',
            renderCell: (params: GridRenderCellParams<number>) => (<>{(params.row.quantity ?? 0) + ' ' + (params.row.unit ?? '')}</>)
        },
        { field: 'weight', headerName: 'Hmotnosť (g)', hide: true, minWidth: 60, flex: 0.15, align: 'center', headerAlign: 'center', type: 'number' },
        {
            field: 'pricePurchase', headerName: 'Nákupná cena', hide: true, minWidth: 30, flex: 0.15, align: 'right', headerAlign: 'center', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'priceUnitNoVat', headerName: 'Cena bez DPH', hide: true, minWidth: 30, flex: 0.15, align: 'right', headerAlign: 'center', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'priceUnit', headerName: 'Cena', hide: false, minWidth: 30, flex: 0.15, align: 'right', headerAlign: 'center', type: 'number',
            valueGetter: (params: GridValueGetterParams) => (params.row.priceUnitNoVat ?? 0) + (params.row.priceUnitVatAmount ?? 0),
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'priceVatRate', headerName: 'DPH', hide: false, minWidth: 30, flex: 0.15, align: 'right', headerAlign: 'center', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0) + '%'
        },
        {
            field: 'priceTotalNoVat', headerName: 'Celkom bez DPH', hide: true, minWidth: 30, flex: 0.15, align: 'right', headerAlign: 'center', type: 'number',
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'priceTotal', headerName: 'Celkom', hide: false, minWidth: 30, flex: 0.15, align: 'right', headerAlign: 'center', type: 'number',
            valueGetter: (params: GridValueGetterParams) => (params.row.priceTotalNoVat ?? 0) + (params.row.priceTotalVatAmount ?? 0),
            valueFormatter: (params: GridValueFormatterParams) => FormatDecimal((params?.value as number) ?? 0, 2)
        },
        {
            field: 'createdDate', headerName: 'Vytvorené', hide: true, minWidth: 50, flex: 0.2, type: 'date',
            valueGetter: (params: GridValueGetterParams) => new Date(params.row['createdDate']),
            valueFormatter: (params: GridValueFormatterParams) => (params?.value as Date).toLocaleDateString() ?? '-'
        },
        {
            field: 'updatedDate', headerName: 'Upravené', hide: true, minWidth: 50, flex: 0.2, type: 'date',
            valueGetter: (params: GridValueGetterParams) => (new Date(params.row['updatedDate'])),
            valueFormatter: (params: GridValueFormatterParams) => ((params?.value as Date).getFullYear() > 1 ? (params?.value as Date).toLocaleDateString() : '-')
        },
        {
            field: 'options', headerName: 'Možnosti', hide: false, width: 80, sortable: false, filterable: false, align: 'right', headerAlign: 'center',
            valueGetter: (params: GridValueGetterParams) => params.row.id,
            renderCell: (params: GridRenderCellParams<number>) => (
                <>
                    <IconButton aria-label="Upraviť" title="Upraviť (enter)" size="small" onClick={() => setRowToEdit({ ...(params.row as DocumentItemRow), autoFocus: 'quantity' })}>
                        <EditIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Vymazať" title="Vymazať (delete)" size="small" onClick={() => setRowToDelete(params.row as DocumentItemRow)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ];

    // Tabuľka
    const [search, setSearch] = useState('');
    const [rowsPageSize, setRowsPageSize] = useState<number>(10);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([])
    const [rowsSelectedMenuEl, setRowsSelectedMenuEl] = useState<null | HTMLElement>(null);
    const [rowsFiltered, setRowsFiltered] = useState<DocumentItemRow[]>([]);
    const [rowToEdit, setRowToEdit] = useState<DocumentItemRow | undefined>(undefined);
    const [rowToDelete, setRowToDelete] = useState<DocumentItemRow | undefined>(undefined);
    const [rowToDiscountList, setRowToDiscountList] = useState<number[] | undefined>(undefined);
    const [rowToDeleteList, setRowToDeleteList] = useState<number[] | undefined>(undefined);
    const [columns, setColumns] = useState<GridColDef[]>(columnsDefault);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    // Po zavretí okna automaticky zresetujem text vyhľadávania
    useEffect(() => {
        if (props.open === false && search.length > 0) {
            setSearch('');
        }
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pre vyvolanie editácie, alebo vymazania musím použiť háčik
    useEffect(() => {
        if (rowToEdit === undefined) {
            return;
        }
        handleItemCreate(rowToEdit.type ?? DocumentItemType.Product, rowToEdit.index ?? 0, rowToEdit.autoFocus);
        setRowToEdit(undefined);
    }, [rowToEdit]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pre vyvolanie editácie, alebo vymazania musím použiť háčik
    useEffect(() => {
        if (rowToDelete === undefined) {
            return;
        }
        let index = rowToDelete.index;
        let itemName = (props.source.items ?? [])[index]?.name ?? '';
        setRowToDelete(undefined);
        setConfirm(prev => ({
            ...prev, open: true, title: itemName, children: 'Skutočne chcete vymazať tento záznam?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setRowsSelected([]);
                props.onItemDelete(index ?? 0);
            }
        }));
    }, [rowToDelete]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pre vyvolanie editácie, alebo vymazania musím použiť háčik
    useEffect(() => {
        if (rowToDeleteList === undefined) {
            return;
        }
        let indexes = rowToDeleteList;
        setRowToDeleteList(undefined);
        setConfirm(prev => ({
            ...prev, open: true, title: 'Vymazať záznamy: ' + indexes.length, children: 'Skutočne chcete vymazať vybrané záznamy?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                setRowsSelected([]);
                props.onItemListDelete(indexes);
            }
        }));
    }, [rowToDeleteList]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pre vyvolanie editácie musím použiť háčik
    useEffect(() => {
        if (rowToDiscountList === undefined) {
            return;
        }
        let indexes = rowToDiscountList;
        setRowToDiscountList(undefined);

        // Pre získanie výšky zľavy použijem rovnaký komponent ako pre zľavu cez novú položku
        setDocumentCreateSelectedItemsDiscount(prev => ({
            ...prev,
            item: undefined, // { name: 'Zľava na položku' },
            itemIndex: 0,
            autoFocus: 'price',
            open: true,
            onSave: (item, index) => {
                props.onItemListDiscount(indexes, item.name ?? 'Zľava', item.price ?? 0, item.type === DocumentItemType.DiscountRelative);
            }
        }));
    }, [rowToDiscountList]); // eslint-disable-line react-hooks/exhaustive-deps

    // Aplikujem uložené nastavenia
    useEffect(() => {
        if ((props.source.documentTypeId ?? 0) > 0) {
            setColumns(columnsSettings.columnApply(columns));
        }
    }, [props.source.documentTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vyhľadávanie (automaticky odfiltrujem záznamy po zmene hľadaného textu, alebo obnovení riadkov)
    useEffect(() => {
        // Zoznam položiek
        let items: DocumentItemRow[] = [];

        // Unikátny identifikátor pre potreby datagridu
        let uid = 0;

        // Postupne prejdem položky a pridám ich podľa typu (zľavy a poplatky dám na koniec zoznamu)
        props.source.items?.forEach((item, index) => {
            if (item.type === DocumentItemType.Discount || item.type === DocumentItemType.DiscountRelative) {
                // Pri položke zľava sa v zozname zobrazujú iba podpoložky s vypočítanou hodnotou
                item.items?.forEach((child, childIndex) => {
                    items.push({ ...child, uid: uid, index: index, ordinalNumber: 99999 });
                    uid++;
                });
            } else {
                // Ostatné položky sú zobrazené štandardne
                items.push({ ...item, uid: uid, index: index, ordinalNumber: (item.type === DocumentItemType.Product ? item.ordinalNumber : 99998) });
                uid++;
            }
        });

        // Zoradím
        items = items.sort((a, b) => (a.ordinalNumber ?? 0) > (b.ordinalNumber ?? 0) ? 1 : -1);

        setRowsFiltered((search.length > 0 ? FindText(items, search) : items))
    }, [props.source.items, search]);

    const handleExport = (type: 'xml' | 'csv') => {
        if (rowsSelected.length === 0) {
            return;
        }
        const indexes = rowsSelected.map(r => r as number);
        const rows = rowsFiltered.filter(row => indexes.includes(row.index));
        DataGridExport({
            type: type,
            columns: columnsSettings.columnApply(columns),
            columnsSkip: ['options', 'file'],
            rows: rows,
            specific: [
                {
                    field: 'priceUnit', getValue: (row: any) => { return (row.priceUnitNoVat ?? 0) + (row.priceUnitVatAmount ?? 0) }
                },
                {
                    field: 'priceTotal', getValue: (row: any) => { return (row.priceTotalNoVat ?? 0) + (row.priceTotalVatAmount ?? 0) }
                }]
        });
    };

    // Celková výška zľavy
    const [priceTotalDiscountWithVat, setPriceTotalDiscountWithVat] = useState<number>(0);

    // Pri zmene v položkách získam celkovú sumu zľavových položiek (každá položka obsahuje hodnotu v podpoložke pre každú sadzbu DPH)
    useEffect(() => {
        let totalDiscountWithVat = 0;
        props.source.items?.filter(item => item.type === DocumentItemType.Discount || item.type === DocumentItemType.DiscountRelative)?.forEach(item => {
            totalDiscountWithVat += item.items?.reduce((sum, current) => sum + (current.priceTotalNoVat ?? 0) + (current.priceTotalVatAmount ?? 0), 0) ?? 0;
        });
        setPriceTotalDiscountWithVat(totalDiscountWithVat);
    }, [props.source.items]);

    return (
        <>
            <Grid container columnSpacing={1}>

                <Menu id="menu-add" anchorEl={addMenuEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '35px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(addMenuEl)} onClose={() => setAddMenuEl(null)} >
                    <MenuItem onClick={() => { handleItemCreate(DocumentItemType.Product); setAddMenuEl(null); }}><ListItemIcon><WidgetsIcon fontSize="small" /></ListItemIcon> Produkt</MenuItem>
                    <MenuItem onClick={() => { handleProductsOpen(); setAddMenuEl(null); }}><ListItemIcon><WidgetsIcon fontSize="small" /></ListItemIcon> Produkt cez zoznam</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleItemCreate(DocumentItemType.Payment); setAddMenuEl(null); }}><ListItemIcon><CreditCardIcon fontSize="small" /></ListItemIcon> Spôsob platby</MenuItem>
                    <MenuItem onClick={() => { handleItemCreate(DocumentItemType.Shipment); setAddMenuEl(null); }}><ListItemIcon><LocalShippingIcon fontSize="small" /></ListItemIcon> Spôsob dopravy</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleItemCreate(DocumentItemType.Discount); setAddMenuEl(null); }}><ListItemIcon><PercentIcon fontSize="small" /></ListItemIcon> Zľava</MenuItem>
                </Menu>

                {/* Označené záznamy (možnosti) */}
                <Menu id="menu-selected" anchorEl={rowsSelectedMenuEl} open={Boolean(rowsSelectedMenuEl)} onClose={() => setRowsSelectedMenuEl(null)} >
                    <MenuItem onClick={() => { setRowToDiscountList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                        <ListItemIcon><PercentIcon fontSize="small" /></ListItemIcon> Zľava na položku
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleExport('csv'); }}>
                        <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako CSV (Excel)
                    </MenuItem>
                    <MenuItem onClick={() => { handleExport('xml'); }}>
                        <ListItemIcon><DownloadIcon fontSize="small" /></ListItemIcon> Stiahnuť ako XML
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { setRowToDeleteList(rowsSelected.map(r => r as number)); setRowsSelectedMenuEl(null); }}>
                        <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon> Vymazať záznamy
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { setRowsSelected([]); setRowsSelectedMenuEl(null); }}>
                        <ListItemIcon><ClearIcon fontSize="small" /></ListItemIcon> Zrušiť výber</MenuItem>
                </Menu>

                {/* Možnosti */}
                <Menu id="menu-options" anchorEl={addOptionsEl} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} sx={{ mt: '35px' }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} open={Boolean(addOptionsEl)} onClose={() => setAddOptionsEl(null)} >
                    <MenuItem onClick={() => { props.onSetZeroVatRate(); setAddOptionsEl(null); }}><ListItemIcon><CalculateIcon fontSize="small" /></ListItemIcon> Nastaviť nulovú sadzbu DPH</MenuItem>
                </Menu>

                <Grid item xs={12} md>
                    <Button variant="contained" size="small" startIcon={<AddIcon />} color="secondary" sx={{ mt: 1 }} aria-label="Vložiť" aria-controls="menu-add" aria-haspopup="true" onClick={(e) => setAddMenuEl(e.currentTarget)}>Nová položka</Button>
                    <Button variant="text" size="small" endIcon={<ExpandMoreIcon />} color="secondary" sx={{ mt: 1, ml: 1 }} aria-label="Možnosti" aria-controls="menu-options" aria-haspopup="true" onClick={(e) => setAddOptionsEl(e.currentTarget)}>Možnosti</Button>
                    <Button variant="text" size="small" disabled={rowsSelected?.length === 0} sx={{ mt: 1, ml: 1 }} aria-label="Vybrané záznamy" aria-controls="menu-selected" aria-haspopup="true" onClick={(e) => setRowsSelectedMenuEl(e.currentTarget)} endIcon={<ExpandMoreIcon />}>Vybrané {'(' + rowsSelected.length.toString() + ')'}</Button>
                </Grid>

                {/* Vyhľadávanie */}
                <Grid item xs={12} md={6} lg={4} sx={{ mt: { xs: 1, md: 0 } }}>
                    <Search
                        text={search}
                        onSearch={s => setSearch(s)}
                        onClear={() => {
                            setSearch('');
                            setFilterModel({ items: [], linkOperator: undefined });
                        }}
                        autoFocus={true} />
                </Grid>

                {/* Zoznam */}
                <Grid item xs={12} mt={1}>
                    <div style={{ display: 'flex', height: '100%', minHeight: '60vh' }}>
                        <DataGrid
                            autoHeight
                            disableVirtualization
                            getRowId={row => row.uid}
                            checkboxSelection
                            disableSelectionOnClick
                            columns={columns}
                            rows={rowsFiltered}
                            pageSize={rowsPageSize}
                            rowsPerPageOptions={[10, 15, 20, 25]}
                            onPageSizeChange={(pageSize) => setRowsPageSize(pageSize)}
                            localeText={skSK.components.MuiDataGrid.defaultProps.localeText}
                            getRowHeight={() => 'auto'}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: 1, },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: 1, },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: 1, },
                            }}

                            // Dvoj-klik (úprava)
                            onCellDoubleClick={(e) => {
                                setRowToEdit({ ...(e.row as DocumentItemRow), autoFocus: e.field });
                            }}

                            // Klávesnica (shift+enter => upraviť, shift+delete => vymazať, shift+space => označiť, vstavaná funkcia)
                            onCellKeyDown={(e, c) => {
                                if ((c.code === 'Enter' || c.code === 'NumpadEnter') && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    setRowToEdit({ ...(e.row as DocumentItemRow), autoFocus: e.field });
                                    return;
                                }
                                if (c.code === 'Delete' && (!AppConfig.DataGrid.UseShiftKey || c.shiftKey)) {
                                    c.preventDefault();
                                    c.stopPropagation();
                                    setRowToDelete(e.row as DocumentItemRow);
                                    return;
                                }
                            }}

                            // Filtrácia
                            filterModel={filterModel}
                            onFilterModelChange={e => setFilterModel(e)}

                            // Vybrané záznamy
                            selectionModel={rowsSelected}
                            onSelectionModelChange={e => setRowsSelected(e)}

                            // Stĺpce (automatické ukladanie nastavení)
                            onColumnVisibilityChange={e => columnsSettings.columnVisibilityChanged(e, columnsDefault)}
                        />
                    </div>
                </Grid>

                {(props.source.items?.length ?? 0) > 0 && (
                    <Grid item xs={12} mt={3} textAlign="right">
                        <Typography variant="h5" component="div">
                            Celková suma: <strong>{FormatCurrency((props.source.priceTotalNoVat ?? 0) + (props.source.priceTotalVatAmount ?? 0), props.currency, 2)}</strong>
                        </Typography>
                        {(priceTotalDiscountWithVat ?? 0) !== 0 && (
                            <Typography variant="body2" component="div">
                                Pôvodne: <strong>{FormatCurrency((props.source.priceTotalNoVat ?? 0) + (props.source.priceTotalVatAmount ?? 0) - (priceTotalDiscountWithVat ?? 0), props.currency, 2)}</strong>,
                                Zľava: <strong>{FormatCurrency((priceTotalDiscountWithVat ?? 0), props.currency, 2)}</strong>
                            </Typography>
                        )}
                        <Typography variant="body2" component="div">
                            Celkom bez DPH: <strong>{FormatCurrency((props.source.priceTotalNoVat ?? 0), props.currency, 2)}</strong>
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

            {/* Výber produktov (ponechať vnorené v nadradenom dialogu aby sa spoločne odpojil a zachoval zIndex) */}
            <ProductsDialog {...productsDialog} />

            {/* Formulár položky (produkt) */}
            <DocumentCreateItem {...documentCreateItem} units={props.units} parameters={props.parameters} vatRates={props.vatRates} vatRateDefault={props.vatRateDefault} priceIncludeVat={props.priceIncludeVat} languageDefaultId={props.languageDefaultId} customerDiscount={props.customerDiscount} priceList={props.priceList} tagDiscount={props.tagDiscount} currency={props.currency} />

            {/* Formulár položky (zľava) */}
            <DocumentCreateItemDiscount {...documentCreateItemDiscount} />

            {/* Formulár položky (spôsob platby) */}
            <DocumentCreateItemPayment {...documentCreateItemPayment} vatRates={props.vatRates} vatRateDefault={props.vatRateDefault} priceIncludeVat={props.priceIncludeVat} payments={props.payments} priceTotal={(props.source.priceTotalNoVat ?? 0) + (props.source.priceTotalVatAmount ?? 0)} />

            {/* Formulár položky (spôsob dopravy) */}
            <DocumentCreateItemShipment {...documentCreateItemShipment} vatRates={props.vatRates} vatRateDefault={props.vatRateDefault} priceIncludeVat={props.priceIncludeVat} shipments={props.shipments} priceTotal={(props.source.priceTotalNoVat ?? 0) + (props.source.priceTotalVatAmount ?? 0)} />

            {/* Formulár zľavy na označené položky */}
            <DocumentCreateItemDiscount {...documentCreateSelectedItemsDiscount} />
        </>
    )
}

export default DocumentCreateItems;