import React, { FormEvent, useEffect, useState } from 'react'

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';

// Modely
import { DocumentItem, DocumentItemType } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import EditIcon from '@mui/icons-material/Edit';
import EuroIcon from '@mui/icons-material/Euro';
import PercentIcon from '@mui/icons-material/Percent';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): DocumentItem => ({
    id: 0,
    parentId: 0,
    productId: 0,
    type: DocumentItemType.Discount,
    ordinalNumber: 0,
    ean: '',
    code: '',
    codeSupplier: '',
    name: '',
    variant: '',
    note: '',
    weight: 0,
    quantity: 1,
    unit: 'ks',
    // pricePurchase: 0,
    // pricePurchaseVatRate: 0,
    // price: 0,
    // priceCalc: 0,
    priceIncludeVat: true,
    // priceVatRate: 0,
    // priceUnitNoVat: 0,
    // priceUnitVatAmount: 0,
    // priceTotalNoVat: 0,
    // priceTotalVatAmount: 0,
    configurator: []
});

// Vstupné parametre
export interface DocumentCreateItemDiscountProps {
    item?: DocumentItem;
    itemIndex?: number;
    open: boolean;
    alert?: string;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave: (item: DocumentItem, itemIndex?: number) => void;
    onClose: () => void;
}

const DocumentCreateItemDiscount = (props: DocumentCreateItemDiscountProps) => {

    // Stav
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<DocumentItem>(EmptySource());

    // Automaticky vyplním okno
    useEffect(() => {
        if (!props.open) {
            return;
        }
        // Použijem props.item alebo emptySource
        setSource((props.item ?? { ...EmptySource() }));
    }, [props.item, props.open]);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Reset formuláru (resetujem iba tie hodnoty, ktoré viem upraviť cez tento formulár)
    const handleReset = () => {
        setSource(prev => ({
            ...prev,
            ...EmptySource(),
            id: prev.id,
            ordinalNumber: prev.ordinalNumber
        }))
    };

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.onSave(source, props.itemIndex);
        props.onClose();
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
            // Automaticky označím text vo vybraných poliach
            if ((props.autoFocus === 'quantity' || props.autoFocus === 'price') &&
                (input?.select !== undefined && typeof input.select === 'function')) {
                setTimeout(() => { input?.select(); }, 250);
            }
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {props.itemIndex === undefined ? 'Nová zľava' : 'Zľava'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth required margin="dense" name="price" type="text" label={'Výška zľavy'} variant="outlined" autoComplete="off" value={source.price ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'price' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {source.type === DocumentItemType.Discount ? <EuroIcon /> : <PercentIcon />}
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="item-discount-create-type">Typ zľavy</InputLabel>
                                    <Select required labelId="item-discount-create-type" label="Typ zľavy" name="type"
                                        value={((source.type ?? DocumentItemType.Discount) === DocumentItemType.Discount ? 0 : 1)}
                                        onChange={(e) => { handleChange(e.target.name, parseInt(e.target.value.toString()) === 0 ? DocumentItemType.Discount : DocumentItemType.DiscountRelative) }}
                                        inputRef={input => props.autoFocus === 'type' && setFocus(input)}>
                                        <MenuItem value={0}>Fixná suma</MenuItem>
                                        <MenuItem value={1}>Percentá</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            {((props.alert ?? '').length > 0 && <Grid item xs={12}><Alert sx={{ mt: 1 }} severity="warning">{(props.alert ?? '')}</Alert></Grid>)}

                            {(warning.text.length > 0 && <Grid item xs={12}><Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert></Grid>)}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleReset} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button onClick={props.onClose}>Späť</Button>
                        <Button type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default DocumentCreateItemDiscount;