import { useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import axios from 'axios';

// Utility
import { ConvertToInt } from '../../utility/Number';
import { CookieRead } from '../../utility/Cookies';
import { FileOpen } from '../../utility/File';

// Modely
import { Language, TemplateReport } from '../../models/Models';

// Komponenty
import { Dialog, Box, DialogTitle, DialogContent, DialogActions, Button, Grid, FormControl, InputLabel, MenuItem, Select, Alert, Paper } from '@mui/material';
import ButtonList, { Item } from '../../components/ButtonList';
import Iframe from '../../components/Iframe';

// Ikony
import PrintIcon from '@mui/icons-material/Print';
import DescriptionIcon from '@mui/icons-material/Description';

// Predpis pre kľúč tlačidla
interface ItemKey {
    id: number;
    format: ItemKeyFormat;
    print: boolean;
}
enum ItemKeyFormat {
    pdf,
    html
}

export interface ReportDocumentProps {
    documentId: number;         // id dokladu
    documentTypeId: number;     // druh dokladu
    open: boolean;
    preview?: boolean;          // true = doklad sa zobrazí cez iframe s automatickou tlačou, false = doklad sa otvori v novom okne
    disableHtml?: boolean;      // skryje tlačidlo pre tlač do HTML
    disablePdf?: boolean;       // skryje tlačidlo pre tlač do PDF
    keepMounted?: boolean;
    onClose: () => void;
}

const ReportDocument = (props: ReportDocumentProps) => {

    // Stav
    const [language, setLangauge] = useState<number>(0);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [templates, setTemplates] = useState<TemplateReport[]>([]);
    const [templatesTypeId, setTemplatesTypeId] = useState<number>(0);
    const [printItems, setPrintItems] = useState<Item[]>([]);
    const [printItemsActiveKey, setPrintItemsActiveKey] = useState<number | undefined>();

    // Načítam zoznam šablón
    const loadTemplates = () => {
        axios
            .get(AppConfig.ApiUri + 'templatereport', {
                params: {
                    'documentTypeId': props.documentTypeId,
                    'createDefault': true
                }
            })
            .then(response => {
                setTemplates(response.data);
            });
    };

    // Načítam zoznam jazykov
    const loadLanguages = () => {
        axios
            .get(AppConfig.ApiUri + 'language')
            .then(response => {
                setLanguages(response.data.filter((language: Language) => language.actived));
            });
    };

    // Automaticky vyberiem systémový jazyk
    useEffect(() => {
        if (language === 0) {
            setLangauge(languages.find(item => item.system)?.id ?? languages[0]?.id ?? 0);
        }
    }, [languages]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automaticky vyplním číselníky po otvorení okna, alebo zmene druhu dokladu
    useEffect(() => {
        // Pri zavrení okna, nastavím "undefined" a pri otvorení "0", aby sa automaticky zbalil zoznam tlačidiel
        setPrintItemsActiveKey(props.open ? 0 : undefined);

        // Ak nie je okon zobrazené, tak ďalej nepokračujem
        if (!props.open) {
            return;
        }

        // Načítam šablóny 
        if (props.documentTypeId > 0 && props.documentTypeId !== templatesTypeId) {
            setTemplatesTypeId(props.documentTypeId);
            loadTemplates();
        }

        // Načítam jazyky
        if (languages.length === 0) {
            loadLanguages();
        }
    }, [props.open, props.documentTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Vygenerujem zoznam pre výber šablón
    useEffect(() => {
        const items: Item[] = [];
        // Pridám tlačidlá, len ak existujú pre daný typ dokladu nejaké šablóny
        if (templates.length > 0) {
            // Náhľad PDF
            const itemPdfPreview: Item = {
                key: { id: 0, format: ItemKeyFormat.pdf, print: false } as ItemKey,
                title: 'Náhľad dokladu',
                icon: <PrintIcon />,
                divider: true,
                items: []
            };
            // Tlač PDF
            const itemPdf: Item = {
                key: { id: 0, format: ItemKeyFormat.pdf, print: true } as ItemKey,
                title: 'Vytlačiť doklad',
                icon: <PrintIcon />,
                items: []
            };
            // Tlač HTML
            const itemHtml: Item = {
                key: { id: 0, format: ItemKeyFormat.html, print: true } as ItemKey,
                title: 'Vytlačiť doklad (HTML)',
                icon: <PrintIcon />,
                items: []
            };
            // Prejdem šablóny a vložím pod tlačidlá
            templates.forEach(item => {
                itemPdfPreview.items?.push({
                    key: { id: item.id ?? 0, format: ItemKeyFormat.pdf, print: false } as ItemKey,
                    title: (item.other?.translationName ?? 'Bez názvu'),
                    icon: <DescriptionIcon />,
                });
                itemPdf.items?.push({
                    key: { id: item.id ?? 0, format: ItemKeyFormat.pdf, print: true } as ItemKey,
                    title: (item.other?.translationName ?? 'Bez názvu'),
                    icon: <DescriptionIcon />,
                });
                itemHtml.items?.push({
                    key: { id: item.id ?? 0, format: ItemKeyFormat.html, print: true } as ItemKey,
                    title: (item.other?.translationName ?? 'Bez názvu'),
                    icon: <DescriptionIcon />,
                });
            });
            items.push(itemPdfPreview);
            if ((props.disablePdf ?? false) === false) {
                items.push(itemPdf);
            }
            if ((props.disableHtml ?? false) === false) {
                items.push(itemHtml);
            }
        }

        // Použijem zoznam tlačidiel
        setPrintItems(items);
    }, [templates]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre tlač
    const handlePrint = (key: ItemKey) => {

        // Vstupné údaje (použijem vybranú šablónu, predvolenú alebo prvú v poradí pre daný typ)
        const documentLanguageId = language ?? 0;
        const documentFormat = (key.format === ItemKeyFormat.html ? 'html' : 'pdf');
        const documentTemplateId = (key.id > 0 ? key.id :
            (templates.find(template => (template.documentTypeId === props.documentTypeId || (props.documentTypeId === 0 && template.documentTypeId === 1)) && template.default)?.id) ??
            (templates.filter(template => (template.documentTypeId === props.documentTypeId || (props.documentTypeId === 0 && template.documentTypeId === 1)))[0]?.id)
            ?? 0);

        // Zložím URL pre tlač
        const printUrl = AppConfig.ApiUri + 'report/document'
            + '?documentId=' + props.documentId
            + '&languageId=' + documentLanguageId
            + '&templateId=' + documentTemplateId
            + '&format=' + documentFormat
            + '&session=' + CookieRead(AppConfig.CookieName.LoggedSession);

        // Zobrazím cez preview dialog
        if (props.preview === true) {
            setPreviewSrc(printUrl);
            setPreviewPrint(key.print);
            setPreviewOpen(true);
        } else {
            FileOpen(printUrl, '_blank');
        }
    };

    // Náhľad dokladu
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewPrint, setPreviewPrint] = useState<boolean>(false);
    const [previewSrc, setPreviewSrc] = useState<string>('');

    return (
        <>
            <Dialog keepMounted={false} open={previewOpen} maxWidth="lg" fullWidth scroll="paper" onClose={(e, r) => setPreviewOpen(false)}>
                <DialogContent dividers={true} sx={{ p: 1, height: '90vh' }}>
                    <Iframe title={'Náhľad dokladu'} src={previewSrc} print={previewPrint} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPreviewOpen(false)}>Zavrieť</Button>
                </DialogActions>
            </Dialog>

            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => props.onClose()}>
                <Box>
                    <DialogTitle>Vytlačiť doklad</DialogTitle>
                    <DialogContent>
                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="print-language">Jazyk</InputLabel>
                                    <Select required labelId="print-language" label="Jazyk" value={language ?? 0} name="languageId" onChange={(e) => { setLangauge(ConvertToInt(e.target.value) ?? 0) }}>
                                        {languages?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {printItems.length === 0 && (
                                <Grid item xs={12} mt={1}>
                                    <Alert severity="warning">Chýbajú šablóny pre tlač</Alert>
                                </Grid>
                            )}

                            {printItems.length > 0 && (
                                <Grid item xs={12} mt={1}>
                                    <Paper>
                                        <ButtonList activedKey={printItemsActiveKey} items={printItems ?? []} onClick={(e) => { handlePrint(e.key as ItemKey); props.onClose(); }} />
                                    </Paper>
                                </Grid>
                            )}

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onClose}>Späť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default ReportDocument;