// -------------------------------------------------------------------------
// Obsahuje triedy a funkcie pre vnútorné prepočty (náhrádza computed fields)
// -------------------------------------------------------------------------

import { MathRound2, MathRound4 } from "../utility/Number";

export const BasicColors: string[] = [
	'121212',
	'2e7d32',
	'b71c1c',
	'1565c0',
	'e65100',
	'9500ae',
	'df487f',
	'827717',
	'e6d200'
];

export interface Authorization {
	email: string;
	password: string;
	remember: boolean;
}

export interface Agreement {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	type?: number;
	validity?: number;
	translations?: AgreementTranslation[];
	other?: AgreementOtherInfo;
}

export interface AgreementOtherInfo {
	translationLabel?: string;
}

export interface AgreementSource {
	id?: number;
	name?: string;
}

export interface AgreementTranslation {
	agreementId?: number;
	languageId?: number;
	label?: string;
	text?: string;
}

export interface Article {
	id?: number;
	widget?: string;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	contentCategoryId?: number;
	fileId?: number;
	name?: string;
	content?: string;
	note?: string;
	other?: ArticleOtherInfo;
}

export interface ArticleOtherInfo extends FileBase {
	contentCategoryName?: string;
}

export interface Automation {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	name?: string;
	trigger?: number;
	domains?: number[];
	rules?: AutomationRule[];
	actions?: AutomationAction[];
}

export interface AutomationDomain {
	automationId?: number;
	domainId?: number;
}

export interface AutomationAction {
	automationId?: number;
	type?: number;
	settings?: AutomationActionSetting[];
}

export interface AutomationActionSetting {
	uid?: string;
	value?: string;
}

export interface AutomationRule {
	automationId?: number;
	condition?: number;
	name?: string;
	value?: string;
}

export interface AutomationTriggerType {
	id?: number;
	name?: string;
	settings?: AutomationTriggerTypeSetting[];
}

export interface AutomationTriggerTypeSetting {
	uid?: string;
	name?: string;
}

export interface AutomationActionType {
	id?: number;
	name?: string;
	settings?: AutomationActionTypeSetting[];
}

export interface AutomationActionTypeSetting {
	uid?: string;
	name?: string;
}

export interface BillingInformationSettings {
	customers: BillingInformationSettingsCustomer[]; // Fakturačné údaje podľa domény (0 = predvolené údaje)
}
export interface BillingInformationSettingsCustomer {
	domainId?: number;
	customerId?: number;
	customerName?: string;
}

/*
export interface Blacklist {
	createdDate?: Date;
	type?: number;
	ip?: string;
	browser?: string;
}

export interface Cart {
	id?: number;
	domainId?: number;
	documentId?: number;
	customerId?: number;
	createdDate?: Date;
	updatedDate?: Date;
	endedDate?: any;
	deletedDate?: Date;
	deleted?: boolean;
	session?: string;
	note?: string;
	currencyCode?: string;
	currencyExchange?: number;
}

export interface CartItem {
	id?: number;
	parentId?: number;
	cartId?: number;
	productId?: number;
	createdDate?: Date;
	updatedDate?: Date;
	deletedDate?: Date;
	deleted?: boolean;
	ean?: string;
	code?: string;
	codeSupplier?: string;
	name?: string;
	variant?: string;
	note?: string;
	weight?: number;
	quantity?: number;
	unit?: string;
	priceNormal?: number;
	price?: number;
	priceVatRate?: number;
	priceIncludeVat?: boolean;
}

export interface CartItemConfigurator {
	id?: number;
	cartItemId?: number;
	uid?: string;
	name?: string;
	value?: string;
	price?: number;
}
*/

export interface Chart {
	datatype?: string;
	dataname?: string;
	labels?: string[];
	datasets?: ChartDataset[];
}

export interface ChartDataset {
	label?: string;
	data?: number[];
	backgroundColor?: string[];
	borderColor?: string[];
}

export interface Category extends CustomFieldBase {
	id?: number;
	parentId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	ordinalNumber?: number;
	fileId?: number;
	sorting?: number;
	domains?: number[];
	translations?: CategoryTranslation[];
	externalCatalogs?: CategoryExternalCatalog[],
	other?: CategoryOtherInfo;
}

export interface CategoryOtherInfo extends FileBase {
	translationName?: string;
}

export interface CategoryTranslation {
	categoryId?: number;
	languageId?: number;
	url?: string;
	name?: string;
	description?: string;
	seoHeadline?: string;
	seoTitle?: string;
	seoDescription?: string;
}

export const ProductSortingTypes: ProductSortingType[] = [
	{ id: 0, name: 'Predvolené' },

	{ id: 1, name: 'Od najlacnejšieho' },
	{ id: 2, name: 'Od najdrahšieho' },

	{ id: 3, name: 'Od najnovšieho' },
	{ id: 4, name: 'Od najstaršieho' },

	{ id: 5, name: 'Abecedne A-Z' },
	{ id: 6, name: 'Abecedne Z-A' },

	{ id: 7, name: 'Od najvyššej priority' },
	{ id: 8, name: 'Od najnižšej priority' },
];

export interface ProductSortingType {
	id?: number;
	name?: string;
}

export interface CategoryExternalCatalog {
	categoryId?: number;
	externalCatalogId?: number;
	category?: string;
	cpc?: number;
}

export interface ContentCategory {
	id?: number;
	parentId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	ordinalNumber?: number;
	fileId?: number;
	name?: string;
	other?: ContentCategoryOtherInfo;
}

export interface ContentCategoryOtherInfo extends FileBase {

}

export const ConditionTypes: ConditionType[] = [
	{ id: 0, name: 'Rovná sa' },
	{ id: 1, name: 'Nerovná sa' },
	{ id: 2, name: 'Obsahuje' },
	{ id: 3, name: 'Neobsahuje' },
	{ id: 4, name: 'Začína s' },
	{ id: 5, name: 'Končí na' },
	{ id: 6, name: 'Je prázdny' },
	{ id: 7, name: 'Nie je prázdny' },
	{ id: 8, name: 'Je väčší ako' },
	{ id: 9, name: 'Je väčší alebo rovný ako' },
	{ id: 10, name: 'Je menší ako' },
	{ id: 11, name: 'Je menší alebo rovný ako' }
];

export interface ConditionType {
	id?: number;
	name?: string;
}

export interface ContentTag {
	tag?: string;
	description?: string;
	type?: string;
}

export interface Country {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	system?: boolean;
	actived?: boolean;
	ordinalNumber?: number;
	fileId?: number;
	translations?: CountryTranslation[];
	other?: CountryOtherInfo;
}

export interface CountryOtherInfo extends FileBase {
	translationName?: string;
}

export interface CountryTranslation {
	countryId?: number;
	languageId?: number;
	name?: string;
}

export interface Currency {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	system?: boolean;
	code?: string;
	exchange?: number;
	exchangeImport?: boolean;
	exchangeImportCalculate?: boolean;
	exchangeImportCalculateMethod?: number;
	exchangeImportCalculateValue?: number;
	prefix?: string;
	surfix?: string;
	separatorOfDecimal?: string;
	separatorOfThousands?: string;
	placesOfDecimal?: number;
	roundingMethod?: number;
	roundingAccurancy?: number;
}

export interface CurrencyCode {
	code?: string;
	name?: string;
}

export interface CustomField {
	id?: number;
	itemTypeId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	tag?: string;
	ordinalNumber?: number;
	required?: boolean;
	languages?: boolean;
	translations?: CustomFieldTranslation[];
	other?: CustomFieldOtherInfo;
}

export interface CustomFieldOtherInfo {
	translationName?: string;
	translationDefault?: string;
	itemTypeName?: string;
}

export interface CustomFieldTranslation {
	customFieldId?: number;
	languageId?: number;
	name?: string;
	default?: string;
}

export interface CustomFieldBase {
	customFieldValues?: CustomFieldValue[];
}

export interface CustomFieldValue {
	customFieldId?: number;
	languageId?: number;
	itemId?: number;
	value?: string;
}

export interface CustomText {
	id?: number;
	widget?: string;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	tag?: string;
	translations?: CustomTextTranslation[];
	other?: CustomTextOtherInfo;
}

export interface CustomTextOtherInfo {
	translationText?: string;
}

export interface CustomTextTranslation {
	customTextId?: number;
	languageId?: number;
	text?: string;
}

export interface CustomersFilter {
	page?: number;
	pageSize?: number;
	sort?: number;
	search?: string;
	domainId?: number;
	customerGroupId?: number;
	priceListId?: number;
	person?: number;
	actived?: number;
	phone?: string;
	email?: string;
	firstname?: string;
	lastname?: string;
	company?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	city?: string;
	countryId?: number;
}

export interface CustomerLite {
	id?: number;
	domainName?: string;
	parentCustomerName?: string;
	createdDate?: Date;
	updatedDate?: Date;
	activedDate?: any;
	phone?: string;
	email?: string;
	customerGroupName?: string;
	priceListName?: string;
	discountLoyalty?: number;
	discountAdditional?: number;
	discountMaximum?: number;
	discountCurrent?: number;
	title?: string;
	person?: boolean;
	name?: string; // meno a priezvisko alebo názov firmy (automaticky podľa typu)
	firstname?: string;
	lastname?: string;
	company?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	city?: string;
	street?: string;
	houseNumber?: string;
	postcode?: string;
	countryName?: string;
	department?: string;
	classification?: string;
}

export interface CustomerBase {
	customerId?: number;
	customerGroupId?: number;
	domainId?: number;

	// Fakturačné údaje
	person?: boolean;
	title?: string;
	firstname?: string;
	lastname?: string;
	company?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	vatType?: number;
	city?: string;
	street?: string;
	houseNumber?: string;
	postcode?: string;
	countryId?: number;
	phone?: string;
	email?: string;

	// Dodacia adresa
	deliveryAddressCustom?: boolean;
	deliveryAddressName?: string;
	deliveryAddressPhone?: string;
	deliveryAddressCity?: string;
	deliveryAddressStreet?: string;
	deliveryAddressHouseNumber?: string;
	deliveryAddressPostcode?: string;
	deliveryAddressCountryId?: number;

	// Bankový účet
	bankName?: string;
	bankIban?: string;
	bankNumber?: string;
	bankCode?: string;
	bankSwift?: string;
}

// Naplním triedu CustomerBase z triedy Customer
export const CustomerBaseFromCustomer = (customer: Customer): CustomerBase => {

	// Výber predvoleného bankového účtu
	const mainBank = (): CustomerBase => {
		let bank = {} as CustomerBank;
		if ((customer.banks?.length ?? 0) > 0 && customer.banks?.find(bank => bank.main) !== null) {
			bank = customer.banks?.find(bank => bank.main) ?? {};
		}
		return ({
			bankName: bank.name ?? '',
			bankIban: bank.iban ?? '',
			bankNumber: bank.number ?? '',
			bankCode: bank.code ?? '',
			bankSwift: bank.swift ?? ''
		});
	};

	return {
		customerId: customer.id,
		customerGroupId: customer.customerGroupId,
		domainId: customer.domainId,
		person: customer.person,
		title: customer.title,
		firstname: customer.firstname,
		lastname: customer.lastname,
		company: customer.company,
		regId: customer.regId,
		taxId: customer.taxId,
		vatId: customer.vatId,
		vatType: customer.vatType,
		city: customer.city,
		street: customer.street,
		houseNumber: customer.houseNumber,
		postcode: customer.postcode,
		countryId: customer.countryId,
		phone: customer.phone,
		email: customer.email,
		deliveryAddressCustom: false,
		deliveryAddressName: '',
		deliveryAddressCity: '',
		deliveryAddressStreet: '',
		deliveryAddressHouseNumber: '',
		deliveryAddressPostcode: '',
		deliveryAddressCountryId: 0,
		...mainBank()
	}
};

export interface Customer extends CustomFieldBase {
	id?: number;
	domainId?: number;
	parentCustomerId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	activedDate?: any;
	actived?: boolean;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	phone?: string;
	email?: string;
	password?: string;
	socialAccount?: string;
	customerGroupId?: number;
	priceListId?: number;
	discountLoyalty?: number;
	discountAdditional?: number;
	discountMaximum?: number;
	discountCurrent?: number;
	note?: string;
	title?: string;
	person?: boolean;
	name?: string; // meno a priezvisko alebo názov firmy (automaticky podľa typu)
	firstname?: string;
	lastname?: string;
	company?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	vatType?: number; // 0 = nenastavené, 1 = neplatca, 2 = platca, 3 = osoba registrovaná pre DPH
	city?: string;
	street?: string;
	houseNumber?: string;
	postcode?: string;
	countryId?: number;
	department?: string;
	classification?: string;
	banks?: CustomerBank[];
	addresses?: CustomerAddress[];
	contacts?: CustomerContact[];
	other?: CustomerOtherInfo;
}

export interface CustomerOtherInfo {
	parentCustomerName?: string;
}

export interface CustomerContact {
	id?: number;
	name?: string;
	phone?: string;
	email?: string;
}

export interface CustomerBank {
	id?: number;
	customerId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	main?: boolean;
	name?: string;
	iban?: string;
	number?: string;
	code?: string;
	swift?: string;
}

export interface CustomerAddress {
	id?: number;
	customerId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	name?: string;
	phone?: string;
	city?: string;
	street?: string;
	houseNumber?: string;
	postcode?: string;
	countryId?: number;
}

/*
export interface CustomerAgreement {
	id?: number;
	customerId?: number;
	agreementId?: number;
	agreementSourceId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	validUntilDate?: any;
	agreement?: boolean;
}
*/
export interface CustomerGroup {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	system?: boolean;
	priceListId?: number;
	maximumDiscount?: number;
	name?: string;
	note?: string;
}

export interface DefaultPagesList {
	pages?: DefaultPage[];
}

export interface DefaultPage {
	domainId?: number;
	type?: number;
	url?: string;
}

export const DefaultPageTypes: DefaultPageType[] = [
	{ id: 1, name: 'Domovská stránka' },
	{ id: 2, name: 'Chybná stránka' },
	{ id: 3, name: 'Prihlásenie (obnova hesla)' },
	{ id: 4, name: 'Registrácia' },
	{ id: 5, name: 'Podmienky používania' },
	{ id: 6, name: 'Ochrana osobných údajov' },
	{ id: 7, name: 'Produkty' },
	{ id: 8, name: 'Nákupný košík' },
	{ id: 9, name: 'Objednávky' }
];

export interface DefaultPageType {
	id?: number;
	name?: string;
}

export interface DocumentsFilter {
	page?: number;
	pageSize?: number;
	sort?: number;
	search?: string;
	domainId?: number;
	customerGroupId?: number;
	dateOfIssueFrom?: Date;
	dateOfIssueTo?: Date;
	createdDateFrom?: Date;
	createdDateTo?: Date;
	done?: number;
	paid?: number;
	number?: string;
	documentTypeId?: number;
	documentStateId?: number;
	paymentId?: number;
	shipmentId?: number;
	trackingNumber?: string;
	priceTotalWithVatFrom?: number;
	priceTotalWithVatTo?: number;
	phone?: string;
	email?: string;
	firstname?: string;
	lastname?: string;
	company?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	city?: string;
	countryId?: number;
}

export interface DocumentLite {
	id?: number;
	createdDate?: Date;
	updatedDate?: Date;
	dateOfIssue?: any;
	dateOfTaxLiability?: any;
	dateOfDue?: any;
	dateOfDelivery?: any;
	dateOfExpiration?: any;
	done?: boolean;
	paid?: boolean;
	number?: string;
	trackingNumber?: string;
	currencyCode?: string;
	currencyExchange?: number;
	priceTotalNoVat?: number;
	priceTotalVatAmount?: number;
	priceTotalWithVat?: number;
	person?: boolean;
	firstname?: string;
	lastname?: string;
	company?: string;
	regId?: string;
	taxId?: string;
	vatId?: string;
	city?: string;
	street?: string;
	houseNumber?: string;
	postcode?: string;
	countryName?: string;
	email?: string;
	phone?: string;
	domainName?: string;
	customerGroupName?: string;
	documentStateName?: string;
	documentStateColor?: string;
	paymentName?: string;
	shipmentName?: string;
	symbolVariable?: string;
	symbolSpecific?: string;
	symbolConstant?: string;
}

export interface Document extends CustomFieldBase {
	id?: number;
	documentTypeId?: number,
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	dateOfIssue?: any;
	dateOfTaxLiability?: any;
	dateOfDue?: any;
	dateOfDelivery?: any;
	dateOfExpiration?: any;
	done?: boolean;
	paid?: boolean;
	number?: string;
	documentStateId?: number;
	priceListId?: number;
	paymentId?: number;
	shipmentId?: number;
	trackingNumber?: string;
	currencyCode?: string;
	currencyExchange?: number;
	priceTotalNoVat?: number;
	priceTotalVatAmount?: number;
	pricePurchaseTotal?: number;
	symbolVariable?: string;
	symbolSpecific?: string;
	symbolConstant?: string;
	customer?: DocumentCustomer;
	owner?: DocumentCustomer;
	items?: DocumentItem[];
	notes?: DocumentNote[];
	payments?: DocumentPayment[];
	history?: History[];
	relatedParents?: DocumentRelated[];
	relatedChildren?: DocumentRelated[];
	relatedExternal?: DocumentRelated[];
	files?: DocumentFile[];
}

export interface DocumentFile {
	id?: number;
	productId?: number;
	fileId?: number;
	ordinalNumber?: number;
	name?: string;
	other?: DocumentFileOtherInfo;
}

export interface DocumentFileOtherInfo extends FileBase {

}

export interface DocumentRelated extends FileBase {
	id?: number;
	parent?: boolean; // iba na čítanie (pre rozdelenie zoznamu z API, aby sa dali children/parent v jednom zozname)
	typeId?: number;
	typeName?: string;
	number?: string;
	done?: boolean;
	paid?: boolean;
}

// Prevod z triedy dokladu (získanie používaných údajov)
export const DocumentRelatedLoad = (document: Document | DocumentLite, documentTypeId: number, documentTypeName: string): DocumentRelated => {
	return {
		id: document.id,
		typeId: documentTypeId,
		typeName: documentTypeName,
		number: document.number,
		done: document.done,
		paid: document.paid
	};
}

// Prevod z triedy dokladu (získanie používaných údajov)
export const DocumentRelatedExternalLoad = (document: ExternalDocument, documentTypeId: number, documentTypeName: string): DocumentRelated => {
	return {
		id: document.id,
		typeId: documentTypeId,
		typeName: documentTypeName,
		number: document.number,
		done: document.done,
		paid: document.paid,
		fileSrc: document.other?.fileSrc
	};
}

export interface DocumentCustomer extends CustomerBase {
	id?: number;
	documentId?: number;
	type?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
}

export interface DocumentItem extends FileBase {
	id?: number;
	parentId?: number;
	documentId?: number;
	productId?: number;
	createdDate?: Date;
	updatedDate?: Date;
	deletedDate?: Date;
	deleted?: boolean;
	type?: DocumentItemType;
	ordinalNumber?: number;
	ean?: string;
	mpn?: string;
	code?: string;
	codeSupplier?: string;
	name?: string;
	variant?: string;
	note?: string;
	weight?: number;
	quantity?: number;
	unit?: string;
	pricePurchase?: number;
	pricePurchaseVatRate?: number;
	price?: number; 				// cenníková cena s DPH alebo bez DPH podľa 'priceIncludeVat' (používa sa len pre výpočet jednotkovej)
	priceCalc?: number;		 		// cenníková cena - len pre prepočet opačnej ceny 'price' s DPH => bez DPH, alebo bez DPH => s DPH
	priceIncludeVat?: boolean;		// cenníková cena "price" nie / je vrátane DPH
	priceVatRate?: number;			// sadzba DPH v %
	priceUnitNoVat?: number; 		// jednotková cena bez DPH,	výpočet = jednotková cena s DPH - vypočítaná DPH
	priceUnitVatAmount?: number; 	// jednotková výška DPH,	výpočet = jednotková cena s DPH * sadzba / (100 + sadzba), zaokrúhlená na 2 desatiny podľa legislatívy
	priceTotalNoVat?: number;		// celková cena bez DPH,	výpočet = celková cena s DPH - celková vypočítaná DPH)
	priceTotalVatAmount?: number;	// celková výška DPH,		výpočet = celková cena s DPH * sadzba / (100 + sadzba)
	configurator?: DocumentItemConfigurator[];
	items?: DocumentItem[];			// podpoložky podľa parentId
	other?: DocumentItemOtherInfo;
}

export interface DocumentItemOtherInfo extends FileBase {

}

export interface DocumentNote {
	id?: number;
	documentId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	private?: boolean;
	note?: string;
	other?: DocumentNoteOtherInfo;
}

export interface DocumentNoteOtherInfo {
	createdUserName?: string;
}

export interface DocumentPayment {
	id?: number;
	documentId?: number;
	paymentId?: number;
	createdDate?: Date;
	createdUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	price?: number;
	note?: string;
	date?: Date;
	argument?: string;
	other?: DocumentPaymentOtherInfo;
}

export interface DocumentPaymentOtherInfo {
	paymentName?: string;
}

export interface DocumentPaymentType {
	id?: number;
	name?: string;
}

export enum DocumentItemType {
	Product = 0,
	Discount = 1,					// zľava v eurách (platí aj pre kupón, kód kupónu je uchovaný v atribúte "variant")
	DiscountRelative = 2,			// zľava v percentách (cc = percentá, jednotková cena pre prepočítava podľa sumáru položiek)
	Shipment = 3,
	Payment = 4
}

export interface DocumentItemConfigurator {
	uid?: string;
	name?: string;
	value?: string;
	price?: number;
}

// Funkcia pre vytvorenie kópie dokladu s automatickým vynulovaním vnorených id
export const DocumentCopy = (documents: Document[], userName?: string): Document => {
	if (documents.length === 0) {
		return {} as Document;
	}

	// Vytvorím kópiu, aby som ani náhodou nevykonal zmeny v zdrojových dokladoch
	const sources = [...documents];

	// Kópia dokladu (z prvého preberiem vybrané údaje, neprenášam automaticky všetko ,keďže  niektoré údaje ako VS, vystavenie a podobne sa neprenášajú)
	let document = {
		documentTypeId: sources[0].documentTypeId,
		priceListId: sources[0].priceListId,
		paymentId: sources[0].paymentId,
		shipmentId: sources[0].shipmentId,
		trackingNumber: sources[0].trackingNumber,
		currencyCode: sources[0].currencyCode,
		currencyExchange: sources[0].currencyExchange,
		priceTotalNoVat: sources[0].priceTotalNoVat,
		priceTotalVatAmount: sources[0].priceTotalVatAmount,
		customer: { ...sources[0].customer, id: 0 },
		owner: { ...sources[0].owner, id: 0 },
		items: [],
		notes: [],
		payments: [],
		history: [],
		relatedParents: [],
		relatedChildren: [],
		relatedExternal: [],
		customFieldValues: [...sources[0].customFieldValues?.map(item => ({ ...item, itemId: 0 })) ?? []]
	} as Document;

	// Zapíšem históriu
	document.history?.push({
		createdDate: new Date(),
		name: 'Kópia dokladu',
		note: (userName ?? 'Užívateľ') + ' vytvoril kópiu z dokladu č.: ' + sources.map(document => document.number ?? '').join(', ') + '.'
	});

	// Vložím položky zo všetkých dokladov
	sources.forEach(source => {
		source.items?.forEach(item => document.items?.push({ ...item }));
	});

	// Funkcia pre rekurzívne vynulovanie id položiek
	const nullItems = (items?: DocumentItem[]) => {
		items?.forEach(item => {
			item.id = 0;
			nullItems(item.items);
		});
	}
	nullItems(document.items);

	return document;
}

// Funkcia pre automatický prepočet zľavových položiek v doklade (vyvolá sa pri zmene v zozname pred vložením do aktuálneho stavu)
export const DocumentItemsDiscountCompute = (items: DocumentItem[]): DocumentItem[] => {

	// Pozor!
	// -------------------------
	// Záporná zľava znamená prirážku, preto zľava má opačné znamienko a prirážka rovnaké znamienko
	// Každá zľava musí byť jasne stanovená pre jednotlivé sadzby DPH.
	// Ak mám percentuálnu zľavu, tak ju stačí jednoducho aplikovať na celkovú sumu s DPH v každej sadzbe.
	// Ak mám absolútnu zľavu, tak ju musím alikvotne rozpočítať medzi jednotlivé sadzby.
	// Napr. ak mám 20 € zľavu, tak mám zľavu z položiek v DPH sazdbe 20%, 10% alebo 0%.
	// Riešenie je také, že sa najskôr vypočíta celková cena s DPH, následne sa zistí koľko percent z celkovej ceny tvorí zľava.
	// Potom sa pre každú sadzbu alikvotne rozpočíta zľava (posledná sadzba si ponechá zvyšok = nepočíta sa, kvôli centovej chybe zaokrúhlenia)
	// Všetky čísla musia byť zaokrúhlené aspoň na 2 desatiny keďže v JS je výpočet s plávajúcou čiarkou nepresný, napríklad výsledok: 7.188 - 1.2 je: 5.9879999 viac info napr. na: https://stackoverflow.com/questions/33255036/javascript-wrong-calculation
	// Hlavná zľava musí mať nulovú jednotkovú a celkovú cenu, aby neovplivnila sumár (nevyvolať: DocumentItemCompute, iba pre podpoložky)

	// Postup výpočtu
	// -------------------------
	// Zostavím zoznam použitých (v položkách) DPH sadzieb so sumárom celkovej ceny s DPH v danej sadzbe (z položiek, ktoré nie sú typu discount a discountRelative)
	// Následne prejdem každú položku typu: discount a discountRelative, pričom zľavu položky rozpočítam medzi podpoložky samostatne pre každú sadzbu DPH
	// V každej zľavovej položke vytvorím (ak neexistujú, kvôli id) podpoložky pre jednotlivé sadzby DPH
	// V každej zľavovej položke vymažem zbytočné sadzby (ak existujú ale sadzba sa už v položkách nepoužíva)
	// Ak ide o zľavu v %, tak:
	// - Prejdem každú sadzbu a z jej sumáru vypočítam výšku zľavy v danej sadzbe
	// - Položke v danej sadzbe nastavím množstvo 1, zľavu ako záporné číslo a nechám prepočítať funkciu "DocumentItemCompute"
	// Ak ide o zľavu v €, tak:
	// - Vypočítam koľko percent z celkovej ceny s DPH činí daná zľava
	// - Následne postupne od najnižšej sadzby DPH alikvotne rozpočítam zľavu pre každú sadzbu,
	// - Pri poslednej sadzbe už nerobím prepočet ale ponechám zvyšok. Napr. pri zľave 20 € ak som pre 0% DPH použil 2,94 € a pre 10% DPH použil 6,47 €, tak pre 20% DPH použijem rozdiel zľavy a toho čo som použil, teda: 10,59 € = 20 € - (2,94 + 6,47). Ak by som počítal každú sadzbu na percentá, tak by chýbali centy kvôli zaokrúhleniu.
	// - Položke v danej sadzbe nastavím množstvo 1, zľavu ako záporné číslo a nechám prepočítať funkciu "DocumentItemCompute"
	// Nastavím znamienko podľa toho, či ide o zľavu alebo prirážku, zľava má opačné znamienko a prirážka rovnaké znamienko

	// Pokračujem len ak existujú nejaké položky
	if (items.length === 0) {
		return items;
	}

	// Predpis pre sumár sadzby DPH
	interface VatRateSummary {
		vatRate: number;			// sadzba DPH
		totalPriceWithVat: number;	// celková suma s DPH v danej sadzbe
	}

	// Zoznam položiek z ktorých vychádzam, teda bežné položky, ktoré nie sú typu discount a discountRelative (aby som nemusel opakovane robiť zložitý filter)
	const itemsSource = items.filter(item => item.type !== DocumentItemType.Discount && item.type !== DocumentItemType.DiscountRelative);

	// Zoznam použitých sadzieb DPH s celkovým sumárom
	let vatRateSummary: VatRateSummary[] = [];

	// Ponechám len unikátne hodnoty zoradené od najmenšej
	itemsSource?.map(item => item.priceVatRate ?? 0)?.sort((a, b) => a < b ? 1 : -1)?.forEach(vatRate => {
		if (vatRateSummary.find(s => s.vatRate === vatRate) === undefined) {
			vatRateSummary.push({
				vatRate: vatRate,
				totalPriceWithVat: (itemsSource.filter(item => item.priceVatRate === vatRate)?.reduce((sum, current) => sum + ((current?.priceTotalNoVat ?? 0) + (current?.priceTotalVatAmount ?? 0)), 0) ?? 0)
			});
		}
	});

	// Musí existovať minimálne jedna sadzba 
	if (vatRateSummary.length === 0) {
		vatRateSummary.push({ vatRate: 0, totalPriceWithVat: 0 });
	}

	// Spočítam celkovú sumu všetkých sadzieb (používa sa pre výpočet z absolútne zľavy)
	let vatRateSummaryTotal: number = vatRateSummary.reduce((sum, current) => sum + current.totalPriceWithVat, 0) ?? 0;

	// Prejdem a upravím každú položku typu: discount a discountRelative
	items.filter(item => item.type === DocumentItemType.Discount || item.type === DocumentItemType.DiscountRelative)?.forEach(item => {

		// Fix: nemôže sa stať, že hlavná položka bude mať vyplnenú jednotkovú alebo celkovú cenu, keďže sú rozložené v rámci podpoložiek (len pre istotu, toto by nemalo nastať iba ak by som omylom vyvolal: DocumentItemCompute(..) pre položku zľavy)
		item.priceUnitNoVat = 0;
		item.priceUnitVatAmount = 0;
		item.priceTotalNoVat = 0;
		item.priceTotalVatAmount = 0;

		// Cenníková cena vyjadruje celkovú zľavu (absolútnu=eurá alebo relatívnu=percentá)
		let itemDiscount: number = Math.abs(item.price ?? 0); // Pracujem vždy s kladnou hodnotou a až po výpočte nastavujem znamienko
		let itemDiscountIsSurcharge: boolean = (item.price ?? 0) < 0; // Podľa toho či ide o zľavu alebo prirážku po vypočítaní zľavy nastavím znamienko

		// Pri absolútnej zľave alikvótne rozložím sumu medzi jednotlivé sadzby, priebežne si poznačím koľko som už použil (pri poslednej sadzbe použijem zvyšnú sumu)
		let itemDiscountUsed: number = 0;

		// Vyčistím podpoložky od tých, ktorých sadzba už nefiguruje v zozname použitých sadzieb
		item.items = item.items?.filter(i => vatRateSummary.find(v => v.vatRate === i.priceVatRate) !== undefined) ?? [];

		// Postupne prejdem každú sadzbu a vypočítam jej alikvótnu časť zo zľavy
		vatRateSummary.forEach((summary, summaryIndex) => {

			// Absolútna zľava pre danú sadzbu
			let itemPrice: number = 0;

			// Ak je zľava relatívna (percentuálna), tak vychádzam iba z celkovej sumy s DPH danej sadzby
			if (item.type === DocumentItemType.DiscountRelative) {
				itemPrice = MathRound2((summary.totalPriceWithVat / 100) * (itemDiscount ?? 0));
			}

			// Ak je zľava absolútna, tak postupne rozložím celkovú sumu medzi jednotlivé sadzby
			if (item.type === DocumentItemType.Discount) {

				// Skontrolujem, či ide o poslednú sadzbu
				if (vatRateSummary.length === (summaryIndex + 1)) {
					// Pri poslednej sadzbe už nevykonám výpočet (posledná sadzba si ponechá zvyšok = nepočíta sa, kvôli centovej chybe zaokrúhlenia)
					itemPrice = MathRound2(itemDiscount - itemDiscountUsed);
				}
				else {
					// Vypočítam koľko percent z celkovej ceny s DPH (celého dokladu, nie jen danej sadzby) činí daná zľava
					let discountInPercent: number = itemDiscount / (vatRateSummaryTotal / 100);

					// Vypočítam alikvótnu časť z tejto sadzby
					itemPrice = MathRound2((summary.totalPriceWithVat / 100) * discountInPercent);
				}

				// Poznačím použitú časť zo zľavy
				itemDiscountUsed += itemPrice;
			}

			// Zľava - opačné znamienko
			if (itemDiscountIsSurcharge === false) {
				itemPrice = summary.totalPriceWithVat >= 0 ? -Math.abs(itemPrice) : Math.abs(itemPrice);
			}

			// Prirážka - rovnaké znamienko
			if (itemDiscountIsSurcharge === true) {
				itemPrice = summary.totalPriceWithVat < 0 ? -Math.abs(itemPrice) : Math.abs(itemPrice);
			}

			// Aktualizujem položku
			item.items = [
				...item.items?.filter(item => item.priceVatRate !== summary.vatRate) ?? [], // ostatné podpoložky
				DocumentItemCompute({
					...(item.items?.find(item => item.priceVatRate === summary.vatRate) ?? {}), // pôvodná položka
					price: itemPrice,
					priceIncludeVat: true, // vždy vychádzam z ceny s DPH (musí sedieť celková suma dokladu, teda suma s DPH)
					priceVatRate: summary.vatRate,
					type: item.type,
					name: item.name,
					quantity: item.quantity,
					unit: item.unit
				})
			];
		});
	});

	return items;
};

// Funkcia pre automatický prepočet položky dokladu
export const DocumentItemCompute = (item: DocumentItem, changedProperty?: string): DocumentItem => {

	// Postup výpočtu (nastavenie výpočtu DPH na doklade na toto nemá vplyv, nastavenie je pre sumár dokladu, nie položky)
	// -------------------------
	// Pri výpočte vychádzam z ceny s DPH.
	// Výšku DPH vypočítam z ceny s DPH vypočítam a zaokrúhlim na 2 desatiny.
	// Cenu bez DPH vypočítam tak, že z ceny s DPH odpočítam vypočítanú DPH.

	// Pozor!
	// -------------------------
	// Všetky čísla musia byť zaokrúhlené aspoň na 4 desatiny keďže v JS je výpočet s plávajúcou čiarkou nepresný
	// Napríklad výsledok: 7.188 - 1.2 je: 5.9879999
	// Viac info napr. na: https://stackoverflow.com/questions/33255036/javascript-wrong-calculation
	// Riešením by bolo napríklad posunom odstrániť desatinnú, vykonať výpočty a následne posunúť čiarku späť, podobne ako pri zaokrúhlení

	// Sadzba DPH
	const priceVatRate = (item.priceVatRate ?? 0);

	// Prepočítam cenníkovú cenu a zaokrúhlim na 4 desatiny (iba ak som zmenil hodnotu 'priceCalc' tak vykonám prepočet spätne)
	if (changedProperty && changedProperty === 'priceCalc') {
		item.price = (!item.priceIncludeVat ?
			MathRound4((item.priceCalc ?? 0) / ((100 + priceVatRate) / 100)) :
			MathRound4((item.priceCalc ?? 0) * ((100 + priceVatRate) / 100)));
	} else {
		item.priceCalc = (item.priceIncludeVat ?
			MathRound4((item.price ?? 0) / ((100 + priceVatRate) / 100)) :
			MathRound4((item.price ?? 0) * ((100 + priceVatRate) / 100)));
	}

	// Jednotková cena s DPH / bez DPH vrátane položiek konfigurátora
	const priceUnit = MathRound4((item.price ?? 0) + (item?.configurator?.reduce((sum, current) => sum + (current?.price ?? 0), 0) ?? 0));

	// Jednotková cena s DPH zaokrúhlená na 4 desatiny
	const priceUnitWithVat = MathRound4(item.priceIncludeVat ? priceUnit : (priceUnit ?? 0) * ((100 + priceVatRate) / 100));

	// Vypočítam hodnotu DPH a zaokrúhlim na 2 desatiny a následne použijem pre výpočet jednotkovej ceny bez DPH
	item.priceUnitVatAmount = MathRound2((priceUnitWithVat * priceVatRate) / (100 + priceVatRate));
	item.priceUnitNoVat = MathRound4(priceUnitWithVat - item.priceUnitVatAmount);

	// Celková cena s DPH zaokrúhlená na 2 desatiny
	const priceTotalWithVat = MathRound2(priceUnitWithVat * (item.quantity ?? 0));

	// Vypočítam hodnotu DPH a zaokrúhlim na 2 desatiny a následne použijem pre výpočet celkovej ceny bez DPH
	item.priceTotalVatAmount = MathRound2((priceTotalWithVat * priceVatRate) / (100 + priceVatRate));
	item.priceTotalNoVat = MathRound4(priceTotalWithVat - item.priceTotalVatAmount);

	// Vrátim prepočítanú položku
	return item;
}

// Nastavenie nulovej sadzby DPH pre všetky položky (vnútorne vyvolá prepočet zľiav)
// Pozor, hodnoty konfigurátora položky sa neprepočítavajú
export const DocumentItemsSetZeroVatRate = (items: DocumentItem[]): DocumentItem[] => {

	// Pokračujem len ak existujú nejaké položky
	if (items.length === 0) {
		return items;
	}

	// Funkcia pre výpočet ceny bez DPH
	const computePriceWithoutVat = (priceWithVat: number, vatRate: number): number => {
		return MathRound4(priceWithVat / ((100 + vatRate) / 100));
	};

	// Prejdem bežné položky s nastavenou DPH, ktoré nie sú typu discount a discountRelative
	items.filter(item => (item.priceVatRate ?? 0) > 0 && item.type !== DocumentItemType.Discount && item.type !== DocumentItemType.DiscountRelative)?.forEach(item => {
		// Vždy sa vychádza z cenníkovej ceny "price", ktorá môže byť zadaná s DPH alebo bez DPH (toto sa nemení)
		if (item.priceIncludeVat) {
			// Cenníková cena je zadaná s DPH (B2C) a preto ju musím prepočítať na cenu bez DPH

			// Prepočítam ceny položiek konfigurátora
			// Pozor, nie tie čo majú hodnotu väčšiu ako nulu, ale tie, ktoré majú hodnotu inú ako nulu, keďže tam môže figurovať záporná zľava
			item.configurator?.forEach(configurator => {
				if ((configurator.price ?? 0) !== 0) {
					configurator.price = computePriceWithoutVat(configurator.price ?? 0, item.priceVatRate ?? 0);
				}
			});

			// Prepočítam cenníkovú cenu
			item.price = computePriceWithoutVat(item.price ?? 0, item.priceVatRate ?? 0);
			item.priceVatRate = 0;
			item = DocumentItemCompute(item);
		}
		else {
			// Cenníková cena je zadaná bez DPH (B2B) a preto stačí, ak nastavím nulovú sadzbu DPH a prepočítam položku
			item.priceVatRate = 0;
			item = DocumentItemCompute(item);
		}
	});

	// Pri zmene sazdby musím automaticky prepočítať aj zľavy, keďže sa delia medzi jednotlivé sadzby DPH
	return DocumentItemsDiscountCompute([...items]);
}

export interface DocumentType {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	system?: boolean;
	type?: number;
	actived?: boolean;
	sale?: boolean;
	ordinalNumber?: number;

	fillDateOfIssue?: number;
	fillDateOfDue?: number;
	fillDateOfTaxLiability?: number;
	fillDateOfDelivery?: number;
	fillDateOfExpiration?: number;

	hideDateOfIssue?: boolean;
	hideDateOfTaxLiability?: boolean;
	hideDateOfDue?: boolean;
	hideDateOfDelivery?: boolean;
	hideDateOfExpiration?: boolean;

	hideTrackingNumber?: boolean;
	hideSymbols?: boolean;
	hidePayments?: boolean;
	hideDocuments?: boolean;
	hideNotes?: boolean;
	hideFiles?: boolean;

	translations?: DocumentTypeTranslation[];
	other?: DocumentTypeOtherInfo;
}

export interface DocumentTypeOtherInfo {
	translationName?: string;
	typeName?: string;
}

// Pevný číselník "type" pre druh dokladu "document_type", podľa tabuľky: document_type_type
export const DocumentTypeTypes: DocumentTypeType[] = [
	{ id: 0, name: 'Vlastné', reportTemplate: 'report-document.html' },
	{ id: 1, name: 'Dopyt', reportTemplate: 'report-document.html' },
	{ id: 2, name: 'Ponuka', reportTemplate: 'report-document.html' },
	{ id: 3, name: 'Objednávka', reportTemplate: 'report-order.html' },
	{ id: 4, name: 'Zálohová faktúra', reportTemplate: 'report-invoice-proforma.html' },
	{ id: 5, name: 'Faktúra', reportTemplate: 'report-invoice.html' },
	{ id: 6, name: 'Dobropis', reportTemplate: 'report-invoice-cancel.html' },
	{ id: 7, name: 'Dodací list', reportTemplate: 'report-document.html' },
	{ id: 8, name: 'Reklamácia', reportTemplate: 'report-claim.html' }
];

export interface DocumentTypeType {
	id?: number;
	name?: string;
	reportTemplate?: string;
}

export interface DocumentTypeTranslation {
	documentTypeId?: number;
	languageId?: number;
	name?: string;
}

export interface DocumentState {
	id?: number;
	documentTypeId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	ordinalNumber?: number;
	color?: string;
	markAsDone?: boolean;
	markAsPaid?: boolean;
	translations?: DocumentStateTranslation[];
	other?: DocumentStateOtherInfo;
}

export interface DocumentStateOtherInfo {
	translationName?: string;
}

export interface DocumentStateTranslation {
	documentStateId?: number;
	languageId?: number;
	name?: string;
}

export interface Domain {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	blocked?: boolean;
	strict?: boolean;
	default?: boolean;
	languageId?: number;
	currencyId?: number;
	priceListId?: number;
	name?: string;
	url?: string;
	urlRedirect?: string;
	other?: DomainOtherInfo;
}

export interface DomainOtherInfo {
	languageCode?: string;
	currencyCode?: string;
	priceListName?: string;
}

export interface EmailAccount {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	default?: boolean;
	name?: string;
	email?: string;
	imapUsername?: string;
	imapPassword?: string;
	imapServer?: string;
	imapPort?: number;
	imapSecurity?: number;
	smtpUsername?: string;
	smtpPassword?: string;
	smtpServer?: string;
	smtpPort?: number;
	smtpSecurity?: number;
}

export interface ExternalDocument extends CustomFieldBase {
	id?: number;
	externalDocumentTypeId?: number;
	createdDate?: any;
	createdUserId?: number;
	updatedDate?: any;
	updatedUserId?: number;
	deletedDate?: any;
	deletedUserId?: number;
	deleted?: boolean;
	done?: boolean;
	paid?: boolean;
	dateOfIssue?: any;
	dateOfTaxLiability?: any;
	dateOfDue?: any;
	dateOfDelivery?: any;
	dateOfExpiration?: any;
	number?: string;
	price?: number;
	currencyCode?: string;
	currencyExchange?: number;
	note?: string;
	customerId?: number;
	fileId?: number;
	symbolVariable?: string;
	symbolSpecific?: string;
	symbolConstant?: string;
	relatedDocuments?: DocumentRelated[];
	other?: ExternalDocumentOtherInfo;
}

export interface ExternalDocumentOtherInfo extends FileBase {
	customerName?: string;
}

export interface ExternalDocumentType {
	id?: number;
	createdDate?: any;
	createdUserId?: number;
	updatedDate?: any;
	updatedUserId?: number;
	deletedDate?: any;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	ordinalNumber?: number;
	translations?: ExternalDocumentTypeTranslation[];
	other?: ExternalDocumentTypeOtherInfo;
}

export interface ExternalDocumentTypeOtherInfo {
	translationName?: string;
}

export interface ExternalDocumentTypeTranslation {
	externalDocumentTypeId?: number;
	languageId?: number;
	name?: string;
}

export interface ExternalDocumentsFilter {
	page?: number;
	pageSize?: number;
	sort?: number;
	search?: string;
	externalDocumentTypeId?: number;
	number?: string,
	dateOfIssueFrom?: Date,
	dateOfIssueTo?: Date,
	priceFrom?: number,
	priceTo?: number
	done?: number,
	paid?: number,
	dateOfDeliveryFrom?: Date,
	dateOfDeliveryTo?: Date,
	dateOfExpirationFrom?: Date,
	dateOfExpirationTo?: Date,
	phone?: string,
	email?: string,
	firstname?: string,
	lastname?: string,
	company?: string,
	regId?: string,
	taxId?: string,
	vatId?: string,
	city?: string,
	countryId?: number
}

/*
export interface ExternalApplication {
	id?: number;
	name?: string;
}
*/

export interface ExternalCatalog {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	ordinalNumber?: number;
	uid?: string;
	name?: string;
	note?: string;
	settings?: string;
}

/*
export interface ExternalNumber {
	itemTypeId?: number;
	itemId?: number;
	externalApplicationId?: number;
	number?: string;
}
*/

export interface FilesFilter {
	page: number;
	pageSize: number;
	sort: number;
	search: string;
	directoryId: number;
}

export interface File extends FileBase {
	id?: number;
	fileDirectoryId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	name?: string;
	size?: number;
	preview?: boolean;
	originalName?: string;
	originalSize?: number;
	originalFileName?: string;
	discarded?: boolean;
	watermark?: boolean;
	watermarkPrevious?: boolean;
	checksum?: string;
}

export interface FileBase {
	fileExtension?: string;
	fileIsImage?: boolean;
	fileName?: string;
	fileNameSmall?: string;
	fileNameMedium?: string;
	fileSrc?: string;
	fileSrcSmall?: string;
	fileSrcMedium?: string;
}

export interface FileDirectory {
	id?: number;
	parentId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	type?: number;
	name?: string;
}

export interface Form {
	id?: number;
	widget?: string;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	contentCategoryId?: number;
	name?: string;
	note?: string;
	email?: string;
	settings?: FormSettings;
	other?: FormOtherInfo;
}

export interface FormOtherInfo {
	contentCategoryName?: string;
}

export interface FormSettings {
	items?: FormSettingsItem[];
}

export interface FormSettingsItem {
	input?: FormSettingsItemInputType;
	ordinalNumber?: number;
	label?: string;
	required?: boolean;
	values?: string;
	defaultValue?: string;
	argument1?: string;
	argument2?: string;
	argument3?: string;
	argument4?: string;
	argument5?: string;
}

export enum FormSettingsItemInputType {
	None,
	Textbox,
	Textarea,
	Radio,
	Checkbox,
	Select,
	Captcha
}

export interface Gallery {
	id?: number;
	widget?: string;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	contentCategoryId?: number;
	name?: string;
	note?: string;
	items?: GalleryItem[];
	other?: GalleryOtherInfo;
}

export interface GalleryOtherInfo {
	contentCategoryName?: string;
}

export interface GalleryItem {
	id?: number;
	galleryId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	fileId?: number;
	ordinalNumber?: number;
	name?: string;
	other?: GalleryItemOtherInfo;
}

export interface GalleryItemOtherInfo extends FileBase {

}

export interface Gift extends CustomFieldBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	minimalPrice?: number;
	fileId?: number;
	note?: string;
	activedDateFrom?: any;
	activedDateTo?: any;
	domains?: number[];
	customerGroups?: number[];
	translations?: GiftTranslation[];
	other?: GiftOtherInfo;
}

export interface GiftOtherInfo extends FileBase {
	translationName?: string;
}

export interface GiftTranslation {
	giftId?: number;
	languageId?: number;
	name?: string;
}

export interface History {
	createdDate?: Date;
	createdUserId?: number;
	itemTypeId?: number;
	itemId?: number;
	customerId?: number;
	name?: string;
	note?: string;
}

export interface Import {
	id?: number;
	createdDate?: any;
	createdUserId?: number;
	updatedDate?: any;
	updatedUserId?: number;
	deletedDate?: any;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	client?: string;
	priority?: number;
	name?: string;
	source?: string;
	interval?: number;
	intervalHour?: number;
	intervalOnce?: boolean;
}

export interface ImportQueue {
	id?: number;
	createdDate?: any;
	createdUserId?: number;
	updatedDate?: any;
	updatedUserId?: number;
	deletedDate?: any;
	deletedUserId?: number;
	deleted?: boolean;
	client?: string;
	priority?: number;
	state?: number;
	note?: string;
	source?: string;
	protocol?: string;
}

export interface Export {
	id?: number;
	createdDate?: any;
	createdUserId?: number;
	updatedDate?: any;
	updatedUserId?: number;
	deletedDate?: any;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	client?: string;
	priority?: number;
	name?: string;
	interval?: number;
	intervalHour?: number;
	intervalOnce?: boolean;
	type?: number;
	token?: string;
	format?: number;
	filter?: ExportFilter;
	parameters?: string[];
}

export interface ExportQueue {
	id?: number;
	createdDate?: any;
	createdUserId?: number;
	updatedDate?: any;
	updatedUserId?: number;
	deletedDate?: any;
	deletedUserId?: number;
	deleted?: boolean;
	client?: string;
	priority?: number;
	state?: number;
	note?: string;
	protocol?: string;
	type?: number;
	token?: string;
	format?: number;
	filter?: ExportFilter;
	parameters?: string[];
}

export interface ExportFilter {
	createdDateFrom?: any;
	createdDateTo?: any;
	updatedDateFrom?: any;
	updatedDateTo?: any;
	priceFrom?: number;
	priceTo?: number;
	categoryId?: number;
	stockAvailabilityId?: number;
	domainId?: number;
	tagId?: number;
	actived?: number;
	quantityFrom?: number;
	quantityTo?: number;
}

export const ExportTypes: ExportType[] = [
	{
		id: 1,
		name: 'Produkty',
		parameters: [
			{ uid: 'Basic', name: 'Základné údaje' },
			{ uid: 'Domains', name: 'Domény' },
			{ uid: 'Category', name: 'Kategória (hlavná)' },
			{ uid: 'PriceLists', name: 'Cenníky' },
			{ uid: 'Tags', name: 'Štítky' },
			{ uid: 'Files', name: 'Súbory' },
			{ uid: 'Stocks', name: 'Sklady' },
			{ uid: 'Parameters', name: 'Parametre' },
			{ uid: 'Properties', name: 'Charakteristika' },
			{ uid: 'DescriptionLong', name: 'Úplný popis' },
			{ uid: 'Related', name: 'Súvisiace' },
			{ uid: 'ExternalCatalogs', name: 'Externé katalógy' },
			{ uid: 'VolumeDiscounts', name: 'Množstevné zľavy' },
			{ uid: 'CustomerGroups', name: 'Zákaznícke skupiny' },
			{ uid: 'Shipments', name: 'Dopravy' },
			{ uid: 'CustomFields', name: 'Vlastné polia' },
		]
	},
	/*{
		id: 2,
		name: 'Doklady',
		parameters: [
			{ uid: 'Items', name: 'Položky' },
			{ uid: 'Payments', name: 'Úhrady' },
			{ uid: 'Documents', name: 'Doklady' },
			{ uid: 'Notes', name: 'Poznámky' },
			{ uid: 'Files', name: 'Prílohy' },
			{ uid: 'History', name: 'História' },
		]
	},*/
];

export interface ExportType {
	id?: number;
	name?: string;
	parameters?: ExportTypeParameter[];
}

export interface ExportTypeParameter {
	uid?: string;
	name?: string;
}

export interface ImportExportQueueState {
	id?: number;
	name?: string;
	color?: string;
}

export const ImportExportQueueStates: ImportExportQueueState[] = [
	{ id: 0, color: '', name: 'Čaká' },
	{ id: 1, color: 'e6d200', name: 'Prebieha' },
	{ id: 2, color: '2e7d32', name: 'Hotovo' },
	{ id: 3, color: 'e65100', name: 'Hotovo s varovaním' },
	{ id: 4, color: 'b71c1c', name: 'Chyba' },
	{ id: 5, color: '121212', name: 'Zrušené' },
];

export interface ImportExportInterval {
	id?: number;
	name?: string;
}

export const ImportExportIntervals: ImportExportInterval[] = [
	{ id: 1, name: 'Pondelok' },
	{ id: 2, name: 'Utorok' },
	{ id: 3, name: 'Streda' },
	{ id: 4, name: 'Štvrtok' },
	{ id: 5, name: 'Piatok' },
	{ id: 6, name: 'Sobota' },
	{ id: 7, name: 'Nedeľa' },
	{ id: 8, name: 'Každý deň' },
	{ id: 9, name: 'Každú hodinu' }
];

export interface ItemType {
	id?: number;
	name?: string;
}

export interface InfoToolbar {
	notesCount?: number;
	ordersCount?: number;
	ordersTypeId?: number;
}

export interface LoggedSession {
	message?: string;
	userName?: string;
	session?: string;
	sessionRemember?: string;
}

export interface Language {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	system?: boolean;
	ordinalNumber?: number;
	code?: string;
	name?: string;
	fileId?: number;
	other?: LanguageOtherInfo;
}

export interface LanguageOtherInfo extends FileBase {

}

export interface LanguageCode {
	code?: string;
	name?: string;
}

export interface LoyaltySystem {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	orderFromDate?: any;
	orderMaxAge?: number;
	name?: string;
	domains?: number[];
	customerGroups?: number[];
	levels?: LoyaltySystemLevel[];
}

export interface LoyaltySystemLevel {
	loyaltySystemId?: number;
	price?: number;
	discount?: number;
}

/*
export interface Newsletter {
	id?: number;
	domainId?: number;
	createdDate?: Date;
	updatedDate?: Date;
	deletedDate?: Date;
	deleted?: boolean;
	actived?: boolean;
	email?: string;
	emailVerifiedDate?: any;
	emailVerified?: boolean;
}
*/

export interface Note {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	notificationDate?: Date;
	active?: boolean;
	private?: boolean;
	color?: string;
	text?: string;
	other?: NoteOtherInfo;
}

export interface NoteOtherInfo {
	createdUserName?: string;
}

export const NoteColors: NoteColor[] = [
	{ textColor: '000000', backgroundColor: 'e9e9e9' },
	{ textColor: 'ffffff', backgroundColor: '424242' },
	{ textColor: 'ffffff', backgroundColor: 'd50000' },
	{ textColor: 'ffffff', backgroundColor: '0d47a1' },
	{ textColor: 'ffffff', backgroundColor: '00bfa5' },
	{ textColor: '000000', backgroundColor: 'ffeb3b' },
	{ textColor: 'ffffff', backgroundColor: 'ef6c00' },
	{ textColor: 'ffffff', backgroundColor: 'c51162' },
	{ textColor: 'ffffff', backgroundColor: '9c27b0' },
];

export interface NoteColor {
	textColor: string;
	backgroundColor: string;
}

export interface NumberSequence {
	id?: number;
	documentTypeId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	year?: number;
	lastNumber?: number;
	template?: string;
	other?: NumberSequenceOtherInfo;
}

export interface NumberSequenceOtherInfo {
	documentTypeName?: string;
	documentTypeSale?: boolean;
}

export interface Overview {
	todayOrdersCount?: number;
	todayOrdersPriceSale?: number;
	todayOrdersPricePurchase?: number;
	todayOrdersPriceProfit?: number;
	totalOrdersCountNotDone?: number;
	thisMonthOrdersPriceSale?: number;
	ordersTypeId?: number;
	orders?: DocumentLite[];
	charts?: Chart[];
	currency?: Currency;
}

export interface Parameter {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	required?: boolean;
	ordinalNumber?: number;
	type?: number; // 0 = charakteristika, 1 = výber varianty, 2 = konfigurátor prepínač, 3 = konfigurátor multivýber
	showInFilter?: boolean;
	showInList?: boolean;
	showInDetail?: boolean;
	displayMethod?: number;	// 0 = text, 1 = farba, 2 = obrázok, 3 = text a obrázok, 4 = text a farba
	items?: ParameterItem[];
	translations?: ParameterTranslation[];
	other?: ParameterOtherInfo;
}

export const ParameterTypes: ParameterType[] = [
	{ id: 0, name: 'Vlastnosť' },
	{ id: 1, name: 'Varianta' },
	{ id: 2, name: 'Konfigurátor - prepínač' },
	{ id: 3, name: 'Konfigurátor - multivýber' }
];

export interface ParameterType {
	id?: number;
	name?: string;
}

export interface ParameterOtherInfo {
	translationName?: string;
}

export interface ParameterItem {
	id?: number;
	parameterId?: number;
	fileId?: number;
	createdDate?: Date;
	updatedDate?: Date;
	deletedDate?: Date;
	deleted?: boolean;
	actived?: boolean;
	ordinalNumber?: number;
	color?: string;
	default?: boolean;
	price?: number;
	priceLists?: ParameterItemPriceList[];
	translations?: ParameterItemTranslation[];
	other?: ParameterItemOtherInfo;
}

export interface ParameterItemOtherInfo extends FileBase {
	translationName?: string;
}

export interface ParameterItemPriceList {
	parameterItemId?: number;
	priceListId?: number;
	price?: number;
}

export interface ParameterItemTranslation {
	parameterItemId?: number;
	languageId?: number;
	slug?: string;
	name?: string;
}

// Funkcia pre výpočet cenníkovej ceny parametra produktu
export const ParameterItemPriceCompute = (parameterItem: ParameterItem, priceList: PriceList, customerDiscount: number): number => {

	// Postup
	// -------------------------
	// Ak nie je nastavený žiadny cenník, tak použijem základnú cenu
	// Získam konkrétnu cenu pre daný cenník
	// Ak nie je zadaná konkrétna cena, tak ju vypočítam zo základnej ceny (na dve desatiny)
	// Na výstupnú cenu automaticky aplikujem zľavu zákazníka (na dve desatiny)

	// Zaokruhlovanie
	// -------------------------
	// Pri generovaní novej ceny (napr. aplikovanie zľavy a odvodenie zo základnej ceny) pracujem s dvomi miestami (napr. výpočet výšky DPH z ceny nie je generovanie novej ceny)
	// Výstupnná cena je zaokrúhlená na štyri miesta, keďže systém pracuje so štýrmi miestami
	// Takže generovanie novej ceny je "pekné" na dve desatiny

	// Funkcia pre aplikovanie zľavy, ak je zľava nulová tak vrátim pôvodnú cenu
	const applyDiscount = (price: number, discount: number): number => discount === 0 || price === 0 ? price : price * ((100 - discount) / 100);

	// Výstupná a základná cena
	let price = 0;
	let priceBasic = parameterItem.price ?? 0;

	// Ak je nastavený cenník, tak sa pokúsim nájsť konkrétnu cenníkovu cenu
	if ((priceList.id ?? 0) > 0) {
		price = parameterItem.priceLists?.find(p => p.priceListId === priceList.id)?.price ?? 0;
	}

	// Ak je nastavený cenník, ale nenašiel som konkrétnu cenníkovú cenu, tak ju vypočítam zo základnej pomocou zľavy cenníka (na dve desatiny)
	if (price === 0 && (priceList.discount ?? 0) !== 0) {
		price = MathRound2(applyDiscount(priceBasic, (priceList.discount ?? 0)));
	}

	// Ak sa nenašla cena podľa cenníku, tak použijem základnú cenu
	if (price === 0) {
		price = priceBasic;
	}

	// Aplikujem zľavu zákazníka
	if (customerDiscount !== 0) {
		price = MathRound2(applyDiscount(price, customerDiscount));
	}

	// Vrátim výstupnú cenu zaorkuhlenú na 4 desatiny 
	return MathRound4(price);
}

export interface ParameterTranslation {
	parameterId?: number;
	languageId?: number;
	slug?: string;
	name?: string;
	note?: string;
	newItems?: string;
}

export interface Payment extends CustomFieldBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	type?: number;
	ordinalNumber?: number;
	fileId?: number;
	note?: string;
	minimalPrice?: number;
	maximalPrice?: number;
	feeType?: number;	// 0 = absolútna hodnota napr. v EUR, 1 = relatívna hodnota v %
	feeValue?: number;
	feeFreeFrom?: number;
	feeVatRate?: number;
	settings?: PaymentSetting[];
	domains?: number[];
	translations?: PaymentTranslation[];
	other?: PaymentOtherInfo;
}

export interface PaymentOtherInfo extends FileBase {
	translationName?: string;
}

export interface PaymentTranslation {
	paymentId?: number;
	languageId?: number;
	name?: string;
	note?: string;
}

export interface PaymentType {
	id?: number;
	name?: string;
	settings?: PaymentTypeSetting[];
}

export interface PaymentTypeSetting {
	uid?: string;
	name?: string;
}

export interface PaymentSetting {
	uid?: string;
	value?: string;
}

export interface Popup {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	activedDateFrom?: any;
	activedDateTo?: any;
	intervalType?: number;
	interval?: number;
	name?: string;
	text?: string;
	domains?: number[];
}

export interface PriceListSettings {
	priceIncludeVat: boolean;
}

export interface PriceList extends CustomFieldBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	name?: string;
	minimalPrice?: number;
	discount?: number;
	highlightPriceNoVat?: boolean;
	allowVoucher?: boolean;
	allowVolumeDiscount?: boolean;
	discounts?: PriceListDiscount[];
}

export interface PriceListDiscount {
	priceListId?: number;
	quantity?: number;
	discount?: number;
}

export interface ProductsFilter {
	page: number;
	pageSize: number;
	sort: number;
	model?: string;
	search?: string;
	fulltext?: string;
	categoryId?: number;
	domainId?: number;
	tagId?: number;
	actived?: number;
	hideCart?: number;
	name?: string;
	ean?: string;
	mpn?: string;
	code?: string;
	codeSupplier?: string;
	externalCatalogId?: number;
	stockAvailabilityId?: number;
	stockAvailabilityQuantityFrom?: number;
	stockAvailabilityQuantityTo?: number;
	priceFrom?: number;
	priceTo?: number;
	weightFrom?: number;
	weightTo?: number;
	parametersColumn?: number[];
}

export interface ProductLite extends FileBase {
	id?: number;
	createdDate?: Date;
	updatedDate?: Date;
	actived?: boolean;
	hideCart?: boolean;
	hideReview?: boolean;
	categoryName?: string;
	name?: string;
	parameters?: string;
	model?: string;
	ean?: string;
	mpn?: string;
	code?: string;
	codeSupplier?: string;
	stockAvailability?: string;
	stockAvailabilityColor?: string;
	stockAvailabilityQuantity?: number;
	price?: number;
	priceDiscount?: number;
	pricePurchase?: number;
	discount?: boolean;
	discountFrom?: Date;
	discountTo?: Date;
	weight?: number;
	dimensionWidth?: number;
	dimensionLength?: number;
	dimensionHeight?: number;
	ordinalNumber?: number;
}

// Funkcia pre výpočet cenníkovej ceny produktu
export const ProductPriceCompute = (product: Product, priceList: PriceList, tagDiscount: number, customerDiscount: number): number => {

	// Postup
	// -------------------------
	// Získam informáciu, či je produkt v zľave: musí byť zadaná základná akciová cena a musí byť aktívny šítok "akcia"
	// Ak nie je nastavený žiadny cenník, tak použijem základnú cenu, alebo akciovú cenu a ďalej nepokračujem.
	// Ak produkt je v zľave:
	// - Získam konkrétnu akciovú cenu pre daný cenník
	// - Ak nie je zadaná konkrétna akciová cena, tak ju vypočítam zo základnej akciovej ceny (na dve desatiny)
	// Ak produkt nie je v zľave:
	// - Získam konkrétnu cenu pre daný cenník
	// - Ak nie je zadaná konkrétna cena, tak ju vypočítam zo základnej ceny
	// Na výstupnú cenu automaticky aplikujem zľavu zákazníka (na dve desatiny)

	// Zaokruhlovanie
	// -------------------------
	// Pri generovaní novej ceny (napr. aplikovanie zľavy a odvodenie zo základnej ceny) pracujem s dvomi miestami (napr. výpočet výšky DPH z ceny nie je generovanie novej ceny)
	// Výstupnná cena je zaokrúhlená na štyri miesta, keďže systém pracuje so štýrmi miestami
	// Takže generovanie novej ceny je "pekné" na dve desatiny

	// Funkcia vráti hodnotu null ak je dátum menší alebo rovný ako 01.01.1970
	const nullMinDate = (date?: Date): Date | null => {
		if (date === undefined) {
			return null;
		}
		if (new Date(date) <= new Date(0)) {
			return null;
		}
		return new Date(date);
	};

	// Funkcia pre aplikovanie zľavy, ak je zľava nulová tak vrátim pôvodnú cenu
	const applyDiscount = (price: number, discount: number): number => discount === 0 || price === 0 ? price : price * ((100 - discount) / 100);

	// Zistím, či je produkt v zľave, musí byť zadaná základná akciová cena a musí byť aktívny šítok "akcia" 
	let discountActive = ((product.priceDiscount ?? 0) > 0 && tagDiscount > 0 && product.tags && product.tags.find(tag =>
		tag.tagId === tagDiscount &&
		(nullMinDate(tag.validDateFrom) ?? new Date(2000, 0, 1)) <= new Date() &&
		(nullMinDate(tag.validDateTo) ?? new Date(3000, 0, 1)) >= new Date()
	));

	// Výstupná a základná cena
	let price = 0;
	let priceBasic = (discountActive ? (product.priceDiscount ?? 0) : (product.price ?? 0));

	// Ak je nastavený cenník, tak sa pokúsim nájsť konkrétnu cenníkovu cenu
	if ((priceList.id ?? 0) > 0) {
		price = (discountActive ?
			(product.priceLists?.find(p => p.priceListId === priceList.id)?.discount ?? 0) :
			(product.priceLists?.find(p => p.priceListId === priceList.id)?.price ?? 0));
	}

	// Ak je nastavený cenník, ale nenašiel som konkrétnu cenníkovú cenu, tak ju vypočítam zo základnej pomocou zľavy cenníka
	if (price === 0 && (priceList.discount ?? 0) !== 0) {
		price = MathRound2(applyDiscount(priceBasic, (priceList.discount ?? 0)));
	}

	// Ak sa nenašla cena podľa cenníku, tak použijem základnú cenu
	if (price === 0) {
		price = priceBasic;
	}

	// Aplikujem zľavu zákazníka
	if (customerDiscount !== 0) {
		price = MathRound2(applyDiscount(price, customerDiscount));
	}

	// Vrátim výstupnú cenu zaorkuhlenú na 4 desatiny 
	return MathRound4(price);
}

export interface Product extends CustomFieldBase {
	id?: number;
	categoryId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	hideCart?: boolean;
	hideReview?: boolean;
	hidePrice?: boolean;
	hidePriceUnlogged?: boolean;
	fixedAll?: boolean;
	fixedPrice?: boolean;
	fixedAvailability?: boolean;
	unitId?: number;
	infoUnitId?: number;
	infoUnitQuantity?: number;
	model?: string;
	ean?: string;
	mpn?: string;
	code?: string;
	codeSupplier?: string;
	stockType?: number;
	stockAvailabilityId?: number;
	price?: number;
	priceVatRate?: number;
	priceDiscount?: number;
	pricePurchase?: number;
	pricePurchaseVatRate?: number;
	cartMinimalQuantity?: number;
	cartMaximalQuantity?: number;
	weight?: number;
	dimensionWidth?: number;
	dimensionLength?: number;
	dimensionHeight?: number;
	ordinalNumber?: number;
	www?: string;
	domains?: number[];
	categories?: number[];
	parameters?: number[];
	customerGroups?: number[];
	shipments?: number[];
	relatedAny?: ProductRelated[]; // type: 0
	relatedAlternative?: ProductRelated[]; // type: 1
	relatedAccessory?: ProductRelated[]; // type: 2
	relatedSpareParts?: ProductRelated[]; // type: 3
	priceLists?: ProductPriceList[];
	volumeDiscounts?: ProductVolumeDiscount[];
	stocks?: ProductStock[];
	tags?: ProductTag[];
	externalCatalogs?: ProductExternalCatalog[];
	files?: ProductFile[];
	translations?: ProductTranslation[];
	other?: ProductOtherInfo;
}

export interface ProductOtherInfo {
	batchIds?: number[];
	translationName?: string;
}

export interface ProductTranslation {
	productId?: number;
	languageId?: number;
	url?: string;
	name?: string;
	descriptionShort?: string;
	descriptionLong?: string;
	properties?: ProductTranslationProperty[];
	seoTitle?: string;
	seoDescription?: string;
}

export interface ProductTranslationProperty {
	name?: string;
	value?: string;
}

export interface ProductFile {
	id?: number;
	productId?: number;
	fileId?: number;
	type?: number; // 0 = obrázok, 1 = súbory na stiahnutie...
	ordinalNumber?: number;
	coverInList?: boolean;
	coverInDetail?: boolean;
	translations?: ProductFileTranslation[];
	other?: ProductFileOtherInfo;
}

export interface ProductFileOtherInfo extends FileBase {
	translationName?: string;
}

export interface ProductFileTranslation {
	productFileId?: number;
	languageId?: number;
	name?: string;
}

export interface ProductRelated {
	productIdRelated?: number;
	productCode?: string;
	productName?: string;
}

export interface ProductPriceList {
	productId?: number;
	priceListId?: number;
	price?: number;
	discount?: number;
	cartMinimalQuantity?: number;
	cartMaximalQuantity?: number;
}

export interface ProductVolumeDiscount {
	productId?: number;
	priceListId?: number;
	minimalQuantity?: number;
	discount?: number;
}

export interface ProductStock {
	productId?: number;
	stockId?: number;
	stockDivisionId?: number;
	stockAvailabilityId?: number;
	quantity?: number;
}

export interface ProductExternalCatalog {
	productId?: number;
	externalCatalogId?: number;
	cpc?: number;
}

export interface ProductTag {
	productId?: number;
	tagId?: number;
	validDateFrom?: Date;
	validDateTo?: Date;
}

export interface ResponseData {
	success: boolean;
	data?: any;
}

/*
export interface Protocol {
	date?: any;
	note?: string;
}

export interface Review {
	id?: number;
	domainId?: number;
	parentId?: number;
	parentType?: number;
	customerId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	approvedDate?: any;
	approvedUserId?: number;
	approved?: boolean;
	rate?: number;
	name?: string;
	pros?: string;
	cons?: string;
	note?: string;
}
*/

export interface SelectItem {
	id: number;
	name: string;
}

export interface Setting {
	settingTypeId?: number;
	settingTypeKey?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	text?: string;
	int?: number;
	datetime?: any;
	bit?: boolean;
	decimal?: number;
}

/*
export interface SettingJson {
	settingTypeId?: number;
	settingTypeKey?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	value?: string;
}

export interface SettingType {
	id?: number;
	name?: string;
}
*/

export interface Shipment extends CustomFieldBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	type?: number;
	ordinalNumber?: number;
	fileId?: number;
	note?: string;
	trackingUrl?: string;
	minimalPrice?: number;
	maximalPrice?: number;
	minimalWeight?: number;
	maximalWeight?: number;
	feeType?: number;
	feeValue?: number;
	feeFreeFrom?: number;
	feeVatRate?: number;
	settings?: ShipmentSetting[];
	domains?: number[];
	countries?: number[];
	countriesBlacklist?: number[];
	priceLists?: number[];
	customerGroups?: number[];
	payments?: number[];
	translations?: ShipmentTranslation[];
	other?: ShipmentOtherInfo;
}

export interface ShipmentOtherInfo extends FileBase {
	translationName?: string;
}

export interface ShipmentCountry {
	shipmentId?: number;
	countryId?: number;
	whitelist?: boolean;
}

export interface ShipmentDomain {
	shipmentId?: number;
	domainId?: number;
}

export interface ShipmentPayment {
	shipmentId?: number;
	paymentId?: number;
}

export interface ShipmentPriceList {
	shipmentId?: number;
	priceListId?: number;
}

export interface ShipmentTranslation {
	shipmentId?: number;
	languageId?: number;
	name?: string;
	note?: string;
}

export interface ShipmentType {
	id?: number;
	name?: string;
	settings?: ShipmentTypeSetting[];
}

export interface ShipmentTypeSetting {
	uid?: string;
	name?: string;
}

export interface ShipmentSetting {
	uid?: string;
	value?: string;
}

export interface Slideshow {
	id?: number;
	widget?: string;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	contentCategoryId?: number;
	name?: string;
	note?: string;
	items?: SlideshowItem[];
	other?: SlideshowOtherInfo;
}

export interface SlideshowOtherInfo {
	contentCategoryName?: string;
}

export interface SlideshowItem {
	id?: number;
	slideshowId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	fileId?: number;
	ordinalNumber?: number;
	url?: string;
	name?: string;
	description?: string;
	other?: SlideshowItemOtherInfo;
}

export interface SlideshowItemOtherInfo extends FileBase {

}

export interface Stock extends CustomFieldBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	ordinalNumber?: number;
	name?: string;
	address?: string;
	note?: string;
	divisions?: StockDivision[];
}

export interface StockDivision {
	id?: number;
	parentId?: number;
	stockId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	name?: string;
	note?: string;
}

export interface StockAvailability {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	type?: number;
	ordinalNumber?: number;
	dispatchTime?: number;
	deliveryTime?: number;
	color?: string;
	fileId?: number;
	translations?: StockAvailabilityTranslation[];
	other?: StockAvailabilityOtherInfo;
}

export const StockAvailabilityTypes: StockAvailabilityType[] = [
	{ id: 0, name: 'Vlastné' },
	{ id: 1, name: 'Skladom' },
	{ id: 2, name: 'Nie je na sklade' },
	{ id: 3, name: 'Na dopyt' }
];

export interface StockAvailabilityType {
	id?: number;
	name?: string;
}

export interface StockAvailabilityOtherInfo extends FileBase {
	translationName?: string;
}

export interface StockAvailabilityTranslation {
	stockAvailabilityId?: number;
	languageId?: number;
	name?: string;
	note?: string;
}

export interface Tag extends CustomFieldBase {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	system?: boolean;
	fileId?: number;
	type?: number;
	color?: string;
	ordinalNumber?: number;
	showOnPage?: boolean;
	showAsPage?: boolean;
	translations?: TagTranslation[];
	other?: TagOtherInfo;
}

export const TagTypes: TagType[] = [
	{ id: 0, name: 'Vlastné' },
	{ id: 1, name: 'Novinky' },
	{ id: 2, name: 'Akcie' },
	{ id: 3, name: 'Výpredaj' }
];

export interface TagType {
	id?: number;
	name?: string;
}

export interface TagTranslation {
	tagId?: number;
	languageId?: number;
	slug?: string;
	name?: string;
	note?: string;
}

export interface TagOtherInfo extends FileBase {
	translationName?: string;
}

export interface TemplateEmail {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	name?: string;
	translations?: TemplateEmailTranslation[];
	files?: TemplateEmailFile[];
}

export interface TemplateEmailFile {
	templateEmailId?: number;
	fileId?: number;
	name?: string;
	fileName?: string;
}

export interface TemplateEmailTranslation {
	templateEmailId?: number;
	languageId?: number;
	subject?: string;
	template?: string;
}

export interface TemplateReport {
	id?: number;
	documentTypeId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	default?: boolean;
	translations?: TemplateReportTranslation[];
	other?: TemplateReportOtherInfo;
}

export interface TemplateReportOtherInfo {
	translationName?: string;
	documentType?: string;
	documentTypeSale?: boolean;
}

export interface TemplateReportTranslation {
	templateReportId?: number;
	languageId?: number;
	name?: string;
	fileName?: string;
	template?: string;
}

export interface TemplateSms {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	name?: string;
	translations?: TemplateSmsTranslation[];
}

export interface TemplateSmsTranslation {
	templateSmsId?: number;
	languageId?: number;
	template?: string;
}

export interface Unit {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	system?: boolean;
	ordinalNumber?: number;
	translations?: UnitTranslation[];
	other?: UnitOtherInfo;
}

export interface UnitOtherInfo {
	translationName?: string;
}

export interface UnitTranslation {
	unitId?: number;
	languageId?: number;
	name?: string;
}

export interface User extends CustomFieldBase {
	id?: number;
	userRoleId?: number;
	customerId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	email?: string;
	password?: string;
	name?: string;
	note?: string;
	other?: UserOtherInfo;
}

export interface UserOtherInfo {
	userRoleName?: string;
	customerName?: string;
}

export interface UserRole {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	superAdmin?: boolean;
	name?: string;
	note?: string;
	items?: UserRoleItem[];
}

export interface UserRoleItem {
	userRoleId?: number;
	userRoleListId?: number;
	editable?: boolean;
}

export interface UserRoleList {
	id?: number;
	name?: string;
	ordinalNumber?: number;
}

export interface VatRate {
	id?: number;
	countryId: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	activeFrom?: Date;
	vatRateHigh?: number;
	vatRateLow?: number;
	vatRateLow2?: number;
	vatRateLow3?: number;
	note?: string;
	other?: VatRateOtherInfo;
}

export interface VatRateOtherInfo {
	countryName?: string;
}

export interface VatRateSettings {
	defaultVatRate: number;
	calculationVatMethod: number; // Výpočet DPH na doklade (0 = DPH počítať pri každej položke, 1 = DPH počítať z celkovej sumy)
}

export interface VolumeDiscount {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	minimalPrice?: number;
	discount?: number;
	type?: number;		// 0 = absolútna hodnota napr. v EUR, 1 = relatívna hodnota v %
	domains?: number[];
	customerGroups?: number[];
	other?: VolumeDiscountOtherInfo;
}

export interface VolumeDiscountOtherInfo {
	domainsName?: string;
	customerGroupsName?: string;
}


export interface Voucher {
	id?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	name?: string;
	discount?: number;
	type?: number;
	disposable?: boolean;
	validDateFrom?: any;
	validDateTo?: any;
	minimalPrice?: number;
	note?: string;
	domains?: number[];
	categories?: number[];
	customerGroups?: number[];
	products?: VoucherProduct[];
	productsBlacklist?: VoucherProduct[];
	codes?: VoucherCode[];
	other?: VoucherOther;
}

export interface VoucherOther {
	codes?: number;
	orders?: number;
}

export interface VoucherProduct {
	productId?: number;
	productCode?: string;
	productName?: string;
}

export interface VoucherCode {
	id?: number;
	voucherId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	code?: string;
	other?: VoucherCodeOther;
}

export interface VoucherCodeOther {
	orders?: number;
}

export interface Watermark {
	full?: WatermarkSettings;
	medium?: WatermarkSettings;
}

export interface WatermarkSettings {
	actived?: boolean;
	fileId?: number;
	position?: number;
	size?: number;
	opacity?: number;
	file?: File;
}

/*á
export interface Watchdog {
	id?: number;
	domainId?: number;
	customerId?: number;
	productId?: number;
	createdDate?: Date;
	updatedDate?: Date;
	deletedDate?: Date;
	deleted?: boolean;
	actived?: boolean;
	email?: string;
	emailVerifiedDate?: any;
	emailVerified?: boolean;
	stock?: boolean;
	price?: number;
}
*/

export interface WebPage {
	id?: number;
	domainId?: number;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	activedDateFrom?: any;
	activedDateTo?: any;
	url?: string;
	templateMode?: number;
	visibility?: number;
	password?: string;
	seoIndex?: boolean;
	seoHeadline?: string;
	seoTitle?: string;
	seoDescription?: string;
	blocks?: WebPageBlock[];
	other?: WebPageOtherInfo;
}

export interface WebPageOtherInfo {
	domainName?: string;
}

export const WebPageVisibilityTypes: WebPageVisibilityType[] = [
	{ id: 0, name: 'Všetkým' },
	{ id: 1, name: 'Prihláseným' },
	{ id: 2, name: 'Neprihláseným' },
	{ id: 3, name: 'Vstup na heslo' }
];

export interface WebPageVisibilityType {
	id?: number;
	name?: string;
}

export interface WebPageBlock {
	id?: number;
	webPageId?: number;
	ordinalNumber?: number;
	area?: number;
	module?: number;
	content?: number;
	showOnAllPages?: boolean;
}

export interface WebPageContent {
	id?: number;
	actived?: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	title?: string;
}

// Číselník modulov
export enum WebPageBlockModuleType {
	None = 0,
	Article = 1,
	Articles = 2,
	Gallery = 3,
	Slideshow = 4,
	Form = 5,
	Menu = 6,
	Registration = 7,
	Login = 8,
	Categories = 9,
	Products = 10,
	Orders = 11,
	Cart = 12,
	// Popup = 13, -- Vkladá sa automaticky
	Dynamic = 14
}

// Predpis nastavení pre daný typ modulu
export interface WebPageBlockModuleTypeSettings {
	type?: WebPageBlockModuleType; // typ modulu
	title?: string; // názov modulu
	selectable?: boolean; // možnosť výberu obsahu
	editableUrl?: string; // možnosť úpravy obsahu (ak nie je nastavená URL, nie je možnosť úpravy)
}

// Zoznam modulov s potrebnými nastaveniami
export const WebPageBlockModules: WebPageBlockModuleTypeSettings[] = [
	{ type: WebPageBlockModuleType.None, title: 'Žiadny', selectable: false, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Article, title: 'Článok', selectable: true, editableUrl: '/articles/' },
	{ type: WebPageBlockModuleType.Articles, title: 'Zoznam článkov', selectable: true, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Gallery, title: 'Galéria', selectable: true, editableUrl: '/galleries/' },
	{ type: WebPageBlockModuleType.Slideshow, title: 'Prezentácia', selectable: true, editableUrl: '/slideshows/' },
	{ type: WebPageBlockModuleType.Form, title: 'Formulár', selectable: true, editableUrl: '/forms/' },
	{ type: WebPageBlockModuleType.Menu, title: 'Navigácia', selectable: true, editableUrl: '/web-structure/' },
	{ type: WebPageBlockModuleType.Registration, title: 'Registrácia', selectable: false, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Login, title: 'Prihlásenie', selectable: false, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Categories, title: 'Kategórie', selectable: true, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Products, title: 'Produkty', selectable: true, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Orders, title: 'Objednávky', selectable: false, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Cart, title: 'Nákupný košík', selectable: true, editableUrl: undefined },
	{ type: WebPageBlockModuleType.Dynamic, title: 'Dynamický obsah', selectable: false, editableUrl: undefined },
];

export interface WebStructure {
	id?: number;
	parentId?: number;
	widget?: string;
	createdDate?: Date;
	createdUserId?: number;
	updatedDate?: Date;
	updatedUserId?: number;
	deletedDate?: Date;
	deletedUserId?: number;
	deleted?: boolean;
	actived?: boolean;
	activedDateFrom?: any;
	activedDateTo?: any;
	ordinalNumber?: number;
	fileId?: number;
	categoryId?: number;
	name?: string;
	url?: string;
	target?: number;
	visibility?: number;
	other?: WebStructureInfo;
}

export interface WebStructureInfo extends FileBase {
	ordinalNumberLevel?: number;
	ordinalNumberUp?: boolean;
	ordinalNumberDown?: boolean;
}

export const WebStructureTargetTypes: WebStructureTargetType[] = [
	{ id: 0, name: 'Rovnaké okno' },
	{ id: 1, name: 'Nové okno' },
	{ id: 2, name: 'Vyskakovacie okno' }
];

export interface WebStructureTargetType {
	id?: number;
	name?: string;
}

export const WebStructureVisibilityTypes: WebStructureVisibilityType[] = [
	{ id: 0, name: 'Všetkým' },
	{ id: 1, name: 'Prihláseným' },
	{ id: 2, name: 'Neprihláseným' }
];

export interface WebStructureVisibilityType {
	id?: number;
	name?: string;
}

export interface WebStructureSettings {
	domains: WebStructureSettingsDomain[]; // Vetva hlavného menu pre danú doménu
}

export interface WebStructureSettingsDomain {
	id?: number;
	webStructureId?: number;
}