export const NullIf = (value: any, valueCompare: any): any => {
    if (value === valueCompare) {
        return value;
    }
    return null;
};

export const ConvertToInt = (value: any): number | undefined => {
    var result: number | undefined = 0;
    try {
        result = parseInt((value.toString() ?? '').replace(/[^\d-]/g, ''));
    }
    catch (e) { result = 0; }
    if (isNaN(result)) {
        result = undefined;
    }
    return result;
};

export const ConvertToDecimal = (value: any): number | undefined => {
    var result: number | undefined = 0;
    try {
        result = parseFloat((value.toString() ?? '').replace(/[,]/g, '.').replace(/[^\d.-]/g, ''));
    }
    catch (e) { result = 0; }
    if (isNaN(result)) {
        result = undefined;
    }
    return result;
};

// Zaokruhlenie na dve desatiny - zaokruhluje vždy kladné číslo a po zaokruhlení pridá pôvodné znamienko (číslo 5 ide nahor, pri zápornom je nahor k nule, takže: 0.025 = 0.03 a -0.025 = -0.02)
export const MathRound2 = (value: number) => Math.sign(value) * Math.round(Math.abs(value) * 100) / 100;

// Zaokruhlenie na štyri desatiny - zaokruhluje vždy kladné číslo a po zaokruhlení pridá pôvodné znamienko (číslo 5 ide nahor, pri zápornom je nahor k nule, takže: 0.025 = 0.03 a -0.025 = -0.02)
export const MathRound4 = (value: number) => Math.sign(value) * Math.round(Math.abs(value) * 10000) / 10000;

export const ConvertToDecimalOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Pomocná funkcia pre vstupné pole, keďže natívne type=number nepodporuje desatinnú čiarku
    // -----------------------------------
    // Input musí vyzerať nasledovne: 
    // <TextField type="text" onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }} inputProps={{ onInput: ConvertToDecimalOnKeyDown }} />

    // Problémy a provizórne riešenia:
    // -----------------------------------
    // Ak vykonám "preventDefault", tak nedokážem znovu vyvolať onChange po ručnej zmene hodnoty
    // Ak by som aj vyvolal "onChange" v nedokončenej hodnote, tak konvertovanie na decimal odstráni čiarku na konci, alebo nulu pri desatinnom čísle
    // Budem teda vykonávať "preventDefault" vtedy ak číslo končí čiarkou, alebo nulou a súčasne obsahuje desatiny
    // Ak stlačím mínus, tak vynulujem text, aby som donútil užívateľa pokračovať v písaní čísel a tak vyvolal onChange
    // Android Chrome úplne ignoruje .preventDefault(), preto si v prvom vlákne ziskam akutálne hodnoty a v druhom cez setTimeout použijem zmeny v prípade výnimiek

    // Testovacie scenáre (ošetrené)
    // -----------------------------------
    // -0,00505 (postupne písať)
    // 123456 (napísať a následne medzi 3 a 4 vložiť desatinnú čiarku)
    // 123456 (napísať a následne na začiatok  vložiť mínus)
    // 123456 (napísať a následne označiť 45 a stlačiť desatinnú čiarku)

    // Testovacie scenáre (neošetrené)
    // -----------------------------------
    // 1.005 (napísať číslo a následne cez backspace odstrániť 5 na konci, vymažú sa všetky nuly)

    // Opozdenie, aby sa najskôr vykonal bežný event a potom sa umelo pridali chybajúce znaky
    let delay = 0;

    // Zachytím premenné v hlavnom vlákne
    let currentTarget = e.currentTarget;
    let currentTargetValue = currentTarget.value.replace(/[,]/g, '.');
    let currentTargetSelectionStart = (currentTarget.selectionStart ?? 0);

    //let eKey = e.key ?? '';
    let eKey = currentTargetValue.substring(currentTargetSelectionStart - 1, currentTargetSelectionStart);

    /*
    console.log('------------------------------------------------------------------------');
    console.log('eKey', eKey);
    console.log('currentTargetValue', currentTargetValue);
    console.log('ConvertToDecimalOnKeyDownKey', e.key);
    console.log('ConvertToDecimalOnKeyDownEvent', e);
    console.log('currentTargetSelectionStart', currentTargetSelectionStart);
    */

    // Vynútim zachovanie pôvodnej hodnoty s pozíciou kurzora (mohol som doplniť znak v strete textu)
    let forceValue = false;

    // Ak som pridal bodku a nevyskytuje sa viac ako jeden krát (idem písať desatinné číslo)
    if (eKey === '.' && currentTargetValue.split('.').length === 2) {
        forceValue = true;
    }

    // Ak som na koniec desatinného čísla pridal nulu (idem písať desatinné číslo s viac nulami, ktoré by sa konvertom orezali)
    if (eKey === '0' && currentTargetValue.includes('.') && currentTargetSelectionStart === currentTargetValue.length) {
        forceValue = true;
    }

    // Ak som pridal minús na začiatok (idem písať záporné číslo)
    if (eKey === '-' && currentTargetSelectionStart === 1) {
        forceValue = true;
    }

    // Ak som za mínús pridal nulu (idem písať záporné desatinné číslo)
    if (eKey === '0' && currentTargetValue === '-0' && currentTargetSelectionStart === currentTargetValue.length) {
        forceValue = true;
    }

    // Vynútim zachovať zadanú hodnotu
    if (forceValue) {
        setTimeout(() => {
            currentTarget.value = currentTargetValue;
            currentTarget.setSelectionRange(currentTargetSelectionStart, currentTargetSelectionStart);
        }, delay);
        return;
    }
};

export const ConvertToDecimalOnKeyDownChybaAndroid = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    // Pomocná funkcia pre vstupné pole, keďže natívne type=number nepodporuje desatinnú čiarku
    // -----------------------------------
    // Input musí vyzerať nasledovne: 
    // <TextField type="text" onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }} inputProps={{ onInput: ConvertToDecimalOnKeyDown }} />

    // Problémy a provizórne riešenia:
    // -----------------------------------
    // Ak vykonám "preventDefault", tak nedokážem znovu vyvolať onChange po ručnej zmene hodnoty
    // Ak by som aj vyvolal "onChange" v nedokončenej hodnote, tak konvertovanie na decimal odstráni čiarku na konci, alebo nulu pri desatinnom čísle
    // Budem teda vykonávať "preventDefault" vtedy ak číslo končí čiarkou, alebo nulou a súčasne obsahuje desatiny
    // Ak stlačím mínus, tak vynulujem text, aby som donútil užívateľa pokračovať v písaní čísel a tak vyvolal onChange

    // Testovacie scenáre (ošetrené)
    // -----------------------------------
    // -0,50005 (postupne písať)
    // 123456 (napísať a následne medzi 3 a 4 vložiť desatinnú čiarku)
    // 123456 (napísať a následne medzi vložiť mínus)
    // 123456 (napísať a následne označiť 45 a stlačiť desatinnú čiarku)

    // Testovacie scenáre (neošetrené)
    // -----------------------------------
    // 1.005 (napísať číslo a následne cez backspace odstrániť 5 na konci, vymažú sa všetky nuly)

    // Ak stlačím mínus, tak vynulujem text a ďalej nepokračujem
    // Donútim užívateľa dopísať ďalšie číslo, ktoré vyvolá onChange a zapíše záporné číslo
    if (e.key === '-') {
        e.preventDefault();
        e.currentTarget.value = '-';
        return;
    }

    // Ak stlačím desatinnú čiarku alebo bodku, tak orežem text po bodku a ďalej nepokračujem
    // Donútim užívateľa dopísať ďalšie číslo, ktoré vyvolá onChange a zapíše desatiny
    if (e.key === '.' || e.key === ',') {
        e.preventDefault();
        var selectionStart = (e.currentTarget.selectionStart ?? 0);
        e.currentTarget.value = e.currentTarget.value.substring(0, selectionStart) + '.';
        e.currentTarget.setSelectionRange(selectionStart + 1, selectionStart + 1);
        return;
    }

    // Ak na koniec desatinného čísla pridávam nulu, tak vyvolám preventDefault a ručne ju dopíšem (konvertovanie by nulu z desatinného čísla odstránilo)
    // Ďalšie číslo spustí onChange (ak nejde o ďalšiu nulu), ktoré zapíše záporné číslo
    if (e.key === '0' && e.currentTarget.value.includes('.') && (e.currentTarget.selectionStart ?? 0) === e.currentTarget.value.length) {
        e.preventDefault();
        e.currentTarget.value += '0';
    }

    // Ak za mínús pridávam nulu, tak vyvolám preventDefault a ručne ju dopíšem (konvertovanie by nulu zo záporného desatinného čísla odstránilo)
    // Ďalšie číslo spustí onChange (ak nejde o ďalšiu nulu), ktoré zapíše záporné číslo
    if (e.key === '0' && e.currentTarget.value === '-' && (e.currentTarget.selectionStart ?? 0) === e.currentTarget.value.length) {
        e.preventDefault();
        e.currentTarget.value += '0';
    }
};