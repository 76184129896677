import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { AppConfig } from '../../AppConfig';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// Utility
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import skLocale from 'date-fns/locale/sk';
import { format as dateFormat } from 'date-fns';
import { FormatCurrency } from '../../utility/Format';
import { ConvertToDecimal, ConvertToDecimalOnKeyDown, MathRound2 } from '../../utility/Number';
import { NullMinDate } from '../../utility/Date';
import addDays from 'date-fns/addDays';

// Modely
import { Country, Currency, Domain, Document, DocumentItem, DocumentItemsDiscountCompute, DocumentItemType, DocumentState, Parameter, Payment, PriceList, ResponseData, Shipment, Unit, VolumeDiscount, CustomerBase, Customer, CustomerBaseFromCustomer, DocumentItemsSetZeroVatRate, DocumentType, DocumentCopy, ExternalDocumentType, DocumentItemCompute } from '../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, Box, Snackbar, IconButton, Accordion, AccordionDetails, AccordionSummary, Tab, Tabs, Typography, FormControl, InputLabel, MenuItem, Select, InputAdornment, TextField, FormControlLabel, Switch, Table, TableBody, TableCell, TableRow, ButtonGroup, Divider, ListItemIcon, Menu, Chip } from '@mui/material';
import Grid from '@mui/material/Grid';
import { DatePicker, TabContext, TabPanel } from '@mui/lab';
import CustomerBaseComponent, { CustomerBaseComponentAction } from '../customer_base/CustomerBaseComponent';
import DocumentCreateItems from './DocumentCreateItems';
import DocumentCreateHistory from './DocumentCreateHistory';
import DocumentCreateNotes from './DocumentCreateNotes';
import DocumentCreatePayments from './DocumentCreatePayments';
import DocumentCreateSummary from './DocumentCreateSummary';
import DocumentCreateRelated from './DocumentCreateRelated';
import DocumentCreateRelatedExternal from './DocumentCreateRelatedExternal';
import Confirm, { ConfirmProps } from '../../components/Confirm';
import CustomFieldValues from '../settings/custom_field/CustomFieldValues';
import DocumentCreateFiles from './DocumentCreateFiles';

// Ikony
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NumbersIcon from '@mui/icons-material/Numbers';
import EuroIcon from '@mui/icons-material/Euro';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContactsIcon from '@mui/icons-material/Contacts';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): Document => ({
    id: 0,
    documentTypeId: 0,
    done: false,
    paid: false,
    number: '',
    documentStateId: 0,
    paymentId: 0,
    shipmentId: 0,
    trackingNumber: '',
    symbolVariable: '',
    symbolSpecific: '',
    symbolConstant: '',
    currencyCode: '',
    currencyExchange: 0,
    priceTotalNoVat: 0,
    priceTotalVatAmount: 0,
    customer: {},
    owner: {},
    items: [],
    notes: [],
    payments: [],
    history: []
});

// Spoločné vstupné parametre pre vnorené komponenty (aby som nemusel pri každom rozpisovať všetko spoločné)
export interface DocumentCreateComponentProps {
    open: boolean;
    source: Document;
    vatRates: number[];
    vatRateDefault: number;
    vatCalculationMethod: number;
    priceIncludeVat: boolean;
    customerDiscount: number;
    languageDefaultId: number;
    priceList: PriceList;
    currency: Currency;
    autoFocus?: string;
    setSource: (action: React.SetStateAction<Document>) => void;
    setFocus: (input: any) => void;
    onChange: (property: string, value: any) => void;
    setLoading?: (active: boolean) => void;
};

// Možnosti pri uložení
export enum DocumentCreateSaveAction {
    SaveAndContinue,
    SaveAndNew,
    SaveAndNewFromCopy,
    SaveAndPrint
}

// Vstupné parametre
export interface DocumentCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    document?: Document; // Predvyplnený doklad, ktorý sa aplikuje na emptySource
    documentType: DocumentType;
    documentTypes: DocumentType[];
    autoFocus?: string;
    keepMounted?: boolean;
    disableSaveAction?: boolean;        // Vypne rozšírené akcie po uložení
    onSave?: (id?: number, action?: DocumentCreateSaveAction) => void;
    onClose: () => void;
}

const DocumentCreate = (props: DocumentCreateProps) => {

    // Globálne premenné
    const appContext = useContext(AppContext);

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [confirm, setConfirm] = useState<ConfirmProps>({ open: false, title: '', children: null });
    const [tabValue, setTabValue] = useState<string>('items');
    const [source, setSource] = useState<Document>(EmptySource());
    const [volumeDiscountsLastUsed, setVolumeDiscountsLastUsed] = useState<number | undefined>(undefined); // naposledy použitá zľava, zabraňuje neželanej opakovanej kontrole
    const [saveMenuEl, setSaveMenuEl] = useState<null | HTMLElement>(null);
    const [customerBaseAction, setCustomerBaseAction] = useState<undefined | CustomerBaseComponentAction>(undefined);

    // Zákazník
    const [customerDiscount, setCustomerDiscount] = useState<number>(0); // aktuálna zľava zákazníka, premieta sa automaticky na ceny nových položiek
    const [customerNewSelected, setCustomerNewSelected] = useState<number>(0); // používa sa pre automatické vyvolanie zmien po výbere NOVÉHO partnera, aby sa nevyvolali pri naplnení source.customer.customerId 

    // Sledovanie zmien - aktivované oneskorene, aby sa stihol zmeniť stav "source"
    const [sourceChanged, setSourceChanged] = useState<boolean | undefined>(undefined);
    const setSourceStartWatch = () => { setTimeout(() => { setSourceChanged(false); }, 250); };
    const setSourceStopWatch = () => { setSourceChanged(undefined); };
    useEffect(() => {
        if (sourceChanged === false) {
            setSourceChanged(true);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Stav číselníky
    const [domains, setDomains] = useState<Domain[]>([]);
    const [documentStates, setDocumentStates] = useState<DocumentState[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [shipments, setShipments] = useState<Shipment[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [currency, setCurrency] = useState<Currency>({}); // Hlavná systémová mena
    const [countries, setCountries] = useState<Country[]>([]);
    const [parameters, setParameters] = useState<Parameter[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [vatRates, setVatRates] = useState<number[]>([]);
    const [vatRateDefault, setVatRateDefault] = useState<number>(0);
    const [vatCalculationMethod, setVatCalculationMethod] = useState<number>(0);
    const [priceIncludeVat, setPriceIncludeVat] = useState<boolean>(false);
    const [languageDefaultId, setLanguageDefaultId] = useState<number>(0);
    const [tagDiscount, setTagDiscount] = useState<number>(0);
    const [volumeDiscounts, setVolumeDiscounts] = useState<VolumeDiscount[]>([]);
    const [priceLists, setPriceLists] = useState<PriceList[]>();
    const [priceList, setPriceList] = useState<PriceList>();
    const [billingCustomer, setBillingCustomer] = useState<CustomerBase>();
    const [externalDocumentTypes, setExternalDocumentTypes] = useState<ExternalDocumentType[]>([]);

    // Stav harmonika
    const [accordionExpanded, setAccordionExpanded] = useState<string[]>([]);

    // Funkcie pre získanie informácie o tom, či je harmonika otvorená a zmenu stavu
    const isAccordionExpanded = (name: string): boolean => {
        return accordionExpanded.includes(name);
    };
    const handleExpandAccordion = (name: string) => {
        if (!isAccordionExpanded(name)) {
            handleToggleAccordion(name);
        }
    };
    const handleToggleAccordion = (name: string, single?: boolean) => {
        if (single === undefined || single === true) {
            if (isAccordionExpanded(name)) {
                setAccordionExpanded([]);
            } else {
                setAccordionExpanded([name]);
            }
        }
        else {
            if (isAccordionExpanded(name)) {
                setAccordionExpanded(prev => prev.filter(item => item !== name));
            } else {
                setAccordionExpanded(prev => [...prev, name]);
            }
        }
    };

    // Reset formuláru
    const handleReset = (keepId?: boolean) => {
        setCustomerDiscount(0);
        setCustomerNewSelected(0);
        setVolumeDiscountsLastUsed(undefined);
        setSource(prev => {
            let empty = {
                ...EmptySource(), // ako prvé
                id: (keepId ?? false) === true ? prev.id : 0,
                documentTypeId: props.documentType.id ?? 0,
                // priceVatRate: vatRateDefault,
                // pricePurchaseVatRate: vatRateDefault,
                documentStateId: documentStates[0]?.id ?? 0,
                shipmentId: shipments[0]?.id ?? 0,
                paymentId: payments[0]?.id ?? 0,
                currencyCode: (currencies.find(currency => currency.system === true)?.code ?? ''),
                currencyExchange: 1,
                owner: { ...billingCustomer },
                ...props.document ?? {}, // ako posledné
            } as Document;

            // Automaticky predvyplním dátum (ak je nastavené 0 a viac dní, -1 je vypnuté)
            if ((props.documentType.fillDateOfIssue ?? -1) >= 0) {
                empty.dateOfIssue = addDays(new Date(), (props.documentType.fillDateOfIssue ?? 0));
            }
            if ((props.documentType.fillDateOfTaxLiability ?? -1) >= 0) {
                empty.dateOfTaxLiability = addDays(new Date(), (props.documentType.fillDateOfTaxLiability ?? 0));
            }
            if ((props.documentType.fillDateOfDue ?? -1) >= 0) {
                empty.dateOfDue = addDays(new Date(), (props.documentType.fillDateOfDue ?? 0));
            }
            if ((props.documentType.fillDateOfDelivery ?? -1) >= 0) {
                empty.dateOfDelivery = addDays(new Date(), (props.documentType.fillDateOfDelivery ?? 0));
            }
            if ((props.documentType.fillDateOfExpiration ?? -1) >= 0) {
                empty.dateOfExpiration = addDays(new Date(), (props.documentType.fillDateOfExpiration ?? 0));
            }
            return empty;
        });
        setSourceStartWatch();
    };
    const handleResetConfirm = (keepId?: boolean) => {
        setConfirm(prev => ({
            ...prev, open: true, title: 'Reset', children: 'Skutočne chcete resetovať celý formulár?', onConfirm: () => {
                setConfirm(prev => ({ ...prev, open: false }));
                handleReset(keepId);
            }
        }));
    }

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            if (tabValue !== 'items') {
                setTabValue('items');
            }
            // Zavriem všetky harmoniky, okrem vybraných
            setAccordionExpanded(['basic']);
            setSourceStopWatch();
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        handleReset();
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'document/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    let document = response.data as Document;

                    // Nedovolím zobraziť editáciu cudzieho typu dokladu
                    if ((document?.documentTypeId ?? 0) !== props.documentType?.id) {
                        props.onClose();
                        return;
                    }

                    // Naplním zdroj, keďže potrebujem zachovať pôvodné hodnoty, tak "deep copy" robím až vnútri zmeny stavu
                    setSource(prev => {
                        // Pri kópii zlúčim pôvodnú "empty" a triedu kópie, aby sa zachovali predvolené hodnoty z "empty"
                        if (props.copy) {
                            document = ({ ...prev, ...DocumentCopy([document], (appContext.userName ?? '')) });
                        }
                        return document;
                    });
                    setSourceStartWatch();
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Automaticky naplním vybraný cenník, ak sa zmení cenník alebo naplní zoznam cenníkov (cenníky v zozname obsahujú aj množstevné zľavy)
    useEffect(() => {
        if ((source.priceListId ?? 0) === 0 || (priceLists?.length ?? 0) === 0) {
            setPriceList(undefined);
            return;
        }
        setPriceList(priceLists?.find(item => item.id === source.priceListId));
    }, [source.priceListId, priceLists]);

    // Ak generujem nový doklad podľa ručne podhodeného dokladu a ten nemá nastavený stav = undefined, tak ho doplním automaticky po naplnení číselníku (v čase handleReset pri otvorení okna ešte nie je naplnený)
    // Aj nula znamená nastavený stav = žiadny, preto to reaguje na undefined
    useEffect(() => {
        if (props.document !== undefined && source.documentStateId === undefined && documentStates.length > 0 && (documentStates[0].documentTypeId ?? 0) === (props.documentType.id ?? 0)) {
            setSource(prev => ({ ...prev, documentStateId: documentStates[0]?.id ?? 0 }));
        }
    }, [documentStates, source.documentStateId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Funkcia pre naplnenie stavu číselníkov
    const applyFormData = (data: any) => {
        if (data.domain) {
            setDomains(data.domain);
        }
        if (data.documentstate) {
            setDocumentStates(data.documentstate);
        }
        if (data.payment) {
            setPayments(data.payment);
        }
        if (data.shipment) {
            setShipments(data.shipment);
        }
        if (data.currency) {
            setCurrencies(data.currency);
        }
        if (data.pricelist) {
            setPriceLists(data.pricelist);
        }
        if (data.country) {
            setCountries(data.country);
        }
        if (data.parameter) {
            setParameters(data.parameter);
        }
        if (data.unit) {
            setUnits(data.unit);
        }
        if (data.vatrate) {
            setVatRates(data.vatrate);
        }
        if (data.vatratesettings) {
            setVatRateDefault(data.vatratesettings.defaultVatRate ?? 0);
            setVatCalculationMethod(data.vatratesettings.calculationVatMethod ?? 0);
        }
        if (data.pricelistsettings) {
            setPriceIncludeVat(data.pricelistsettings.priceIncludeVat ?? false);
        }
        if (data.languagedefault) {
            setLanguageDefaultId(data.languagedefault ?? 0);
        }
        if (data.tagdiscount) {
            setTagDiscount(data.tagdiscount ?? 0);
        }
        if (data.volumediscount) {
            setVolumeDiscounts(data.volumediscount);
        }
        if (data.billingcustomer) {
            setBillingCustomer(CustomerBaseFromCustomer(data.billingcustomer as Customer));
        }
        if (data.externaldocumenttypes) {
            setExternalDocumentTypes(data.externaldocumenttypes);
        }
    };

    // Načítam dáta pre formulár jedným volaním
    const loadFormData = () => {
        if ((props.documentType.id ?? 0) === 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'document/formdata', {
                params: {
                    'documentTypeId': props.documentType.id
                }
            })
            .then(response => {
                if (response.data) {
                    // Aplikujem
                    applyFormData(response.data);
                }
            });
    };
    useEffect(() => loadFormData(), [props.documentType.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // História
    const handleChangeHistory = (property: string, value: any) => {
        // Pozor! 
        // V DEBUG režime sa kvôli render vyvoláva setTimeout 2x, preto sa stav zapíše dvojmo, v produkcii je to OK.
        if (property === 'documentStateId') {
            handleChangeHistorySave('Zmena stavu', (appContext.userName ?? '') + ' zmenil stav dokladu na: ' + (documentStates.find(state => state.id === value)?.other?.translationName ?? '') + '.');
            return;
        }
        if (property === 'paymentId') {
            handleChangeHistorySave('Zmena platby', (appContext.userName ?? '') + ' zmenil spôsob platby na: ' + (payments.find(payment => payment.id === value)?.other?.translationName ?? '') + '.');
            return;
        }
        if (property === 'shipmentId') {
            handleChangeHistorySave('Zmena dopravy', (appContext.userName ?? '') + ' zmenil spôsob dopravy na: ' + (shipments.find(shipment => shipment.id === value)?.other?.translationName ?? '') + '.');
            return;
        }
        if (property === 'done') {
            handleChangeHistorySave('Vybavené', (appContext.userName ?? '') + ' označil doklad ako ' + (value === false ? 'ne' : '') + 'vybavené.');
            return;
        }
        if (property === 'paid') {
            handleChangeHistorySave('Uhradené', (appContext.userName ?? '') + ' označil doklad ako ' + (value === false ? 'ne' : '') + 'uhradené.');
            return;
        }
        if (property === 'items-add') {
            handleChangeHistorySave('Nová položka', (appContext.userName ?? '') + ' pridal položku: ' + value + '.');
            return;
        }
        if (property === 'items-update') {
            handleChangeHistorySave('Zmena položky', (appContext.userName ?? '') + ' upravil položku: ' + value + '.');
            return;
        }
        if (property === 'items-update-list') {
            handleChangeHistorySave('Zmena položiek', (appContext.userName ?? '') + ' upravil položky: ' + value + '.');
            return;
        }
        if (property === 'items-delete') {
            handleChangeHistorySave('Vymazanie položky', (appContext.userName ?? '') + ' vymazal položku: ' + value + '.');
            return;
        }
        if (property === 'customer-update') {
            handleChangeHistorySave('Zmena zákazníka', (appContext.userName ?? '') + ' upravil zákazníka: ' + value + '.');
            return;
        }
    };
    const handleChangeHistorySave = (name: string, note: string) => {
        setSource(prev => ({
            ...prev, history: [...prev.history ?? [], {
                createdDate: new Date(),
                name: name,
                note: note
            }]
        }));
    };

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        // Zápis histórie
        handleChangeHistory(property, value);
        // Zmena
        setSource(prev => {
            const item: Document = ({ ...prev, [property]: value });
            // Automatická úprava po zmene stavu
            if (property === 'documentStateId') {
                // Ak má vybraný stav dokladu označenú možnosť "označiť ako vybavené", tak automaticky označím vybavené = áno
                if ((documentStates.find(state => state.id === value)?.markAsDone ?? false) === true && (item['done'] ?? false) === false) {
                    item['done'] = true;
                    setTimeout(() => { handleChangeHistory('done', true); }, 500);
                }
                // Ak má vybraný stav dokladu označenú možnosť "označiť ako uhradené", tak automaticky označím uhradené = áno
                if ((documentStates.find(state => state.id === value)?.markAsPaid ?? false) === true && (item['paid'] ?? false) === false) {
                    item['paid'] = true;
                    setTimeout(() => { handleChangeHistory('paid', true); }, 500);
                }
            }
            // Automatická úprava po zmene meny
            if (property === 'currencyCode') {
                // Po zmene meny automaticky upravím kurz
                item['currencyExchange'] = currencies.find(currency => currency.code === value)?.exchange ?? 1;
            }
            return item;
        });
    }

    // Ak sa zmení číselník meny, tak automaticky získam hlavnú systémovú menu
    useEffect(() => {
        setCurrency((currencies.find(currency => currency.system === true) ?? {}));
    }, [currencies]);

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    // Funkcia pre vygenerovanie spoločných vstupných parametrov pre komponenty (aby som nemusel pri každom rozpisovať všetko spoločné, premenné hodnoty musím doplniť v rámci render)
    const componentProps = (): DocumentCreateComponentProps => {
        return {
            open: props.open,
            source: {},
            vatRates: [],
            vatRateDefault: 0,
            vatCalculationMethod: 0,
            priceIncludeVat: false,
            languageDefaultId: 0,
            customerDiscount: 0,
            priceList: {},
            currency: {},
            autoFocus: props.autoFocus,
            setSource: setSource,
            setFocus: setFocus,
            onChange: handleChange,
            setLoading: setLoading
        }
    };

    // Nastavenie nulovej sadzby DPH pre všetky položky (produkt, platba doprava) 
    // Pozor, hodnoty konfigurátora položky sa neprepočítavajú
    const handleSetZeroVatRate = () => {
        setSource(prev => {
            return ({ ...prev, items: DocumentItemsSetZeroVatRate(prev.items ?? []) });
        });
    };

    // Vložiť položku do zoznamu
    const handleItemAdd = (item: DocumentItem, itemIndex?: number) => {
        handleChangeHistory((itemIndex !== undefined && itemIndex >= 0 ? 'items-update' : 'items-add'), item.name);
        setSource(prev => {
            if (itemIndex !== undefined && itemIndex >= 0) {
                // Aktualizácia existujúceho záznamu
                const items = [...prev.items ?? []];
                items[itemIndex] = { ...item };
                return ({ ...prev, items: DocumentItemsDiscountCompute([...items ?? []]) });
            }
            else {
                // Nový záznam (automaticky doplním poradové číslo pre produkt +1)
                if (item.type === DocumentItemType.Product && (item.ordinalNumber ?? 0) === 0 && (prev.items?.length ?? 0) > 0) {
                    item.ordinalNumber = (Math.max(...(prev.items?.map(i => i.ordinalNumber ?? 0) ?? [])) ?? 0) + 1;
                }
                return ({ ...prev, items: DocumentItemsDiscountCompute([...prev.items ?? [], item]) });
            }
        });
    };

    // Vymazať položku zo zoznamu
    const handleItemDelete = (index: number) => {
        let itemName = (source.items ?? [])[index]?.name ?? '';
        handleChangeHistory('items-delete', itemName);
        setSource(prev => {
            const items = prev.items ?? [];
            return ({ ...prev, items: DocumentItemsDiscountCompute([...items.filter(item => item !== items[index]) ?? []]) });
        });
    };

    // Vymazať položku zo zoznamu
    const handleItemListDelete = (indexList: number[]) => {
        if (indexList.length === 0) {
            return;
        }
        setSource(prev => {
            const items = prev.items ?? [];
            const deleted: any[] = [...indexList.map((index) => items[index])];
            const changed = [...items.filter(row => !deleted.includes(row)) ?? []];
            return ({ ...prev, items: DocumentItemsDiscountCompute([...changed ?? []]) });
        });
    };

    // Aplikuje zľavy (alebo prirážky) "zľava na položku" na označené položky cez konfigurátor (aplikuje sa jednorázovo)
    const handleItemListDiscount = (indexList: number[], discountName: string, discountValue: number, discountRelative: boolean) => {
        if (indexList.length === 0) {
            return;
        }
        handleChangeHistory('items-update-list', 'Zľava na položku: ' + (discountRelative === true ? discountValue + ' %' : FormatCurrency(discountValue, currency, 2)) + ' (' + discountName + ')');

        // Prejdem každú jednu položku
        setSource(prev => {
            const items = prev.items ?? [];
            for (var index = 0; index < items.length; index++) {
                if (indexList.includes(index)) {
                    let item = items[index];
                    let itemPrice = (item.price ?? 0);
                    let itemDiscount = Math.abs(discountValue); // Pracujem vždy s kladnou hodnotou a až po výpočte nastavujem znamienko
                    let itemDiscountIsSurcharge: boolean = discountValue < 0; // Podľa toho či ide o zľavu alebo prirážku po vypočítaní zľavy nastavím znamienko
                    let itemName = itemDiscountIsSurcharge === false ? 'Zľava' : 'Prirážka';

                    // Zľavu na položku je možné použiť iba pre produkt
                    if (item.type !== DocumentItemType.Product) {
                        continue;
                    }

                    // Ak ide o relatívnu hodnotu v percentách, tak ju jednorázovo prepočítam
                    if (discountRelative === true) {
                        itemDiscount = MathRound2((itemPrice / 100) * discountValue);
                    }

                    // Zľava - opačné znamienko
                    if (itemDiscountIsSurcharge === false) {
                        itemDiscount = itemPrice >= 0 ? -Math.abs(itemDiscount) : Math.abs(itemDiscount);
                    }

                    // Prirážka - rovnaké znamienko
                    if (itemDiscountIsSurcharge === true) {
                        itemDiscount = itemPrice < 0 ? -Math.abs(itemDiscount) : Math.abs(itemDiscount);
                    }

                    item = {
                        ...item, configurator: [
                            ...(item?.configurator?.filter(c => c.uid !== 'itemdiscount') ?? []),
                            ...(itemDiscount !== 0 ? [{ uid: 'itemdiscount', name: itemName, value: discountName, price: itemDiscount }] : [])
                        ]
                    };
                    items[index] = { ...DocumentItemCompute(item) };
                }
            }
            return ({ ...prev, items: DocumentItemsDiscountCompute([...items ?? []]) });
        });
    };

    // Uloženie formuláru
    const handleSave = (event?: FormEvent<HTMLFormElement>, action?: DocumentCreateSaveAction) => {
        if (event !== undefined) {
            event.preventDefault();
            event.stopPropagation();
        }

        // Skontrolujem položky
        if ((source.items?.filter(item => item.type === 0)?.length ?? 0) === 0) {
            setWarning({ ...EmptyWarning, text: 'Musíte vložiť položky!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'document', source)
            .then(response => {
                if (response.data !== undefined) {
                    const responseData = response.data as ResponseData
                    if (!responseData.success) {
                        setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
                        return;
                    }
                    props.onClose();
                    if (props.onSave) {
                        props.onSave((responseData.data ?? 0) as number, action);
                    }
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické zistenie aktuálnej zľavy partnera po jeho zmene (aj pri editácii)
    useEffect(() => {
        setCustomerDiscount(0);
        if ((source.customer?.customerId ?? 0) === 0) {
            return;
        }
        axios
            .get(AppConfig.ApiUri + 'customer/discount/' + (source.customer?.customerId ?? 0))
            .then(response => {
                if (response.data !== null && response.data > 0) {
                    setCustomerDiscount(response.data as number);
                }
            });
    }, [source.customer?.customerId]);

    // Automatické zistenie cenníka vybraného nového partnera (nevyvolá sa pri edite, kde už spätne nemôžem zmeniť vybraný cenník na existujúcom doklade, iba ak by som nanovo vybral partnera)
    useEffect(() => {
        if ((customerNewSelected ?? 0) === 0) {
            return;
        }
        // Získam cenník v poradí podľa priority: nastavenie zákazníka, nastavenie zákazníckej skupiny zákazníka, nastavenie východzieho cenníku domény zákazníka (rieši priamo API)
        axios
            .get(AppConfig.ApiUri + 'pricelist/customer/' + (customerNewSelected ?? 0))
            .then(response => {
                if (response.data !== null) {
                    setSource(prev => ({ ...prev, priceListId: (response.data as PriceList).id ?? prev.priceListId ?? 0 }));
                }
            });

        // Vložím zákaznícku zľavu iba vtedy, ak ešte nefiguruje v zozname (spätne nemôžem aktualizovať, aby to neovplivnilo starý doklad)
        // Ak chcem vložiť aktuálnu zľavu nanovo, tak musím vloženú zľavu odstrániť a zrušiť reláciu so zákazníkom a znovu vybrať
        /*
        if (source.items?.find(item => item.type === DocumentItemType.DiscountRelative && item.code === 'customer') === undefined) {
            axios
                .get(AppConfig.ApiUri + 'customer/discount/' + (customerChanged ?? 0))
                .then(response => {
                    if (response.data !== null && response.data > 0) {
                        handleItemAdd({
                            id: 0,
                            type: DocumentItemType.DiscountRelative,
                            code: 'customer',
                            name: 'Zákaznícka zľava ' + response.data + '%',
                            quantity: 1,
                            unit: 'ks',
                            price: response.data as number,
                            priceCalc: 0,
                            priceIncludeVat: true,
                            priceVatRate: 0,
                            priceUnitNoVat: 0,
                            priceUnitVatAmount: 0,
                            priceTotalNoVat: 0,
                            priceTotalVatAmount: 0
                        })
                    }
                });
        }
        */

    }, [customerNewSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    // Automatická kontrola objemových zliav po každej zmene v položkách (kontrola z celkovej ceny bez aplikovaných zliav)
    // Pomocou volumeDiscountsLastUsed (naposledy použitá zľava) zabránim opakovanej neželanej kontrole pri:
    // - otvorení existujúceho dokladu (source.id > 0)
    // - otvorení ručne vyplneného dokladu s položkami (props.document !== undefined)
    // - ručnom zmazaní objemovej zľavy, kde nechcem aby sa zľava pridala automaticky kým znovu neprekročím inú objemovú hranicu (ak sa ale objemová zľava vymaže automaticky tak sa nemôže blokovať opätovná kontrola)
    useEffect(() => {

        // Ak sú položky prázdne, tak ďalej nepokračujem 
        if ((source?.items?.length ?? 0) === 0) {
            return;
        }

        // Nová objemová zľava
        let volumeDiscount: VolumeDiscount | undefined = undefined;

        // Aktuálna objemová zľava v položkách
        let volumeDiscountItem: DocumentItem | undefined = source?.items?.find(item => (item.code === 'volumediscount' && (item.type === DocumentItemType.Discount || item.type === DocumentItemType.DiscountRelative)));

        // Pri prvom zobrazení si poznačím aktuálne použitú objemovú zľavu, aby som vedel porovnať, či som zmenil hranicu
        if (volumeDiscountsLastUsed === undefined) {
            setVolumeDiscountsLastUsed(volumeDiscountItem?.price ?? 0);

            // Ďalej pokračujem iba pri novom doklade, keďže som práve vložil prvú položku
            // Ďalej nepokračujem pri: 
            // - otvorení existujúceho dokladu (source.id > 0)
            // - otvorení ručne vyplneného dokladu s položkami (props.document !== undefined)
            // Lebo by som automaticky vložil alebo vyhodil zľavu aj keď mohla byť zmazaná, ručne pridaná, alebo ju v tom čase ešte systém nedopočítal, ak tak sa pridá až pri ďalšej zmene v položkách
            if ((source.id ?? 0) > 0 || props.document !== undefined) {
                return;
            }
        }

        // Zoznam položiek bez objemovej zľavy, pre výpočet celkovej ceny (súčasne zoznam použijem na naplnenie stavu v prípade zmeny, aby som ušetril ďalšiu filtráciu)
        let itemsSource = source?.items?.filter(item => !(item.code === 'volumediscount' && (item.type === DocumentItemType.Discount || item.type === DocumentItemType.DiscountRelative))) ?? [];

        // Kontrolu vykonám iba ak existujú objemové zľavy a súčasne buď nie je vybraný žiadny zákaznícky cenník prázdny, alebo má vybraný zákaznícky cenník povolené objemové zľavy
        if (volumeDiscounts.length > 0 && ((priceList?.id ?? 0) === 0 || priceList?.allowVolumeDiscount === true)) {
            // Celková suma položiek, pracujem vždy s kladnou hodnotou, keďže na dobropise môže byť aj záporná (nie sú tu zarátané zľavy, skutočné hodnoty (jednotkové ceny) zliav figurujú ako podpoložky zľavových položiek)
            let itemsTotalPrice = Math.abs(itemsSource?.reduce((sum, current) => sum + ((current?.priceTotalNoVat ?? 0) + (current?.priceTotalVatAmount ?? 0)), 0) ?? 0);

            // Zoradím od najväčšej minimálnej ceny a následne nájdem prvú v poradí s podmienkou:
            // - ak je vybraná zákaznícka skupina, tak figurovať v zozname povolených skupín, alebo tento zoznam musí byť prázdny (pre všetko)
            // - ak je vybraná doména, tak figurovať v zozname povolených domén, alebo tento zoznam musí byť prázdny (pre všetko)
            // - musí mať menšiu alebo rovnakú minimálnu sumu ako je celková suma dokladu
            volumeDiscount = volumeDiscounts
                .sort((a, b) => (a.minimalPrice ?? 0) < (b.minimalPrice ?? 0) ? 1 : -1)
                .find(d =>
                    (
                        // - buď je zoznam prázdny (platí pre všetko), alebo musí obsahovať danú zákaznícku skupinu
                        (d.customerGroups?.length ?? 0) === 0 || ((source.customer?.customerGroupId ?? 0) > 0 && d.customerGroups?.includes(source.customer?.customerGroupId ?? 0))
                    )
                    &&
                    (
                        // - buď je zoznam prázdny (platí pre všetko), alebo musí obsahovať danú zákaznícku skupinu
                        (d.domains?.length ?? 0) === 0 || ((source.customer?.domainId ?? 0) > 0 && d.domains?.includes(source.customer?.domainId ?? 0))
                    )
                    && (d.minimalPrice ?? 0) <= itemsTotalPrice && (d.discount ?? 0) > 0);
        }

        // Ak sa nenašla objemová zľava, tak ju odstránim zo zoznamu a ďalej nepokračujem
        if (volumeDiscount === undefined) {

            // Uchovám naposledy použitú hladinu, aby som vykonal zmenu len ak sa zmení
            setVolumeDiscountsLastUsed(0);

            // Odstránim zo zoznamu ale iba vtedy, ak v nom ešte figuruje (mohol som ju medzi časom ručne zmazať)
            if (volumeDiscountItem !== undefined) {
                // Uložím stav s oneskorením, aby sa stihol uložiť stav naposledy použitej hladiny
                setTimeout(() => { setSource(prev => ({ ...prev, items: DocumentItemsDiscountCompute([...itemsSource ?? []]) })); }, 250);
            }
            return;
        }

        // Ak som našiel objemovú zľavu a je iná ako naposledy použitá, tak ju aplikujem
        if (volumeDiscount !== undefined && volumeDiscountsLastUsed !== volumeDiscount.discount) {

            // Uchovám naposledy použitú hladinu, aby som vykonal zmenu len ak sa zmení
            setVolumeDiscountsLastUsed(volumeDiscount.discount);

            // Pridám do položiek
            let items = [
                ...itemsSource,
                {
                    code: 'volumediscount',
                    type: volumeDiscount.type === 0 ? DocumentItemType.Discount : DocumentItemType.DiscountRelative,
                    price: volumeDiscount.discount,
                    priceIncludeVat: true, // vždy vychádzam z ceny s DPH (musí sedieť celková suma dokladu, teda suma s DPH)
                    name: 'Objemová zľava',
                    quantity: 1,
                    unit: 'ks'
                }
            ];

            // Uložím stav s oneskorením, aby sa stihol uložiť stav naposledy použitej hladiny
            setTimeout(() => { setSource(prev => ({ ...prev, items: DocumentItemsDiscountCompute([...items ?? []]) })); }, 250);
        }
    }, [source.items]); // eslint-disable-line react-hooks/exhaustive-deps

    // Info text v harnominke
    let titleCustomer = '(nie)';
    let titleOwner = '(nie)';
    let titleSummary = FormatCurrency((source.priceTotalNoVat ?? 0) + (source.priceTotalVatAmount ?? 0), currency, 2);

    if (source.customer?.person === true && (source.customer.firstname || source.customer.lastname)) {
        titleCustomer = source.customer.firstname + ' ' + source.customer.lastname;
    }
    if (source.customer?.person === false && source.customer.company) {
        titleCustomer = source.customer.company;
    }

    if (source.owner?.person === true && (source.owner.firstname || source.owner.lastname)) {
        titleOwner = source.owner.firstname + ' ' + source.owner.lastname;
    }
    if (source.owner?.person === false && source.owner.company) {
        titleOwner = source.owner.company;
    }

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="xl" fullWidth open={props.open} scroll="body" onClose={(e, r) => {
                if (r !== 'backdropClick') {
                    if (sourceChanged !== true) {
                        props.onClose();
                        return;
                    }
                    setConfirm(prev => ({
                        ...prev, open: true, title: 'Neuložené zmeny', children: 'Skutočne chcete zavrieť okno bez uloženia zmien?', onConfirm: () => {
                            setConfirm(prev => ({ ...prev, open: false }));
                            props.onClose();
                        }
                    }));
                }
            }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave} noValidate>
                    <DialogContent sx={{ height: '85vh' }}>
                        <Grid container columnSpacing={3} sx={{ mt: 1 }}>

                            {/* Ľavý panel */}
                            <Grid item xs={12} lg={4}>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('basic')} onChange={() => handleToggleAccordion('basic')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography fontWeight="600">
                                            {props.documentType.other?.translationName} / {((props?.id ?? 0) > 0 && !props.copy ? (source.number ?? '-') : 'Nový doklad')}
                                            {(props.copy && ' (kópia)')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            {(documentStates?.length ?? 0) > 0 && (
                                                <Grid item xs={12}>
                                                    <FormControl margin="dense" variant="outlined" fullWidth>
                                                        <InputLabel id="documentcreate-state">Stav</InputLabel>
                                                        <Select labelId="documentcreate-state" label="Stav" value={source.documentStateId ?? 0} name="documentStateId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'documentStateId' && setFocus(input)}>
                                                            <MenuItem key={0} value={0}>-</MenuItem>
                                                            {documentStates?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )}

                                            <Grid item xs={12}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="documentcreate-pricelist">Cenník</InputLabel>
                                                    <Select labelId="documentcreate-pricelist" label="Cenník" value={source.priceListId ?? 0} name="priceListId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => { props.autoFocus === 'priceListId' && setFocus(input) }}>
                                                        <MenuItem key={0} value={0}>-</MenuItem>
                                                        {priceLists?.map(item => <MenuItem key={item.id} value={item.id}>{item.name + ', ' + ((item.discount ?? 0) * -1) + '%'}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="documentcreate-shipment">Doprava</InputLabel>
                                                    <Select labelId="documentcreate-shipment" label="Doprava" value={source.shipmentId ?? 0} name="shipmentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'shipmentId' && setFocus(input)}>
                                                        <MenuItem key={0} value={0}>-</MenuItem>
                                                        {shipments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="documentcreate-payment">Platba</InputLabel>
                                                    <Select labelId="documentcreate-payment" label="Platba" value={source.paymentId ?? 0} name="paymentId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'paymentId' && setFocus(input)}>
                                                        <MenuItem key={0} value={0}>-</MenuItem>
                                                        {payments?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel control={<Switch checked={source.done} name="done" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Vybavené" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControlLabel control={<Switch checked={source.paid} name="paid" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Uhradené" />
                                            </Grid>

                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('customer')} onChange={() => handleToggleAccordion('customer')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {((props.documentType.sale ?? false) === true ? 'Odberateľ' : 'Dodávateľ')}{isAccordionExpanded('customer') ? null : <>: <strong>{titleCustomer}</strong></>}
                                            {customerDiscount > 0 && <Chip label={'-' + customerDiscount + '%'} size='small' sx={{ ml: 1 }} />}
                                            {/* Pri výbere akcie musím vždy rozbaliť harmoniku, aby sa pripojil komponent, keďže je aktívne nastavenie: "unmountOnExit" */}
                                            {isAccordionExpanded('customer') === false && <Button onClick={(e) => { e.stopPropagation(); handleExpandAccordion('customer'); setCustomerBaseAction(CustomerBaseComponentAction.Select); }} size='small' color='secondary' sx={{ position: 'absolute', top: 8, right: 45 }} startIcon={<ContactsIcon />}>Vybrať</Button>}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CustomerBaseComponent customer={source.customer ?? {}} countries={countries}
                                            startAction={customerBaseAction}
                                            startActionCallback={() => setCustomerBaseAction(undefined)}
                                            onCustomerChanged={(changed) => {
                                                let changedCustomer = '';
                                                if (changed.person === true && (changed.firstname || changed.lastname)) {
                                                    changedCustomer = changed.firstname + ' ' + changed.lastname;
                                                }
                                                if (changed.person === false && changed.company) {
                                                    changedCustomer = changed.company;
                                                }
                                                handleChangeHistory('customer-update', changedCustomer);
                                                setSource(prev => ({ ...prev, customer: changed }));
                                                setCustomerNewSelected(changed.customerId ?? 0);
                                            }} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('owner')} onChange={() => handleToggleAccordion('owner')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            {((props.documentType.sale ?? false) === false ? 'Odberateľ' : 'Dodávateľ')}{isAccordionExpanded('owner') ? '' : ': ' + titleOwner}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CustomerBaseComponent customer={source.owner ?? {}} countries={countries} onCustomerChanged={(changed) => {
                                            let changedCustomer = '';
                                            if (changed.person === true && (changed.firstname || changed.lastname)) {
                                                changedCustomer = changed.firstname + ' ' + changed.lastname;
                                            }
                                            if (changed.person === false && changed.company) {
                                                changedCustomer = changed.company;
                                            }
                                            handleChangeHistory('customer-update', changedCustomer);
                                            setSource(prev => ({ ...prev, owner: changed }));
                                        }} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion TransitionProps={{ unmountOnExit: false /* Ponechat pripojené, aby vždy bežal prepočet */ }} expanded={isAccordionExpanded('summary')} onChange={() => handleToggleAccordion('summary')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            Sumár{isAccordionExpanded('summary') ? null : <>: {titleSummary}</>}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <DocumentCreateSummary {...componentProps()} source={source} vatRates={vatRates} vatCalculationMethod={vatCalculationMethod} currency={currency} currencies={currencies} />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion sx={{ mb: 2 }} TransitionProps={{ unmountOnExit: true }} expanded={isAccordionExpanded('details')} onChange={() => handleToggleAccordion('details')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>Podrobnosti</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container columnSpacing={1}>

                                            {(props.documentType.hideSymbols ?? false) === false && (
                                                <>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField margin="dense" name="symbolVariable" label="Variabilný symbol (automaticky)" fullWidth variant="outlined" autoComplete="off" value={source.symbolVariable ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'symbolVariable' && setFocus(input)}
                                                            inputProps={{ maxLength: 10 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <NumbersIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField margin="dense" name="symbolSpecific" label="Špecifický symbol" fullWidth variant="outlined" autoComplete="off" value={source.symbolSpecific ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'symbolSpecific' && setFocus(input)}
                                                            inputProps={{ maxLength: 10 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <NumbersIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <TextField margin="dense" name="symbolConstant" label="Konštantný symbol" fullWidth variant="outlined" autoComplete="off" value={source.symbolConstant ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                            inputRef={input => props.autoFocus === 'symbolConstant' && setFocus(input)}
                                                            inputProps={{ maxLength: 4 }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <NumbersIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </Grid>
                                                </>
                                            )}

                                            {(props.documentType.hideDateOfIssue ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                                        <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={true}
                                                            renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Dátum vystavenia" value={NullMinDate(source.dateOfIssue)} onChange={(d) => { handleChange('dateOfIssue', d) }}
                                                            inputRef={input => props.autoFocus === 'dateOfIssue' && setFocus(input)} />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfDue ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                                        <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={true}
                                                            renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Dátum splatnosti" value={NullMinDate(source.dateOfDue)} onChange={(d) => { handleChange('dateOfDue', d) }}
                                                            inputRef={input => props.autoFocus === 'dateOfDue' && setFocus(input)} />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfTaxLiability ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                                        <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={true}
                                                            renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Dátum zdaniteľného plnenia" value={NullMinDate(source.dateOfTaxLiability)} onChange={(d) => { handleChange('dateOfTaxLiability', d) }}
                                                            inputRef={input => props.autoFocus === 'dateOfTaxLiability' && setFocus(input)} />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfDelivery ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                                        <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={true}
                                                            renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Dátum dodania" value={NullMinDate(source.dateOfDelivery)} onChange={(d) => { handleChange('dateOfDelivery', d) }}
                                                            inputRef={input => props.autoFocus === 'dateOfDelivery' && setFocus(input)} />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            {(props.documentType.hideDateOfExpiration ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                                        <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={true}
                                                            renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Dátum platnosti" value={NullMinDate(source.dateOfExpiration)} onChange={(d) => { handleChange('dateOfExpiration', d) }}
                                                            inputRef={input => props.autoFocus === 'dateOfExpiration' && setFocus(input)} />
                                                    </LocalizationProvider>
                                                </Grid>
                                            )}

                                            <Grid item xs={12} sm={6}>
                                                <TextField fullWidth margin="dense" name="currencyExchange" type="text" label="Kurz" variant="outlined" autoComplete="off" value={source.currencyExchange ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    disabled={(source.currencyCode === currency.code)}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EuroIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel id="domain-create-currency">Mena</InputLabel>
                                                    <Select required labelId="domain-create-currency" label="Mena" value={source.currencyCode ?? 0} name="currencyCode" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => props.autoFocus === 'currencyCode' && setFocus(input)}>
                                                        {currencies?.map(item => <MenuItem key={item.id} value={item.code}>{item.code}</MenuItem>)}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            {(props.documentType.hideTrackingNumber ?? false) === false && (
                                                <Grid item xs={12} sm={6}>
                                                    <TextField margin="dense" name="trackingNumber" label="Sledovacie číslo" fullWidth variant="outlined" autoComplete="off" value={source.trackingNumber ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                                        inputRef={input => (props.autoFocus === 'trackingNumber') && setFocus(input)}
                                                        inputProps={{ maxLength: 255 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <NumbersIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>
                                            )}

                                            <Grid item xs={12} mt={1}>
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ pl: 0 }}>Vytvorené</TableCell>
                                                            <TableCell sx={{ pr: 0 }} align="right">{(source.createdDate !== undefined ? dateFormat(new Date(source.createdDate ?? new Date()), 'dd.MM.yyyy HH:mm') : '-')}</TableCell>
                                                        </TableRow>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ pl: 0 }}>Upravené</TableCell>
                                                            <TableCell sx={{ pr: 0 }} align="right">{(source.updatedDate !== undefined ? dateFormat(new Date(source.updatedDate ?? new Date()), 'dd.MM.yyyy HH:mm') : '-')}</TableCell>
                                                        </TableRow>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell sx={{ pl: 0 }}>Doména</TableCell>
                                                            <TableCell sx={{ pr: 0 }} align="right">{(source.customer?.domainId ?? 0) > 0 ? domains.find(domain => domain.id === source.customer?.domainId)?.name ?? '' : '-'}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <CustomFieldValues itemTypeId={5} hideTitle={true} values={source.customFieldValues} useDefault={source.id === 0} onChange={customFieldValues => handleChange('customFieldValues', customFieldValues)} />
                            </Grid>

                            {/* Pravý panel */}
                            <Grid item xs={12} lg>

                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                        <Tab value="items" label={'Položky (' + (source.items?.length ?? 0) + ')'} />
                                        {(props.documentType.hidePayments ?? false) === false && (
                                            <Tab value="payments" label={'Úhrady (' + (source.payments?.length ?? 0) + ')'} />
                                        )}
                                        {(props.documentType.hideDocuments ?? false) === false && (
                                            <Tab value="related" label={'Doklady (' + ((source.relatedParents?.length ?? 0) + (source.relatedChildren?.length ?? 0) + (source.relatedExternal?.length ?? 0)) + ')'} />
                                        )}
                                        {(props.documentType.hideNotes ?? false) === false && (
                                            <Tab value="notes" label={'Poznámky (' + (source.notes?.length ?? 0) + ')'} />
                                        )}
                                        {(props.documentType.hideFiles ?? false) === false && (
                                            <Tab value="files" label={'Prílohy (' + (source.files?.length ?? 0) + ')'} />
                                        )}
                                        <Tab value="history" label={'História (' + (source.history?.length ?? 0) + ')'} />
                                    </Tabs>
                                </Box>
                                <TabContext value={tabValue}>
                                    <TabPanel value="items" sx={{ px: '0', pb: 0, pt: 1 }}>
                                        <DocumentCreateItems {...componentProps()}
                                            onSetZeroVatRate={handleSetZeroVatRate}
                                            onItemAdd={handleItemAdd}
                                            onItemDelete={handleItemDelete}
                                            onItemListDelete={handleItemListDelete}
                                            onItemListDiscount={handleItemListDiscount}
                                            source={source}
                                            units={units}
                                            parameters={parameters}
                                            vatRates={vatRates}
                                            vatRateDefault={vatRateDefault}
                                            vatCalculationMethod={vatCalculationMethod}
                                            priceIncludeVat={priceIncludeVat}
                                            languageDefaultId={languageDefaultId}
                                            customerDiscount={customerDiscount}
                                            priceList={priceList ?? {}}
                                            tagDiscount={tagDiscount}
                                            payments={payments}
                                            shipments={shipments}
                                            currency={currency} />
                                    </TabPanel>
                                    {(props.documentType.hidePayments ?? false) === false && (
                                        <TabPanel value="payments" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <DocumentCreatePayments {...componentProps()} payments={payments} source={source} currency={currency} onChangeHistory={handleChangeHistory} />
                                        </TabPanel>
                                    )}
                                    {(props.documentType.hideDocuments ?? false) === false && (
                                        <TabPanel value="related" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <Grid container columnSpacing={1}>
                                                <DocumentCreateRelated {...componentProps()} source={source} documentTypes={props.documentTypes} />
                                                <DocumentCreateRelatedExternal {...componentProps()} source={source} documentTypes={props.documentTypes} externalDocumentTypes={externalDocumentTypes} />
                                            </Grid>
                                        </TabPanel>
                                    )}
                                    {(props.documentType.hideNotes ?? false) === false && (
                                        <TabPanel value="notes" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <DocumentCreateNotes {...componentProps()} source={source} />
                                        </TabPanel>
                                    )}
                                    {(props.documentType.hideFiles ?? false) === false && (
                                        <TabPanel value="files" sx={{ px: '0', pb: 0, pt: 1 }}>
                                            <DocumentCreateFiles source={source} setSource={setSource} />
                                        </TabPanel>
                                    )}
                                    <TabPanel value="history" sx={{ px: '0', pb: 0, pt: 1 }}>
                                        <DocumentCreateHistory history={source.history} />
                                    </TabPanel>
                                </TabContext>

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => handleResetConfirm()} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose} sx={{ mr: 1 }}>Späť</Button>
                        <ButtonGroup disabled={loading} variant="contained" aria-label="Uložiť">
                            <Button disabled={props.disableSaveAction ?? false} type="button" variant="contained" size="small" aria-controls="save-menu" aria-haspopup="true" onClick={(e) => setSaveMenuEl(e.currentTarget)}>
                                <ArrowDropDownIcon />
                            </Button>
                            <Button type="submit" variant="contained">Uložiť</Button>
                        </ButtonGroup>
                    </DialogActions>
                </Box>

                <Menu id="save-menu" anchorEl={saveMenuEl} open={Boolean(saveMenuEl)} anchorOrigin={{ vertical: 'top', horizontal: 'left', }} transformOrigin={{ vertical: 'bottom', horizontal: 'left', }} onClose={() => setSaveMenuEl(null)}>
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndContinue); setSaveMenuEl(null); }}>
                        <ListItemIcon><SaveIcon fontSize="small" /></ListItemIcon> Uložiť a pokračovať
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndNew); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový
                    </MenuItem>
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndNewFromCopy); setSaveMenuEl(null); }}>
                        <ListItemIcon><AddBoxIcon fontSize="small" /></ListItemIcon> Uložiť a nový z kópie
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { handleSave(undefined, DocumentCreateSaveAction.SaveAndPrint); setSaveMenuEl(null); }}>
                        <ListItemIcon><PrintIcon fontSize="small" /></ListItemIcon> Uložiť a vytlačiť
                    </MenuItem>
                </Menu>

                <Snackbar open={warning.text.length > 0} autoHideDuration={10000} onClose={() => setWarning(EmptyWarning)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                    <Alert sx={{ mt: 1 }} variant="filled" severity={warning.color as AlertColor} action={
                        <IconButton color="inherit" size="small" onClick={() => setWarning(EmptyWarning)}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >{warning.text}</Alert>
                </Snackbar>

            </Dialog>

            {/* Potvrdzovacie okno */}
            <Confirm open={confirm.open} title={confirm.title} children={confirm.children} onConfirm={confirm.onConfirm} onCancel={() => { setConfirm(prev => ({ ...prev, open: false })) }} />

        </>
    )
}

export default DocumentCreate;