import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../../utility/Number';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import skLocale from 'date-fns/locale/sk';

// Modely
import { LoyaltySystem, Domain, CustomerGroup } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, Box, FormControlLabel, Switch, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/lab';

// Ikony
import PercentIcon from '@mui/icons-material/Percent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FunctionsIcon from '@mui/icons-material/Functions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): LoyaltySystem => ({
    id: 0,
    actived: true,
    orderFromDate: new Date(),
    orderMaxAge: 24,
    name: '',
    domains: [],
    customerGroups: [],
    levels: []
});

// Vstupné parametre
export interface LoyaltySystemCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const LoyaltySystemCreate = (props: LoyaltySystemCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<LoyaltySystem>(EmptySource());
    const [domains, setDomains] = useState<Domain[]>([]);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'loyaltysystem/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as LoyaltySystem);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam domén
    const loadDomains = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'domain')
            .then(response => {
                setDomains(response.data.filter((domain: Domain) => domain.actived));
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadDomains(), []);

    // Načítam zákazníckych skupín
    const loadCustomerGroups = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'customergroup')
            .then(response => {
                setCustomerGroups(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadCustomerGroups(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }
    const handleChangeLevels = (index: number, property: string, value: any) => {
        setSource((p) => {
            const changed = p.levels ?? [];
            changed[index] = { ...changed[index], [property]: value };
            return { ...p, levels: changed };
        });
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if ((source.levels?.length ?? 0) === 0) {
            setWarning({ ...EmptyWarning, name: 'actions', text: 'Musíte pridať minimálne jednu výšku zľavy podľa obratu!' });
            return;
        }

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'loyaltysystem', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Vernostný program' : 'Nový vernostný program')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} mb={1}>
                                <FormControlLabel control={<Switch checked={source.actived} name="actived" onChange={(e) => { handleChange(e.target.name, e.target.checked) }} />} label="Aktívny" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="loyaltysystem-create-domain">Domény</InputLabel>
                                    <Select multiple labelId="loyaltysystem-create-domain" label="Domény" name="domains" value={source.domains ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'domains' && setFocus(input)}>
                                        {domains.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="loyaltysystem-create-customergroups">Zákaznícke skupiny</InputLabel>
                                    <Select multiple labelId="loyaltysystem-create-customergroups" label="Zákaznícke skupiny" name="customerGroups" value={source.customerGroups ?? []} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'customerGroups' && setFocus(input)}>
                                        {customerGroups.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Objednávky od" value={source.orderFromDate} onChange={(d) => { handleChange('orderFromDate', d) }}
                                        inputRef={input => props.autoFocus === 'orderFromDate' && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <TextField required margin="dense" name="name" label="Názov" fullWidth variant="outlined" autoComplete="off" value={source.name ?? ''} onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    inputRef={input => (props.autoFocus === 'name' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 255 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <TextField required fullWidth margin="dense" name="orderMaxAge" type="text" label="Objednávky za obdobie (mesiacov)" variant="outlined" autoComplete="off" value={source.orderMaxAge ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'orderMaxAge' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Do celkového obratu sa budú započítavať objednávky od nastaveného dátumu 'objednávky od', ktoré nie sú staršie ako nastavené obdobie v mesiacoch 'objednávky za obdobie'.">
                                                    <CalendarMonthIcon className="formIconHelp" />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="warning">
                                    Do celkového obratu sa započítavajú všetky objednávky, ktoré sú označené ako "uhradené" v nastavenom období.
                                    V prípade, že sa niektorá objednávka (napr. vrátená) nemá započítavať do celkového obratu, je potrebné zrušiť označenie "uhradené".
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={1}>
                                <Alert severity="info">
                                    Pri obmedzeniach (napr. domény) platí pravidlo, že ak je nie je označená žiadna možnosť, tak záznam platí pre všetko.
                                </Alert>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <Typography textAlign="center" variant="h6" gutterBottom>Zľavy podľa obratu</Typography>

                                {(source.levels?.length ?? 0) > 0 &&
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Od obratu</TableCell>
                                                <TableCell>Zľava %</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {source.levels?.map((level, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField required fullWidth size="small" name="price" type="text" variant="outlined" autoComplete="off" value={level.price ?? ''}
                                                            onChange={(e) => { handleChangeLevels(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Obrat od ktorého je uplatnená zľava">
                                                                            <FunctionsIcon className="formIconHelp" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                    <TableCell sx={{ p: 0.5, pl: 0 }}>
                                                        <TextField required fullWidth size="small" name="discount" type="text" variant="outlined" autoComplete="off" value={level.discount ?? ''}
                                                            onChange={(e) => { handleChangeLevels(index, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                            inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title="Zľava, ktorá je uplatnená po dosiahnutí nastaveného obratu">
                                                                            <PercentIcon className="formIconHelp" />
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                ),
                                                            }} />
                                                    </TableCell>
                                                    <TableCell style={{ padding: 0, width: '50px', textAlign: 'center' }}>
                                                        <IconButton aria-label="Vymazať" size="small" onClick={() => setSource((p) => ({ ...p, levels: p.levels?.filter(item => item !== level) ?? [] }))}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                }
                                <Button fullWidth variant="contained" color="secondary" sx={{ mt: 1 }} onClick={() => setSource((p) => ({ ...p, levels: [...p.levels ?? [], { price: 0, discount: 0 }] }))}>Pridať zľavu</Button>
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default LoyaltySystemCreate;