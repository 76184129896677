import React from 'react';

// Modely
import { ProductCreateComponentProps } from './ProductCreate';
import { ProductStock, Stock, StockAvailability } from '../../models/Models';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../utility/Number';

// Komponenty
import { MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

// Ikony
import ApartmentIcon from '@mui/icons-material/Apartment';

// Vstupné parametre, vychádzam zo spoločného predpisu
interface ProductCreateStockProps extends ProductCreateComponentProps {
    stocks: Stock[];
    stockAvailabilities: StockAvailability[];
};

const ProductCreateStock = (props: ProductCreateStockProps) => {

    // Zmeny vo formulári
    const handleChangeStock = (stockId: number, stockDivisionId: number, property: string, value: any) => {
        props.setSource(prev => {
            const item: ProductStock = { ...prev?.stocks?.find(item => item.stockId === stockId && item.stockDivisionId === stockDivisionId), stockId: stockId, stockDivisionId: stockDivisionId, [property]: value };
            const next = { ...prev, stocks: [...prev?.stocks?.filter(item => !(item.stockId === stockId && item.stockDivisionId === stockDivisionId)) ?? [], item] };
            return next;
        });
    }

    return (
        <>
            <Paper sx={{ p: 2 }}>
                <div style={{ display: 'flex', height: '100%', overflowX: 'auto' }}>
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small" sx={{ minWidth: '500px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell sx={{ width: '30%' }}>Dostupnosť</TableCell>
                                    <TableCell sx={{ width: '30%' }}>Množstvo na sklade</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        <strong>Celkový stav</strong>
                                    </TableCell>
                                    <TableCell>
                                        <Select fullWidth size="small" labelId="product-create-availability" value={props.source.stockAvailabilityId ?? 0} name="stockAvailabilityId" onChange={(e) => { props.onChange(e.target.name, parseInt(e.target.value.toString())) }}>
                                            <MenuItem value={0}>-</MenuItem>
                                            {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <TextField fullWidth size="small" type="number" variant="outlined" autoComplete="off" disabled
                                            value={props.source.stocks?.reduce((sum, current) => sum + (current?.quantity ?? 0), 0) ?? ''}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <ApartmentIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </TableCell>
                                </TableRow>
                                {props.stocks?.map((stock, index) => (
                                    <React.Fragment key={stock.id}>
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {stock.name}
                                            </TableCell>
                                            <TableCell>
                                                <Select fullWidth size="small" name="stockAvailabilityId"
                                                    value={props.source.stocks?.find(item => item.stockId === stock.id && (item.stockDivisionId ?? 0) === 0)?.stockAvailabilityId ?? 0}
                                                    onChange={(e) => { handleChangeStock((stock?.id ?? 0), 0, e.target.name, e.target.value) }}>
                                                    <MenuItem key={0} value={0}>-</MenuItem>
                                                    {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <TextField fullWidth size="small" name="quantity" type="text" variant="outlined" autoComplete="off"
                                                    value={props.source.stocks?.find(item => item.stockId === stock.id && (item.stockDivisionId ?? 0) === 0)?.quantity ?? ''}
                                                    onChange={(e) => { handleChangeStock((stock?.id ?? 0), 0, e.target.name, ConvertToDecimal(e.target.value)) }}
                                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <ApartmentIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }} />
                                            </TableCell>
                                        </TableRow>
                                        {
                                            (stock.divisions !== undefined && (stock.divisions?.length ?? 0) > 0 ? stock.divisions : [/*{ id: 0, name: '' }*/])
                                                .map((division, index2) => (
                                                    <TableRow
                                                        key={stock.id + '-' + division.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {stock.name} {(division?.id ?? 0) > 0 ? ' / ' + division?.name : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Select fullWidth size="small" name="stockAvailabilityId"
                                                                value={props.source.stocks?.find(item => item.stockId === stock.id && item.stockDivisionId === division.id)?.stockAvailabilityId ?? 0}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), (division?.id ?? 0), e.target.name, e.target.value) }}>
                                                                <MenuItem key={0} value={0}>-</MenuItem>
                                                                {props.stockAvailabilities.map(item => (<MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>))}
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField fullWidth size="small" name="quantity" type="text" variant="outlined" autoComplete="off"
                                                                value={props.source.stocks?.find(item => item.stockId === stock.id && item.stockDivisionId === division.id)?.quantity ?? ''}
                                                                onChange={(e) => { handleChangeStock((stock?.id ?? 0), (division?.id ?? 0), e.target.name, ConvertToDecimal(e.target.value)) }}
                                                                inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <ApartmentIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                        }
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Paper>
        </>
    )
};

export default ProductCreateStock;
