import { FormEvent, useCallback, useEffect, useState } from 'react'
import { AppConfig } from '../../../AppConfig';
import axios from 'axios';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// Utility
import { ConvertToDecimal, ConvertToDecimalOnKeyDown } from '../../../utility/Number';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import skLocale from 'date-fns/locale/sk';

// Modely
import { VatRate, Country } from '../../../models/Models';

// Komponenty
import { Alert, AlertColor, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Select, TextField, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/lab';

// Ikony
import PercentIcon from '@mui/icons-material/Percent';

// Predpis pre zobrazenie varovania a prázdne nastavenia
interface Warning {
    name: string;
    text: string;
    color: string;
}
const EmptyWarning: Warning = {
    name: '',
    text: '',
    color: 'error'
}

// Prázdny záznam (predvolený)
const EmptySource = (): VatRate => ({
    id: 0,
    countryId: 0,
    activeFrom: new Date(),
    // vatRateHigh: 0,
    // vatRateLow: 0,
    // vatRateLow2: 0,
    // vatRateLow3: 0,
    note: ''
});

// Vstupné parametre
export interface VatRateCreateProps {
    open: boolean;
    id?: number;
    copy?: boolean;
    autoFocus?: string;
    keepMounted?: boolean;
    onSave?: () => void;
    onClose: () => void;
}

const VatRateCreate = (props: VatRateCreateProps) => {

    // Stav
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState<Warning>(EmptyWarning);
    const [source, setSource] = useState<VatRate>(EmptySource());
    const [sourceCountry, setSourceCountry] = useState<Country[]>([]);

    // Funkcia pre načítanie dát z API
    const loadData = useCallback(() => {
        if (!props.open) {
            return;
        }
        // Vždy najskôr vyresetujem údaje (kvôli háčikom)
        setSource(EmptySource());
        if ((props.id ?? 0) === 0) {
            return;
        }
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'vatrate/' + props.id)
            .then(response => {
                if (response.data !== null) {
                    if (props.copy) {
                        response.data.id = 0;
                    }
                    setSource(response.data as VatRate);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.id, props.copy, props.open]);

    // Načítam dáta po zobrazení
    useEffect(() => loadData(), [loadData]);

    // Načítam zoznam cenníkov
    const loadVatRateCountries = () => {
        setLoading(true);
        axios
            .get(AppConfig.ApiUri + 'country')
            .then(response => {
                setSourceCountry(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => loadVatRateCountries(), []);

    // Zmeny vo formulári
    const handleChange = (property: string, value: any) => {
        setSource(prev => ({ ...prev, [property]: value }));
    }

    // Ak niečo zmením, tak skryjem hlášku s upozornením
    useEffect(() => {
        if (warning.text.length > 0) {
            setWarning(EmptyWarning);
        }
    }, [source]); // eslint-disable-line react-hooks/exhaustive-deps

    // Uloženie formuláru
    const handleSave = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setWarning(EmptyWarning);
        setLoading(true);

        axios
            .post(AppConfig.ApiUri + 'vatrate', source)
            .then(response => {
                if (response.data > 0) {
                    if (props.onSave) {
                        props.onSave();
                    }
                    props.onClose();
                }
            })
            .catch(() => {
                setWarning({ ...EmptyWarning, text: 'Záznam sa nepodarilo uložiť! Skontrolujte vstupné údaje.' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Automatické nastavenie "focus" (iba pri zmene zobrazenia dialógového okna)
    const [focusActivate, setFocusActivate] = useState<boolean>(false);
    const setFocus = (input: any) => {
        if (focusActivate && props.open) {
            setFocusActivate(false);
            setTimeout(() => { input?.focus(); }, 100);
        }
    }
    useEffect(() => setFocusActivate(true), [props.open]);

    return (
        <>
            <Dialog keepMounted={props.keepMounted ?? false} maxWidth="sm" fullWidth open={props.open} scroll="body" onClose={(e, r) => { if (r !== 'backdropClick') { props.onClose(); } }}>
                <Backdrop sx={{ color: '#666', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box component="form" onSubmit={handleSave}>
                    <DialogTitle>
                        {((props?.id ?? 0) > 0 ? 'Sadzba DPH' : 'Nová sadzba DPH')}
                        {(props.copy && ' (kópia)')}
                    </DialogTitle>
                    <DialogContent>

                        <Grid container columnSpacing={1} sx={{ mt: 1 }}>

                            <Grid item xs={12} md={6}>
                                <FormControl margin="dense" variant="outlined" fullWidth>
                                    <InputLabel id="vatrate-create-country">Krajina</InputLabel>
                                    <Select required labelId="vatrate-create-country" label="Krajina" value={source.countryId ?? 0} name="countryId" onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                        inputRef={input => props.autoFocus === 'country' && setFocus(input)}>
                                        <MenuItem key={0} value={0}>Žiadna (všetky krajiny)</MenuItem>
                                        {sourceCountry?.map(item => <MenuItem key={item.id} value={item.id}>{item.other?.translationName ?? ''}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                    <DatePicker inputFormat='dd.MM.yyyy' disableMaskedInput={true}
                                        renderInput={(props) => <TextField fullWidth margin="dense" autoComplete="off" variant="outlined" {...props} />} label="Aktívna od" value={source.activeFrom} onChange={(d) => { handleChange('activeFrom', d) }}
                                        inputRef={input => props.autoFocus === 'activeFrom' && setFocus(input)} />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth margin="dense" name="vatRateHigh" type="text" label="Základná sadzba DPH (%)" variant="outlined" autoComplete="off" value={source.vatRateHigh ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => (props.autoFocus === 'vatRateHigh' || props.autoFocus === undefined) && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth margin="dense" name="vatRateLow" type="text" label="Znížená sadzba DPH (%)" variant="outlined" autoComplete="off" value={source.vatRateLow ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'vatRateLow' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="vatRateLow2" type="text" label="Znížená sadzba č.2 DPH (%)" variant="outlined" autoComplete="off" value={source.vatRateLow2 ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'vatRateLow2' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth margin="dense" name="vatRateLow3" type="text" label="Znížená sadzba č.3 DPH (%)" variant="outlined" autoComplete="off" value={source.vatRateLow3 ?? ''} onChange={(e) => { handleChange(e.target.name, ConvertToDecimal(e.target.value)) }}
                                    inputRef={input => props.autoFocus === 'vatRateLow3' && setFocus(input)}
                                    inputProps={{ maxLength: 18, onInput: ConvertToDecimalOnKeyDown }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PercentIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>

                        </Grid>

                        {(warning.text.length > 0 && <Alert sx={{ mt: 1 }} severity={warning.color as AlertColor}>{warning.text}</Alert>)}

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={() => setSource(prev => ({ ...EmptySource(), id: prev.id }))} color="error" sx={{ mr: 1, display: 'inline-flex', marginRight: 'auto' }}>Reset</Button>
                        <Button disabled={loading} onClick={props.onClose}>Späť</Button>
                        <Button disabled={loading} type="submit" variant="contained">Uložiť</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    )
}

export default VatRateCreate;