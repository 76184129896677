
export const FormatDecimal = (value: number, maxDigits?: number | undefined) : string | undefined => {
    return value.toLocaleString('sk-sk', { minimumFractionDigits: maxDigits, maximumFractionDigits: maxDigits });
};

interface Currency {
    prefix?: string;
    surfix?: string;
}

export const FormatCurrency = (value: number,  currency: Currency, maxDigits?: number) : string | undefined => {
    return ((currency.prefix ?? '').length > 0 ? currency.prefix + ' ' : '') + FormatDecimal(value, maxDigits) + ((currency.surfix ?? '').length > 0 ? ' ' + currency.surfix : '');
};
