
export const ExportCSV = (data: any[], delimiter?: string, delimiterValue?: string) => {
    if (delimiter === undefined) {
        delimiter = ',';
    }
    if (delimiterValue === undefined) {
        delimiterValue = '"';
    }
    var content = 'data:text/csv;charset=utf-8,';
    data.filter(item => item).forEach(item => {
        if (Array.isArray(item)) {
            var row = '';
            item.forEach(value => {
                row += (row !== '' ? delimiter : '');
                if (typeof value === 'object') {
                    row += delimiter;
                }
                else {
                    // Desatinné číslo by malo byť oddelené čiarkou
                    if (typeof value === 'number') {
                        value = value?.toLocaleString() ?? '';
                    }
                    // https://en.wikipedia.org/wiki/Comma-separated_values
                    // Ak text obsahuje oddelovač, tak je potrebné text uzavrieť do úvodzoviek
                    // Ak text obsahuje úvodzovky, tak je potrebné text uzavrieť do úvodzoviek a úvodzovky v texte zdvojiť
                    row += ((value?.toString().indexOf(delimiter) !== -1 || value?.toString().indexOf(delimiterValue) !== -1) ? delimiterValue : '');
                    row += (value?.toString() ?? '').replace(/(?:\\[rn]|[\r\n]+)+/g, '').replace(RegExp(delimiterValue ?? '', 'g'), (delimiterValue ?? '') + (delimiterValue ?? ''));
                    row += ((value?.toString().indexOf(delimiter) !== -1 || value?.toString().indexOf(delimiterValue) !== -1) ? delimiterValue : '');
                }
            });
            content += row + '\r\n';
        }
    });
    var encodedUri = encodeURI(content);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", document.title + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
